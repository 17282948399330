import PageHeader from "../../components/Header/PageHeader";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Collapse, Select} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import axios from "axios";


const defaultVideos = [
	{
		"id": 1,
		"name_en": "IMEAN.IO",
		"name_ru": "IMEAN.IO",
		"link":"CUxT_a3lmkM"
	}
];

const Guideline = () => {
	const [activeVideo, setActiveVideo] = useState(0);
	const [videosList, setVideosList] = useState(defaultVideos);
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [faq, setFaq] = useState([]);

	const handleReplaceVideo = (action) => {
		if (action === 'increment' && activeVideo < videosList.length - 1){
			setActiveVideo(p => p + 1);
		}
		if (action === 'decrement' && activeVideo > 0){
			setActiveVideo(p => p - 1);
		}
	}

	useEffect(() => {
		axios.get('/data/guideline-faq.json')
				.then((res)=>{
					setFaq(res.data)
				});
		axios.get('/data/guideline-videos.json')
				.then((res)=>{
					setVideosList(res.data)
				})
	}, []);

	return (
			<div>
				<PageHeader
						title={t("home.guideline")}
						onBackClick={() => {
							navigate("/home");
						}}
				/>
				<div>
					<h1 className="self-assessment-title">
						{t("home.guideline")}
					</h1>
				</div>
				<div
						className="survey-page px-1 pb-5"
						style={{maxWidth: "800px", margin: "0 auto"}}
				>
					<div className='rounded-lg overflow-hidden'>
						<iframe
								className='w-full aspect-video'
								// src="https://www.youtube.com/embed/Gaf_jCnA6mc?si=9wD8p_5IGqPv4l-8"
								src={`https://www.youtube.com/embed/${videosList[activeVideo].link}${`?hl=${localStorage.getItem('ln') || 'en'}`}`}
								lang={localStorage.getItem('ln') || 'en'}
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
						</iframe>
					</div>

					<div className='flex justify-center items-center gap-1.5 mt-2.5'>
						<button
								disabled={(activeVideo <= 0)}
								className={`w-8 h-8 max-w-8 post-content-button ${(activeVideo <= 0) && 'mono'}`}
								onClick={() => handleReplaceVideo('decrement')}
						>
							&#60;
						</button>
						<Select
								value={activeVideo}
								className='h-full w-[calc(100%-100px)] max-w-[500px] text-center border-white'
								onChange={(value) => setActiveVideo(+value)}
						>
							{videosList.map((item, index) => (
									<Select.Option value={index}>{item[`name_${i18n.language}`]}</Select.Option>
							))}
						</Select>
						{/*<h6 className='w-full max-w-72 text-center font-semibold overflow-hidden overflow-ellipsis text-nowrap'>*/}
						{/*	{videosList[activeVideo][`name_${i18n.language}`]}*/}
						{/*</h6>*/}
						<button
								disabled={(activeVideo >= videosList.length - 1)}
								className={`w-8 h-8 max-w-8 post-content-button ${(activeVideo >= videosList.length - 1) && 'mono'}`}
								onClick={() => handleReplaceVideo('increment')}
						>
							&#62;
						</button>
					</div>

					<div className='my-5'>
						<Collapse accordion defaultActiveKey={['0']}>
							{faq.map((item) => (
									<CollapsePanel header={item[`title_${i18n.language}`]} key={item.id}>
										{item?.content?.map((content) => (
											content?.isHtml ?
												<div
													key={content.id}
													className='mb-4'
											 		dangerouslySetInnerHTML={{__html: content[`text_${i18n.language}`]}}
												/>:
											 	<div className='mb-4' key={content.id}>
													{content[`title_${i18n.language}`] && (
															<h5 className='text-lg font-semibold text-violet-600'>{content[`title_${i18n.language}`]}</h5>
													)}
													<pre
															className='block text-wrap text-pretty max-w-full font-inherit text-inherit'
													>
														{content[`text_${i18n.language}`]}
													</pre>
												</div>


										))}
									</CollapsePanel>
								))}
						</Collapse>
					</div>
				</div>
			</div>
);
};

export default Guideline;