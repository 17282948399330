import PageHeader from "../../components/Header/PageHeader";
import {Link, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import "./style.css";
import React, {useEffect, useState, useRef, useMemo} from "react";
import { fetchWrapper } from "../../helpers";
import {Button, Checkbox, message, Modal, Popover} from "antd";
import visaMaster from "../../files/visa-master.png";
import payment_ru from "../../files/payment_ru.pdf";
import payment_en from "../../files/payment_en.pdf";
import { Tag } from "antd";
import crownIcon from "../icons/crown.png";
import {countdownTimer} from "../../utils/countdownTimer";
import EndDateTimer from "../../components/Timers/EndDateTimer";
import PromoCodeForm from "../../components/Forms/PromoCodeForm";
import {QuestionCircleOutlined} from "@ant-design/icons";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const SubscriptionPlansPage = () => {
  const isAfterPayment = document.location.href.toLowerCase().includes("payment");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRememberMyCard, setIsRememberMyCard] = useState(true);
  const [levels, setLevels] = useState([]);
  const user = useSelector((obj) => obj.auth.user);
  const [userCreatedAt, setUserCreatedAt] = useState(undefined);
  const [isCardSaved, setIsCardSaved] = useState(false);
  const [sid, setSid] = useState(
    jwtDecode(JSON.parse(localStorage.getItem("user")).accessToken)
      .subscriptionLevel.id
  );
  const [userSubscription, setUserSubscription] = useState(jwtDecode(JSON.parse(localStorage.getItem("user")).accessToken).userSubscription);
  const [subscriptionTimer, setSubscriptionTimer] = useState({
    message: '',
    intervalLink: null,
  });
  const [subscriptionFreeTimer, setSubscriptionFreeTimer] = useState({
    message: '',
    intervalLink: null,
  });
  const interval = useRef(null);
  const payment = {
    ru: payment_ru,
    en: payment_en,
  };

  const refreshToken = () => {
    fetchWrapper.get(`${baseUrl}/auth/refresh-token`).then((res) => {
      localStorage.setItem("user", JSON.stringify(res));
      setSid(jwtDecode(res.accessToken).subscriptionLevel.id);
      setUserSubscription(jwtDecode(res.accessToken).userSubscription);
    });
  };

  const freedomPayRequest = async (sid) => {
    const resposne = await fetchWrapper.get(`${baseUrl}/freedom/card-add/${sid}`);
    return resposne;
  };

  const freedomPayHandler = (result) => {
    if (result.redirect_uri){
      console.log(result.redirect_uri);
      window.location = result.redirect_uri;
      return true;
    }

    if (result.extended){
      refreshToken();
      message.success(t("subscriptionPlans.subscribeExtended"));
      return true;
    }

    return false;
  };

  const freedomPayInitNew = async (sid) => {
    const response = await freedomPayRequest(sid);
    console.log(response)
    if (!freedomPayHandler(response)) {
      message.loading(t("functions.loading"));
      if (!freedomPayHandler(await freedomPayRequest(sid))){
        if (!freedomPayHandler(await freedomPayRequest(sid))){
          return message.error(t("functions.errorPleaseCallSupport"));
        }
      }
    }
  };

  const freedomPayInitOld = (sid) => {
    fetchWrapper.get(`${baseUrl}/freedom/request/${sid}`).then((res) => {
      console.log(res)
      window.location = res.redirectUrl;
    });
  };

  const freedomPayInit = async (sid) => {
    if (isCardSaved){
      if (!window.confirm(t("subscriptionPlans.confirmExtendSubscribe"))) return;
    }
    if (!isRememberMyCard){
      freedomPayInitOld(sid);
    } else {
      await freedomPayInitNew(sid);
    }
  };

  const cancelSubscription = (id) => {
    fetchWrapper.post(`${baseUrl}/cancel-subscription/`, {id}).then(() => {
      refreshToken();
      navigate("/profile");
    });
  };

  const removeMyCard = () => {
    if (window.confirm(t("subscriptionPlans.confirmDeleteCard"))){
      fetchWrapper.post(`${baseUrl}/freedom/card-delete/`, {}).then((res) => {
        if (res){
          message.success(t("subscriptionPlans.cardDeleted"));
          setIsCardSaved(false);
        }
      }).catch(()=>{
        message.error(t("subscriptionPlans.cardDeleteError"));
      })
    }
  }

  const handleButton = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchWrapper.get(`${baseUrl}/subscribe/pricing/`).then((res) => {
      setLevels(res);
    });

    refreshToken();

    fetchWrapper.get(`${baseUrl}/profile/`).then((res) => {
      if (res) {
        setUserProfile(res);
      }
    });
  }, []);
  console.log(userProfile);
  useEffect(() => {
    interval.current = setInterval(() => {
      refreshToken();
    }, 10000);

    return () => {
      clearInterval(interval.current);
    };
  }, [interval]);

  useEffect(() => {
    subscriptionTimer?.intervalLink && clearInterval(subscriptionTimer?.intervalLink);
    subscriptionFreeTimer?.intervalLink && clearInterval(subscriptionFreeTimer?.intervalLink);

    let userCreatedDate = undefined;

    fetchWrapper.get(`${baseUrl}/profile`).then(async (res) => {
      userCreatedDate = await new Date(res.createdAt);
      setUserCreatedAt(new Date(res.createdAt));
      if (res.cardSaved) {
        setIsCardSaved(res.cardSaved);
      }

      if (userCreatedDate){
        countdownTimer({
          endDate: new Date(userCreatedDate?.getTime() + 1209600000),
          currentDate: new Date(),
          finalMessage: 'Время истекло!',
          setter: setSubscriptionFreeTimer,
          locale: i18n.language,
        });
      }
    });



    countdownTimer({
      endDate: new Date(userSubscription?.endDate),

      currentDate: new Date(),
      finalMessage: 'Время истекло!',
      setter: setSubscriptionTimer,
      locale: i18n.language,
    });


    return () => {
      subscriptionTimer?.intervalLink && clearInterval(subscriptionTimer?.intervalLink);
      subscriptionFreeTimer?.intervalLink && clearInterval(subscriptionFreeTimer?.intervalLink);
    };
  }, [userSubscription?.endDate]);

  return (
    <>
      <PageHeader
        title={t("subscriptionPlans.header")}
        onBackClick={() => {
          navigate("/profile");
        }}
      />
      <div className="subscription-plans">
        {levels.map((level) => (
          <div
            key={level?.id}
            className={
              `subscription-plan__plan-card ${level?.levelName === "assistant" ? "disabled" : ""}`
              + `${(userCreatedAt?.getTime() + 1209600000 < new Date().getTime() && level.levelName === "free") || (sid !== undefined && level.levelName === "free") ? " !hidden " : ` levelName: ${level.levelName}`}`
            }
          >
            <div className='mb-auto'>
            <h2 className="plan-card__title">
              {t(`subscriptionPlans.${level.levelName}`)}{" "}
              {level.price.amount > 0 && <img className='premium-icon me-1.5' src={`${crownIcon}`} alt="premium"/>}
              {sid === level.id && (
                  <Tag color="green">{t("subscriptionPlans.active")}</Tag>
              )}
              {(!sid && level.levelName === "free" && userCreatedAt?.getTime() + 1209600000 > new Date().getTime()) && (
                  <Tag color="green">{t("subscriptionPlans.active")}</Tag>
              )}
            </h2>
            {/* <p className="plan-card__description">{t(`subscriptionPlans.${level.levelName}.description`)}</p> */}
              {(level.price.saleCurrency && !isAfterPayment) ? (
                  <div className='relative'>
                    <div className='relative inline-block'>
                      <p className="sale-card__price">
                        {level.price.amount || 0}{i18n.language === 'en' ? '$' : '₸'}
                      </p>
                      <p className="plan-card__price --sale">{level.price.initialAmount || 0}{i18n.language === 'en' ? '$' : '₸'}</p>
                    </div>
                    <div>
                    {!!level.price.dateEnd && (
                        <small className='block text-xs leading-1 text-center mb-2.5'>
                          {t("subscriptionPlans.offerExpire")}: <br/>
                          <EndDateTimer finalMessage={t("subscriptionPlans.offerOver")} currentDate={new Date()} endDate={level.price.dateEnd}/>
                        </small>
                    )}
                    </div>
                  </div>
              ) : (
                  <p className="plan-card__price">{level.price.amount || 0} {i18n.language === 'en' ? '$' : '₸'}</p>
              )}
            </div>
            {(!level.price.amount && (!sid && level.levelName === "free")) && (
                <>
                  <small className='text-center my-2.5'>{t("profile.endFreeSubscriptionDate")}
                    <br/>
                    {!!userCreatedAt?.getTime() &&
                      <EndDateTimer finalMessage={t("subscriptionPlans.subscribeExpired")} currentDate={new Date()} endDate={userCreatedAt?.getTime() + 1209600000}/>
                    }
                  </small>
                </>
            )}

            {level.price.amount > 0 ? (
                <>
                  {sid === level.id ? (
                      <>
                      <small className='text-center my-2.5'>{t("profile.endSubscriptionDate")}
                        <br/>
                        <EndDateTimer finalMessage={t("subscriptionPlans.subscribeExpired")} currentDate={new Date()} endDate={userSubscription?.endDate}/>
                      </small>
                        {isCardSaved ? (
                            <div className={`mx-auto mb-2.5 rounded-lg flex items-center justify-center px-2 py-1.5`}>
                              <button className='text-white/80 hover:text-white font-semibold px-2 pt-1' onClick={removeMyCard}>
                                {t("subscriptionPlans.removeMyCardBtn")}
                              </button>
                            </div>
                        ): <div className={`mx-auto mb-2.5 rounded-lg flex items-center justify-center px-2 py-1.5`}>
                          <Popover
                              overlayStyle={{maxWidth: 500}}
                              placement='top'
                              content={() => (
                                  <pre style={{fontSize: '12px'}}
                                       className='text-[10px] block text-wrap text-pretty max-w-full font-inherit text-inherit'>
                                  {t("subscriptionPlans.rememberMyCardPopoverText")}
                                </pre>
                              )}
                              title={t("subscriptionPlans.rememberMyCardPopoverTitle")}
                          >
                            <Checkbox
                                checked={isRememberMyCard}
                                onChange={(e) => setIsRememberMyCard(e.target.checked)}
                                style={{
                                  color: isRememberMyCard? "#000" : "#fff",
                                  filter: isRememberMyCard? "invert(1)" : "none",
                                  fontWeight: "500",
                                }}
                            >
                              {t("subscriptionPlans.rememberMyCard")}
                            </Checkbox>

                            <QuestionCircleOutlined
                                className='text-white rounded-full text-md cursor-help transition-opacity opacity-80 hover:opacity-100'/>
                          </Popover>
                        </div>
                        }

                      <button
                          onClick={() => freedomPayInit(level.id)}
                          className="plan-card__button --extend"
                      >
                        {t("profile.extendSubscription")}
                      </button>
                      <button
                          onClick={() => cancelSubscription(level.id)}
                          className="plan-card__button mt-2 --cancel opacity-0 pointer-events-none"
                      >
                        {t("profile.cancelSubscription")}
                      </button>
                    </>
                ) : (
                      level?.levelName === "assistant" ? (
                          <>

                            <small className='text-center my-2.5'>
                              {t("profile.assistDescription")}
                            </small>

                            <button
                                onClick={() => message.info(t('profile.comingSoon'))}
                                className="plan-card__button"
                            >
                              {t("profile.soon")}
                            </button>

                            <button
                                onClick={() => message.info('Уже совсем скоро...')}
                                className="plan-card__button mt-2 --cancel opacity-0 pointer-events-none"
                            >
                              {t("profile.cancelSubscription")}
                            </button>

                          </>
                      ) : (
                          <>
                            <div className={`mx-auto mb-2.5 rounded-lg flex items-center justify-center px-2 py-1.5`}>
                              <Popover
                                  overlayStyle={{maxWidth: 500}}
                                  placement='top'
                                  content={() => (
                                      <pre style={{fontSize: '12px'}}
                                           className='text-[10px] block text-wrap text-pretty max-w-full font-inherit text-inherit'>
                                  {t("subscriptionPlans.rememberMyCardPopoverText")}
                                </pre>
                                  )}
                                  title={t("subscriptionPlans.rememberMyCardPopoverTitle")}
                              >
                              <Checkbox
                                  checked={isRememberMyCard}
                                  onChange={(e) => setIsRememberMyCard(e.target.checked)}
                                  style={{
                                    color: isRememberMyCard? "#000" : "#fff",
                                    filter: isRememberMyCard? "invert(1)" : "none",
                                    fontWeight: "500",
                                  }}
                              >
                                {t("subscriptionPlans.rememberMyCard")}
                              </Checkbox>

                                <QuestionCircleOutlined
                                    className='text-white rounded-full text-md cursor-help transition-opacity opacity-80 hover:opacity-100'/>
                              </Popover>
                            </div>

                            <button
                                onClick={() => freedomPayInit(level.id)}
                                className="plan-card__button"
                            >
                              {t("profile.buySubscription")}
                            </button>
                          </>
                      )
                  )}
                </>
            ) : (
                <></>
            )}
            {(level?.levelName === "paid") ? (
                <p className="plan-card__list">
                  {t("subscriptionPlans.unlimitedRate")}
                    </p>
            ) : level?.levelName === "assistant" ? (
                <p className="plan-card__list">
                  {t("subscriptionPlans.assistantRate")}
                </p>
            ): (
                <p className="plan-card__list">
                  {t("subscriptionPlans.freeRate")}
                </p>
                // <ul className="plan-card__list">
                //   {level.features.actions.map((action) => (
                //       <li>
                //         {t(`subscriptionPlans.${action.type}`)}: {action.limit}
                //       </li>
                //   ))}
                // </ul>
            )}
          </div>
        ))}
      </div>
      <div
          className="main-content"
          style={{marginTop: "40px", marginBottom: "20px", textAlign: "center"}}
      >
        <button
            className="survey-button"
            onClick={() => {
            showModal();
          }}
        >
          {t("subscriptionPlans.infoButton")}
        </button>
        <div className='mt-10 max-w-xs mx-auto'>
          <PromoCodeForm/>
        </div>
        <div className="signup-wrapper">
          <div className="signup-container">
            <div className="divider">
              <span className="divider-line"></span>
            </div>
            <div
              className="main-content"
              style={{
                marginTop: "40px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              <Link
                  className="signin-link"
                  to={`/document/payment`}
              >
                <img src={visaMaster} style={{ cursor: "pointer" }} alt="" />
                <span className="link">{t("signin.payment")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={t("subscriptionPlans.modalTitle")}
        open={isModalOpen}
        onOk={handleButton}
        onCancel={handleButton}
        footer={[
          <Button key="submit" onClick={handleButton} type="primary">
            {t("subscriptionPlans.submit")}
          </Button>,
        ]}
      >
        <p
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{ __html: t("subscriptionPlans.modalText") }}
        >
          {/*{t("subscriptionPlans.modalText")}*/}
        </p>
      </Modal>
    </>
  );
};
