import React, { useEffect, useState, useRef } from "react";
import PageHeader from "../../components/Header/PageHeader";
import BottomNav from "./BottomNavContentPlan";
import { useDispatch, useSelector } from "react-redux";
import instagramContentIcon from "../icons/instagram-content.svg"; // Replace with path to your Google icon
import facebookContentIcon from "../icons/facebook-content.svg"; // Replace with path to your Google icon
import linkedInContentIcon from "../icons/linkedin-content.svg"; // Replace with path to your Google icon
import emptyContentPlanIcon from "../icons/empty-content-plan.svg"; // Replace with path to your Google icon
import DropdownContent from "./DropdownContent";
import { contentPlanActions } from "../../store";
import ContentPlanDrawer from "./ContentPlanDrawer";
// import { forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {message, notification, Space, Button, Checkbox} from "antd";
import {jwtDecode} from "jwt-decode";
import {fetchWrapper} from "../../helpers";
import {daysOfWeek} from "../../constants/daysOfWeek";

const icons = [instagramContentIcon, facebookContentIcon, linkedInContentIcon];
const colors = ["#EEEBFF", "#FFEBF9", "#EBF0FF"];
const baseUrl = `${process.env.REACT_APP_API_URL}`;
const generateLangs = [
  {
    id: 1,
    label: {
      ru: "на русском языке",
      en: "in russian language",
    },
    value: "на русском языке",
  },
  {
    id: 2,
    label: {
      ru: "на английском языке",
      en: "in english language"
    },
    value: "in english language",
  }
];
export const CreateContentPlan = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    socialNetworks,
    contentPlans,
    weeks,
    loading,
    generating,
    loadingCreate,
  } = useSelector((obj) => obj.contentPlan);
  const [fbPageId, setFbPageId] = useState('');
  const [isToLate, setIsToLate] = useState(false);
  const [activeWeek, setActiveWeek] = useState(weeks[0]);
  const [hasContentPlanForWeek, setHasContentPlanForWeek] = useState();
  const [currentWeekSnCreated, setCurrentWeekSnCreated] = useState({});
  const [selectedGeneratingWeekDays, setSelectedGeneratingWeekDays] = useState(daysOfWeek);
  const [activeContentPlans, setActiveContentPlans] = useState({});
  const [visibleOpenModal, setVisibleModal] = useState(false);
  const interval = useRef(null);
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();
  const user = useSelector((obj) => obj.auth.user);
  const [token, setToken] = useState(false);
  const blocks = useSelector((obj) => obj.strategy.blocks);
  const [localUser, setLocalUser] = useState(
      JSON.parse(localStorage.getItem("user"))
  );
  const [generateLang, setGenerateLang] = useState(i18n.language === 'ru'? generateLangs[0].value : generateLangs[1].value);
  const [sn, setSn] = useState('');
  const [tokens, setTokens] = useState({
    instagram: false,
    facebook: false,
    linkedin: false
  })

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type="primary"
          size="small"
          onClick={() => navigate("/support")}
        >
          {t("posts.goToSupport")}
        </Button>
      </Space>
    );
    notificationApi.open({
      message: t("functions.error"),
      description: t("posts.unknownError"),
      btn,
      key,
    });
  };

  const openNotificationEndSubscribe = () => {
    const key = `open${Date.now()}`;
    const btn = (
        <Space>
          <Button
              type="primary"
              size="small"
              onClick={() => navigate("/subscription-plans")}
          >
            {t("posts.goToRate")}
          </Button>
        </Space>
    );
    notificationApi.open({
      message: t("functions.error"),
      description: t("posts.limit"),
      btn,
      key,
    });
  };

  const openNotificationSocialNetwork = ({message, description}) => {
    const key = `open${Date.now()}`;
    const btn = (
        <Space>
          <Button
              type="primary"
              size="small"
              onClick={() => navigate("/profile")}
          >
            {t("posts.goToProfile")}
          </Button>
        </Space>
    );
    notificationApi.open({
      message: message ||t("functions.error"),
      description: description || t("posts.unknownError"),
      btn,
      key,
    });
  };

  const generateContentPlan = async (values) => {
    message.loading(t("functions.generating"));
    await Promise.all(
      values.socialNetworks.map(async (sn) =>
        dispatch(
          contentPlanActions.createContentPlan({
            socialNetwork: sn,
            content_blocks: values.contentBlocks,
            frequency: values.frequency,
            timezone: -new Date().getTimezoneOffset(),
            lang: generateLang,
            week: activeWeek,
            days: selectedGeneratingWeekDays,
            openNotificationEndSubscribe,
            openNotification,
          })
        )
      )
    )
      .then(() => {
        message.success(t("functions.successfullySendToGenerate"));
      })
      .catch((error) => {
        // console.log(error);
        // if (error === 'Достигнут лимит действий'){
        //   openNotificationEndSubscribe();
        // } else {
        //   openNotification();
        // }
        message.error(t("functions.wrong"));
      });
    setVisibleModal(false);
  };

  const openNotificationLinkedIn = () => {
    const key = `open${Date.now()}`;
    const btn = (
        <Space>
          <Button type="primary" size="small" onClick={() => navigate("/profile")}>
            {t("profile.linkAccount")}
          </Button>
        </Space>
    );
    notificationApi.open({
      message: t("contentPlan.tokenError"),
      btn,
      key,
    });
  };

  const handleGenerateContentPlan = () => {
    if(!token){
      openNotificationLinkedIn()
    }else{
      generateContentPlan({
        contentBlocks: blocks,
        socialNetworks: sn.split(','),
        frequency: 7,
      })
    }
  }

  useEffect(() => {
    setActiveWeek(weeks.length);
  }, [weeks]);

  useEffect(() => {
    if (generating) {
      interval.current = setInterval(() => {
        dispatch(contentPlanActions.getContentPlan(socialNetworks));
      }, 5000);
    } else {
      clearInterval(interval.current);
      interval.current = null;
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [dispatch, generating, interval]);

  const handleChangeSocialNetworks = (value) => {
    if (!tokens[value.toLowerCase()]){
      return openNotificationSocialNetwork({
        message: t("functions.error"),
        description: t(`posts.notLinked-${value.toLowerCase()}`),
      });
    }
    setSn((prevState) => {
      if (prevState.includes(value)) {
        return prevState.split(',').filter(item => item !== value).join(',')
      } else {
        if (prevState === '') {
          return value
        } else {
          return `${prevState},${value}`
        }
      }
    });
  }

  // useEffect(() => {
  //   if (socket) {
  //     socket.on('finishedContentPlan', () => {
  //       dispatch(contentPlanActions.getContentPlan(socialNetworks))
  //     })
  //   }

  // }, [socket])

  useEffect(() => {
    dispatch(contentPlanActions.getContentPlan(socialNetworks));
  }, []);

  useEffect(() => {
    let lastDate = '';
    const freshCurrentWeekSnCreated = {};
    let tempHasCn = false;
    const tempCn = {};

    for (const sn of socialNetworks) {
      const filteredCn = contentPlans[sn.fetch].filter(
        (cn) => cn.week == activeWeek
      );

      if (filteredCn.length) {
        freshCurrentWeekSnCreated[sn.generate] = true;
      } else {
        freshCurrentWeekSnCreated[sn.generate] = false;
      }

      if (filteredCn.length > 0) {
        tempHasCn = true;
      }
      tempCn[sn.fetch] = filteredCn;

      if (!lastDate || filteredCn[filteredCn.length - 1]?.date < lastDate){
        lastDate = filteredCn[filteredCn.length - 1]?.date;
      }
    }

    if (new Date(lastDate) < new Date()) {
      setIsToLate(true);
    } else {
      setIsToLate(false);
    }

    setCurrentWeekSnCreated(freshCurrentWeekSnCreated);
    setHasContentPlanForWeek(tempHasCn);
    setActiveContentPlans(tempCn);
  }, [activeWeek, contentPlans, loading, socialNetworks]);

  useEffect(() => {
    fetchWrapper
        .get(`${baseUrl}/auth/getSocialMediaTokens/`)
        .then((res) => {
          let filteredSn = [];
          const filteredToken = {
            facebook: false,
            instagram: false,
            linkedin: false,
          };
          res.forEach((token) => {
            if (token.socialMediaType === "FACEBOOK") {
              filteredToken.facebook = true;
              if (!currentWeekSnCreated.facebook) {
                filteredSn.push('Facebook')
              }
              setToken(true)
            }
            if (token.socialMediaType === "INSTAGRAM") {
              filteredToken.instagram = true;
              if (!currentWeekSnCreated.instagram) {
                filteredSn.push('Instagram')
              }
              setToken(true)
            }
            if (token.socialMediaType === "LINKEDIN") {
              filteredToken.linkedin = true;
              if (!currentWeekSnCreated.linkedin) {
                filteredSn.push('LinkedIn')
              }
              setToken(true)
            }
          });
          setTokens(prevState => filteredToken);
          setSn(filteredSn.join(','));
        })
        .catch(() => {});
  }, [currentWeekSnCreated]);

  useEffect(() => {
    fetchWrapper.get(`${baseUrl}/profile/`).then((res) => {
      setFbPageId(res.facebookPageId);
    });
  }, []);

  const isEmpty = !loading && !hasContentPlanForWeek;

  return (
    <>
      {notificationContextHolder}
      <PageHeader
        title={t("contentPlan.header")}
        onBackClick={() => {
          navigate("/home");
        }}
      />
      {generating ? (
        <div>
          <h1 className="self-assessment-title">
            {t("functions.generatingContentPlan")}
          </h1>
        </div>
      ) : (
        <>
          <div>
            <h1 className="self-assessment-title">
              {t("contentPlan.week")} {activeWeek}{" "}
              {
                activeContentPlans[socialNetworks[0]?.fetch]?.length > 0 ||
                activeContentPlans[socialNetworks[1]?.fetch]?.length > 0 ||
                activeContentPlans[socialNetworks[2]?.fetch]?.length > 0
                ? "(" +
                  (moment(
                activeContentPlans[socialNetworks[0]?.fetch]?.[0]?.date ||
                    activeContentPlans[socialNetworks[1]?.fetch]?.[0]?.date ||
                    activeContentPlans[socialNetworks[2]?.fetch]?.[0]?.date
                  ).format("DD/MM/YYYY") +
                    " - " +
                    moment(
                      activeContentPlans[socialNetworks[0]?.fetch]?.[
                        activeContentPlans[socialNetworks[0]?.fetch]?.length - 1
                      ]?.date ||
                        activeContentPlans[socialNetworks[1]?.fetch]?.[
                        activeContentPlans[socialNetworks[1]?.fetch]?.length - 1
                            ]?.date ||
                        activeContentPlans[socialNetworks[2]?.fetch]?.[
                        activeContentPlans[socialNetworks[2]?.fetch]?.length - 1
                            ]?.date
                    ).format("DD/MM/YYYY")) +
                  ")"
                : null}
            </h1>
          </div>

          {localUser.strategiesCount < 5 ? (
              <div className="flex flex-col items-center justify-center">
                <h3 className='text-xl text-center font-semibold my-10'>
                  {t("contentPlan.needSurveyText")}
                </h3>
                <button
                  className="logout-button mx-auto max-w-max max-w-fit"
                  onClick={() => navigate("/survey")}
                >
                  {t("contentPlan.goToSurveyBtnText")}
                </button>
              </div>
              ):
              activeWeek === 3 && localUser.strategiesCount < 15 ? (
                      <div className="flex flex-col items-center justify-center">
                        <h3 className='text-xl text-center font-semibold my-10'>
                          {t("contentPlan.needSurveyText")}
                        </h3>
                        <button
                            className="logout-button mx-auto max-w-max max-w-fit"
                            onClick={() => navigate("/personality-survey")}
                        >
                          {t("contentPlan.goToSurveyBtnText")}
                        </button>
                      </div>
              ):
            isEmpty ? (
                <div className="flex flex-col items-center justify-center">
                  <div
                      className="flex gap-4 justify-center py-4 border-b border-solid border-b-[color:var(--Primary-200,#E9D7FE)]">
                    <div style={{opacity: sn?.includes("LinkedIn") ? 1 : 0.4}}
                         onClick={() => handleChangeSocialNetworks("LinkedIn")}
                         className="flex flex-col flex-1 cursor-pointer">
                      <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d25408c5dff59b86cb5e75674b2a798a87cd455bde0adf0ceb8c9a670d1405e8?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                          className="self-center w-6 aspect-square"
                      />
                      <div className="mt-1">LinkedIn</div>
                    </div>

                    <div style={{opacity: sn?.includes("Facebook") ? 1 : 0.4}}
                         onClick={() => handleChangeSocialNetworks("Facebook")}
                         className={`flex flex-col flex-1 cursor-pointer ${process.env.REACT_APP_IS_HIDE_FACEBOOK === 'true' && '!hidden'}`}>
                      <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8432315b7e3752ab28ffd9571f771342eef6b676c1f20e4b8a5e6ee48c70349?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                          className="self-center w-6 aspect-square"
                      />
                      <div className="mt-1">Facebook</div>
                    </div>

                    <div style={{opacity: sn?.includes("Instagram") ? 1 : 0.4}}
                         onClick={() => handleChangeSocialNetworks("Instagram")}
                         className="flex flex-col flex-1 cursor-pointer">
                      <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/497038a4df4615b7ef35db19c49364c0fdcd7e3749f9681fe807b55de2426e88?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                          className="self-center w-6 aspect-square"
                      />
                      <div className="mt-1">Instagram</div>
                    </div>

                  </div>
                  <div>
                    <h5 className='text-center text-md font-semibold my-2'>
                      {t("contentPlan.selectWeekDaysTitle")}
                    </h5>
                    <div className='max-w-max max-w-fit mx-auto mt-4 flex flex-col gap-2'>
                      {daysOfWeek.map((day) => (
                          <Checkbox
                            key={day}
                            checked={ selectedGeneratingWeekDays.includes(day) }
                            onChange={(e)=>{
                              setSelectedGeneratingWeekDays(e.target.checked ? [...selectedGeneratingWeekDays, day] : selectedGeneratingWeekDays.filter(item => item !== day))
                              // if(e.target.checked){
                              //   setSelectedGeneratingWeekDays([...daysOfWeek, day])
                              // }else{
                              //   setSelectedGeneratingWeekDays(daysOfWeek.filter(item => item !== day))
                              // }
                            }}
                          >
                            {t(`weekDays.${day}`)}
                          </Checkbox>
                      ))}
                    </div>
                  </div>

                  <button
                      disabled={!sn || !selectedGeneratingWeekDays.length}
                      className="logout-button max-w-fit max-w-max disabled:opacity-50 disabled:cursor-not-allowed"
                      // className="survey-button"
                      // onClick={() => setVisibleModal(true)}
                      onClick={handleGenerateContentPlan}
                  >
                    {t("contentPlan.create")}
                  </button>
                  <select
                      className='border rounded-lg p-2.5 mt-2.5 text-center no-arrows cursor-pointer hidden'
                      value={generateLang}
                      onChange={e => setGenerateLang(e.target.value)}
                  >
                    {generateLangs.map((lng) => (
                        <option key={lng.id} value={lng.value}>{lng.label[i18n.language]}</option>
                    ))}
                  </select>
                  <img src={emptyContentPlanIcon}/>
                </div>
            ) : (
                <>
                  {socialNetworks?.map((socialsNetwork, index) => (
                      <DropdownContent
                          key={socialsNetwork.fetch}
                          color={colors[index]}
                          icon={icons[index]}
                          items={activeContentPlans[socialsNetwork?.fetch]}
                          fbPageId={fbPageId}
                      />
                  ))}
                </>
            )}
          <div style={{height: 70}}></div>
          <BottomNav
              // onClickFont={() => setVisibleModal(true)}
              onClick={() =>
                  generateContentPlan({
                    contentBlocks: blocks,
                    socialNetworks: ["LinkedIn"],
                    frequency: 7,
                  })
              }
              isFont={hasContentPlanForWeek}
              isFilled={(()=>{
                let isFullFilled = true
                socialNetworks.forEach(sn => {
                  if (!currentWeekSnCreated[sn.generate] && !isToLate) {
                    isFullFilled = false
                  }
                })
                return isFullFilled;
              })()}
              onClickBefore={() =>
                  activeWeek > 1 && setActiveWeek(activeWeek - 1)
              }
              onClickNext={() => setActiveWeek(activeWeek + 1)}
              onClickFont={() => setVisibleModal(true)}
          />
          <ContentPlanDrawer
              loadingCreate={loadingCreate}
              onOk={handleGenerateContentPlan}
              open={visibleOpenModal}
              close={() => setVisibleModal(false)}
              activeWeek={activeWeek}
              handleChangeSocialNetworks={handleChangeSocialNetworks}
              socialNetworks={sn}
              currentWeekSnCreated={currentWeekSnCreated}
              selectedGeneratingWeekDays={selectedGeneratingWeekDays}
              setSelectedGeneratingWeekDays={setSelectedGeneratingWeekDays}
          />
        </>
      )}
    </>
  );
};
