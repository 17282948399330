import checkIcon from "../icons/check-icon.svg"; // Replace with path to your Google icon
import uncheckIcon from "../icons/uncheck-icon.svg"; // Replace with path to your Google icon
import questionIcon from "../icons/question-icon.svg"; // Replace with path to your Google icon
import arrowRightIcon from "../icons/arrow-right-content.svg"; // Replace with path to your Google icon
import refreshIcon from "../icons/refresh.svg"; // Replace with path to your Google icon
import moment from "moment";
import {Link, useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { fetchWrapper } from "../../helpers";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import penIcon from "../icons/pen-icon.svg";
import {Button, message, notification, Popover, Space} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const PlanCard = ({ item, fbPageId, index, isQuickPublication=false }) => {
  const [notificationApi, notificationContextHolder] =
      notification.useNotification();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isMinified, setIsMinified] = useState(true);
  const [postThemeEditText, setPostThemeEditText] = useState(false);
  const [postThemeText, setPostThemeText] = useState(item.description);
  const [post, setPost] = useState();
  const [isHide, setIsHide] = useState(false);
  const [postHasError, setPostHasError] = useState(false);

  const changePostThemeText = () => {
    if (postThemeEditText) {
      fetchWrapper.put(`${baseUrl}/content-plan/update/${item.id}`, {
        description: postThemeText,
      });
    }
    setPostThemeEditText(!postThemeEditText);
  };

  const openNotificationFbPages = () => {
    const key = `open${Date.now()}`;
    const btn = (
        <Space>
          <Button
              type="primary"
              size="small"
              onClick={() => navigate("/profile")}
          >
            {t("posts.goToProfile")}
          </Button>
        </Space>
    );
    notificationApi.open({
      message: t("functions.warning"),
      description: t("posts.notSelectedFacebookPage"),
      btn,
      key,
    });
  };

  const approve = () => {
    if (item.socialNetwork.toLowerCase().includes('instagram') && (!post?.images?.length && !post?.video)){
      message.warning(t('functions.minMediaForInstagramPostMessage'));
      return;
    }
    if (item.socialNetwork.toLowerCase().includes('facebook') && !fbPageId){
      openNotificationFbPages()
      return;
    }

    let statuses = [];

    if (post?.published_statuses?.length) {
      post?.published_statuses?.forEach((network) => {
        if (network?.publishedError){
          statuses.push({
            ...network,
            publishedError: null
          })
        } else {
          statuses.push(network)
        }
      });
    } else {
      statuses = null
    }


    fetchWrapper
      .post(`${baseUrl}/post/approve/`, { postId: post.id })
      .then((res) => {
        setPost({ ...post, broken: false, approved: true, published_statuses: statuses });
        setPostHasError(false);
      })
      .catch(() => {});
  };

  const disapprove = () => {
    fetchWrapper
      .post(`${baseUrl}/post/disapprove/`, { postId: post.id })
      .then((res) => {
        setPost({ ...post, approved: false });
      })
      .catch(() => {});
  };

  const hide = () => {
    if (window.confirm(t("plancard.hiddeConfrim"))) {
      fetchWrapper
          .post(`${baseUrl}/post/hide/${item?.uniqueIndexId}`, { postId: item?.uniqueIndexId })
          .then((res) => {
            setPost();
            setIsHide(true);
            message.success(t("plancard.successfullyHidden"));
          })
          .catch(() => {
            message.error(t("functions.error"));
          });
    }
  };

  useEffect(() => {
    fetchWrapper
      .get(`${baseUrl}/post/actual/${item.id}`)
      .then((res) => {
        setPost(res);
      })
      .catch(() => {});
  }, [item, index]);

  useEffect(() => {
    let isErr = false;
    post?.published_statuses?.forEach((network) => {
      if (network?.publishedError){
      console.log(network)
        isErr = true;
      }
    });
    if (isErr) {
      setPostHasError(true);
    } else {
      setPostHasError(false);
    }
  }, [post]);

  if (isHide) return null;

  return (
    <div key={index} className="schedule-item">
      {notificationContextHolder}
      <div className="schedule-details">

        {item.weekDay ? (
            <span className="schedule-day">{t(`weekDays.${item.weekDay}`)}</span>
        ) : (
            ""
        )}

        {Array.isArray(post?.published_statuses) ? (
            <Popover
                overlayStyle={{maxWidth: 500}}
                placement='bottomLeft'
                content={() => (
                    <div
                        style={{
                          fontSize: "12px",
                        }}
                      className='flex flex-col gap-1 text-[10px] block text-wrap text-pretty max-w-full font-inherit text-inherit'
                    >
                        {post?.published_statuses?.length && (
                            post?.published_statuses?.map((network, index) => (
                                <span
                                    className={`${network?.isPublished ? 'text-green-600' : network?.publishedError ? 'text-red-600' : ''}`}
                                    key={index}>
                                  <b className='font-semibold'>
                                  {network?.name}:{" "}
                                  </b>
                                  {network?.isPublished
                                      ? `${t("post.published")} ${new Date(network?.publishedAt)?.toLocaleDateString()}`
                                      : network?.publishedError
                                          ? `${t("post.broken")} "${network?.publishedError[i18n.language]? network?.publishedError[i18n.language] : network?.publishedError }"`
                                          : t("post.notpublished")}
                                </span>
                            ))
                        )}
                    </div>
                )}
                title={t("posts.postStatusesInfoTitle")}
            >
              <span className="relative group schedule-time font-bold flex items-center gap-2 cursor-help">
                  {post?.published_statuses?.length && (
                      post?.published_statuses?.map((network, index) => (
                          <span
                              className={`${network?.isPublished ? 'text-green-600' : network?.publishedError ? 'text-red-600' : ''}`}
                              key={index}>{network?.name}</span>
                      ))
                  )}
              </span>
            </Popover>

        ) : (
            <span className="schedule-time font-bold flex items-center gap-2">
              {item?.socialNetwork.split(',').map((network, index) => (
                  <span key={index}>{network}</span>
              ))}
            </span>
        )}


        <span className="schedule-time">
          {moment(item.date).format("DD/MM/YYYY")}
        </span>
        {/* <span className="schedule-type">{item.contentType}</span> */}
        {post ? (
            <>
            <span
                className={`schedule-type ${
                    post.post !== "" ? "" : "--schedule-yellow-badge"
                }`}
            >
              {post.post !== ""
                  ? t("plancard.generated")
                  : t("plancard.notGenerated")}
            </span>
              <span
                  className={`schedule-type ${
                      !post.approved
                          ? "--schedule-red-badge"
                          : "--schedule-green-badge"
                  }`}
              >
              {post.approved
                  ? t("plancard.approved")
                  : t("plancard.notApproved")}
            </span>

              <span
                  className={`schedule-type ${
                      post.published
                          ? "--schedule-green-badge"
                          : "--schedule-red-badge"
                  }`}
              >
              {post.published ? t("post.published") : post.broken ? t("post.broken") : t("post.notpublished")}
            </span>

            </>
        ) : (
            <span className={"schedule-type --schedule-yellow-badge"}>
            {t("plancard.notGenerated")}
          </span>
        )}
      </div>
      <div className="schedule-info">
        <div className="schedule-description flex items-center">
          <div style={{maxWidth: "3rem"}}>
            <img
                style={{width: "25px", height: "25px", maxWidth: "unset" }}
              className="cursor-pointer"
              onClick={() => post?.published ? null : changePostThemeText()}
              src={postThemeEditText || post?.published ? checkIcon : penIcon}
            />
          </div>

          {postThemeEditText ? (
            <div className="pl-3 w-full">
              <textarea
                value={postThemeText}
                onChange={(e) => setPostThemeText(e.target.value)}
                className="resize-none w-full h-32 sm:h-full justify-start"
              ></textarea>
            </div>
          ) : (
            <div
              className="pl-3 text-ellipsis overflow-hidden h-10 line-clamp-2"
            >
              {postThemeText}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="refresh-icon-placeholder">
            {isQuickPublication && (
                <p
                    onClick={() => hide()}
                    className="plan-card-button --schedule-red-badge"
                >
                  {t("plancard.hide")}
                </p>
            )}
            {post && (
                <>
                  {postHasError ? (
                          <p
                              onClick={() => approve()}
                              className="plan-card-button --schedule-green-badge"
                          >
                            {t("plancard.approveAgain")}
                          </p>
                      ):
                    post?.approved ? (
                      <p
                          onClick={() => disapprove()}
                          className="plan-card-button --schedule-red-badge"
                      >
                        {t("plancard.disapprove")}
                      </p>
                  ) :
                    post?.published ? null : (
                      <p
                          onClick={() => approve()}
                          className="plan-card-button --schedule-green-badge"
                      >
                        {new Date(item.date).getTime() < new Date().getTime() ?
                            t("plancard.publish")
                            : t("plancard.approve")
                        }
                      </p>
                  )}
                </>
            )}
          </div>
          <Link to={`/create-post/${item.id}`}>
            <div className="arrow-icon-placeholder">
              <img src={arrowRightIcon}/>
            </div>
          </Link>
        </div>
      </div>
      {post && (
        <Link to={`/create-post/${item.id}`}>
          <div
            className={"justify-center px-3 py-2 mt-2.5 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50" +
                ` ${isMinified? 'text-ellipsis text-nowrap overflow-hidden' : ''}`}
          >
            {post.post}
          </div>
        </Link>
      )}
      {post && (
        <div
          className="cursor-pointer max-w-max max-w-fit mx-auto justify-center py-1 mt-1 mb-2 m-auto text-xs text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4"
          onClick={()=> setIsMinified(ps => !ps)}
        >
          {isMinified ? t("functions.expand") : t("functions.collapse")}
        </div>
      )}
    </div>
  );
};
