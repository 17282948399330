import React, {useEffect, useState} from 'react';
import PageHeader from "../../../components/Header/PageHeader";
import activeMic from "../../icons/mic-on.svg";
import inactiveMic from "../../icons/mic-off.svg";
import i18n from "i18next";
import {
	Button,
	Checkbox,
	Collapse,
	Drawer,
	InputNumber,
	message,
	notification,
	Popover,
	Select,
	Slider,
	Space
} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import AudioRecorder from "audio-recorder-polyfill";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {fetchWrapper} from "../../../helpers";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const HTWSPostCreate = () => {
	window.MediaRecorder = AudioRecorder;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [postDescription, setPostDescription] = useState("");
	const [messageApi, contextHolder] = message.useMessage();
	const [notificationApi, notificationContextHolder] =
			notification.useNotification();
	const getInputDatetime = () => {
		const d = new Date();
		return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
				.toISOString()
				.slice(0, -5)
				.slice(0, -3);
	};
	const [date, setDate] = useState(() => getInputDatetime());
	const [sn, setSn] = useState("LinkedIn,Instagram");
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [contentFormats, setContentFormats] = useState([]);
	const [selectedContentFormatId, setSelectedContentFormatId] = useState(null);
	const [selectedContentLangs, setSelectedContentLangs] = useState([]);
	const [postLength, setPostLength] = useState(250);
	const [postEmojis, setPostEmojis] = useState(1);
	const [postLoading, setPostLoading] = useState(false);
	const [isMicActive, setIsMicActive] = useState(false);
	const [mediaRecorder, setMediaRecorder] = useState(null);
	const [audioChunks, setAudioChunks] = useState([]);


	const onSubmit = (e) => {
		setPostLoading(true);
		e.preventDefault();
		const event = new Date(date);
		fetchWrapper
				.post(`${baseUrl}/post/pre-test-post`, {
					description: postDescription,
					socialNetwork: sn,
					date: event.toISOString(),
					formatId: selectedContentFormatId,
					selectedContentLangs,
					postLength,
					postEmojis,
				})
				.then((res) => {
					// console.log(res)
					const sessionPosts = JSON.parse(sessionStorage.getItem('posts')) || [];
					const createdPost = {
						id: sessionPosts.length + 1,
						socialNetwork: sn,
						date: new Date().toISOString(),
						...res
					};
					sessionStorage.setItem('posts', JSON.stringify([...sessionPosts, createdPost]));
					navigate(`/how-to-work-post/${sessionPosts.length + 1}`);
				})
				.catch((err) => {
					setPostLoading(false);
				});
	};

	const startRecording = () => {
		console.log(date);
		navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then((stream) => {
					setIsMicActive(true);
					const recorder = new MediaRecorder(stream);
					recorder.start();
					setMediaRecorder(recorder);
					recorder.addEventListener("dataavailable", (e) => {
						message.loading(t("functions.loading"));
						const audioBlob = new Blob([e.data], { type: "audio/wav" });
						const audioFile = new File([audioBlob], "recording.wav", {
							type: "audio/wav",
						});
						const formData = new FormData();
						formData.append("audio", audioFile);

						axios({
							method: "POST",
							url: `${process.env.REACT_APP_API_URL}/upload-audio`,
							headers: {
								"Content-Type": "multipart/form-data",
							},
							data: formData,
						})
								.then(
										(data) =>
												data.data &&
												setPostDescription(
														(postDescription) => `${postDescription || ""}${data.data}`
												)
								)
								.catch((error) => console.error(error));
					});
				})
				.catch((error) => {
					message.error(t("functions.microphone"));
				});
	};

	const stopRecording = () => {
		mediaRecorder?.stop();
		// You might want to do something with the audio chunks here, like creating a blob and saving or playing it
		const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
		// Resetting audio chunks after recording is stopped
		setAudioChunks([]);
		setIsMicActive(false);
		// Here, you could save the blob or do something else with it
	};

	useEffect(() => {
		return () => {
			mediaRecorder?.state === "recording" && mediaRecorder.stop();
		};
	}, [mediaRecorder]);

	const toggleMic = () => {
		if (isMicActive) {
			stopRecording();
		} else {
			startRecording();
		}
	};

	const onChangeTextarea = (e) => {
		setPostDescription(e.target.value);
		// if (
		//   i18n.language === "en" &&
		//   (new RegExp(/^[a-zA-Z0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
		//     e.target.value
		//   ) ||
		//     e.target.value === "")
		// ) {
		//   setPostDescription(e.target.value);
		// } else if (
		//   i18n.language === "ru" &&
		//   (new RegExp(/^[а-яА-Я0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
		//     e.target.value
		//   ) ||
		//     e.target.value === "")
		// ) {
		//   setPostDescription(e.target.value);
		// } else {
		//   messageApi.open({
		//     type: "error",
		//     content: t("functions.wrongLayout"),
		//   });
		// }
	};

	const handleChangeSocialNetworks = (value) => {
		setSn((prevState) => {
			if (prevState.includes(value)) {
				return prevState.split(',').filter(item => item !== value).join(',')
			} else {
				if (prevState === '') {
					return value
				} else {
					return `${prevState},${value}`
				}
			}
		});
	};

	const handleChangeGeneratedLangs = (value) => {
		setSelectedContentLangs(value)
	}

	const onChangeFormatsCheckboxes = (e) => {
		e.stopPropagation();
		const { id } = e.target;

		if (selectedContentFormatId === id) {
			setSelectedContentFormatId(null);
		} else {
			setSelectedContentFormatId(id);
		}

	};

	useEffect(() => {
		fetchWrapper.get(`${baseUrl}/postformats`)
				.then((res) => {
					setContentFormats(res);
				});
	}, []);
	return (
			<>
				{contextHolder}
				{notificationContextHolder}
				<PageHeader title={t("posts.generatePost")} onBackClick={() => {
					navigate("/how-to-work");
				}} />
				<div ac className="generate-post">
					<div className="welcome-container" style={{ marginTop: "0" }}>
						<div className="main-content post-sn-container">
							<div className="flex gap-4 justify-center py-4 border-b border-solid border-b-[color:var(--Primary-200,#E9D7FE)]">
								<div
										style={{ opacity: sn.includes("Facebook") ? 1 : 0.4 }}
										onClick={() => handleChangeSocialNetworks("Facebook")}
										className="flex flex-col flex-1 cursor-pointer hidden"
								>
									<img
											loading="lazy"
											src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8432315b7e3752ab28ffd9571f771342eef6b676c1f20e4b8a5e6ee48c70349?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
											className="self-center w-6 aspect-square fill-indigo-800 "
									/>
									<div className="mt-1">Facebook</div>
								</div>
								<div
										style={{ opacity: sn.includes("LinkedIn") ? 1 : 0.4 }}
										onClick={() => handleChangeSocialNetworks("LinkedIn")}
										className="flex flex-col flex-1 cursor-pointer"
								>
									<img
											loading="lazy"
											src="https://cdn.builder.io/api/v1/image/assets/TEMP/d25408c5dff59b86cb5e75674b2a798a87cd455bde0adf0ceb8c9a670d1405e8?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
											className="self-center w-6 aspect-square"
									/>
									<div className="mt-1">LinkedIn</div>
								</div>

								<div
										style={{ opacity: sn.includes("Instagram") ? 1 : 0.4 }}
										onClick={() => handleChangeSocialNetworks("Instagram")}
										className="flex flex-col flex-1 cursor-pointer"
								>
									<img
											loading="lazy"
											src="https://cdn.builder.io/api/v1/image/assets/TEMP/497038a4df4615b7ef35db19c49364c0fdcd7e3749f9681fe807b55de2426e88?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
											className="self-center w-6 aspect-square"
									/>
									<div className="mt-1">Instagram</div>
								</div>
							</div>
						</div>
					</div>
					<form onSubmit={onSubmit} className="post-info-form" noValidate>
						<div className="survey-page">
							<label className="input-label text-center" htmlFor="description">
								{t("posts.postDescription")}
							</label>
							<div className="mic-icon" onClick={toggleMic}>
								<img src={isMicActive ? activeMic : inactiveMic} alt=""/>
							</div>
							{isMicActive && (
									<div className="text-center mt-4">{t("survey.recording")}</div>
							)}
							<div className="text-area-container">
              <textarea
									placeholder={t("posts.postDescription")}
									value={postDescription}
									onChange={(e) => onChangeTextarea(e)}
									name="description"
									id="description"
									maxLength={1000}
							/>
								{!!selectedContentFormatId && (
										<div className='text-center my-2'>
											<p className='font-semibold text-sm'>{t("home.formatPost")}</p>
											<p className='text-xs'>{contentFormats.find((item) => item.id === selectedContentFormatId).name[i18n.language]}</p>
										</div>
								)}
								{!!selectedContentLangs.length && (
										<div className='text-center my-2'>
											<p className='font-semibold text-sm'>{t("posts.selectedLanguages")}</p>
											<span className='text-xs'>
                      {selectedContentLangs?.toString()}
                    </span>
										</div>
								)}

							</div>

							<div className='hidden'>
								<label className="input-label" htmlFor="description">
									{t("posts.postDate")}
								</label>
								<input
										className="post-datepicker"
										type="datetime-local"
										id="date"
										name="date"
										value={date}
										onChange={(e) => setDate(e.target.value)}
										required
								/>
							</div>

							<button
									type="submit"
									className="post-button disabled:cursor-not-allowed disabled:opacity-75"
									disabled={postLoading || sn === '' || !postDescription}
							>
								{t("home.createPost")}
							</button>

							{!!contentFormats.length && (
									<>
										<button
												type="button"
												className="post-button mono mb-5"
												disabled={postLoading}
												onClick={() => setDrawerVisible(true)}
										>
											{t("home.formatPost")}
										</button>

										<Drawer
												title={t("posts.chooseFormatTitle")}
												placement={'bottom'}
												closable={false}
												onClose={() => setDrawerVisible(false)}
												open={drawerVisible}
												classNames={{
													mask: 'backdrop-blur-sm',
													content: 'h-full',
													wrapper: 'h-full'
												}}
												extra={
													<Space>
														<Button type="primary" onClick={() => setDrawerVisible(false)}>
															{t("posts.acceptFormat")}
														</Button>
													</Space>
												}
										>
											<div className='mb-4'>
												<h5 className='text-md font-semibold mb-1'>
													<Popover
															overlayStyle={{maxWidth: 500}}
															placement='bottomLeft'
															content={() => (
																	<pre style={{fontSize: '12px'}}
																			 className='text-[10px] block text-wrap text-pretty max-w-full font-inherit text-inherit'>
                                  {t("posts.multiLanguagePostsGenerateDescription")}
                                </pre>
															)}
															title={t("posts.multiLanguagePostsGenerateTitle")}
													>
														<QuestionCircleOutlined
																className='bg-primary text-white p-1 rounded-full text-md cursor-help transition-opacity opacity-50 hover:opacity-100'/>
													</Popover>
													<span className='ml-2'>
                          {t("posts.multiLanguagePostsGenerateTitle")}:
                        </span>
												</h5>
												<Select
														size='large'
														mode="multiple"
														style={{
															width: '100%'
														}}
														showSearch={false}
														placeholder={t("posts.multiLanguagePostsGeneratePlaceholder")}
														onChange={handleChangeGeneratedLangs}
														tokenSeparators={[',']}
														options={[{value: 'Русский', label: 'Русский'}, {
															value: 'Қазақша',
															label: 'Қазақша'
														}, {value: 'English', label: 'English'}]}
												/>
												{selectedContentLangs.toString().includes('Қазақша') && (
														<p className='ml-2 mt-1 text-red-600 opacity-85'>
															{t('posts.kazGenerateWarningMessage')}
														</p>
												)}
											</div>

											<h5 className='text-md font-semibold'>
												{t("posts.postLengthTitle")}:
											</h5>
											<div className='flex items-center gap-4 mb-5'>
												<Slider
														min={10}
														max={400}
														step={10}
														value={postLength}
														onChange={(value) => setPostLength(value>400 ? 400 : +value)}
														className='w-full'
												/>
												<InputNumber
														min={10}
														max={400}
														step={10}
														value={postLength}
														onChange={(value) => setPostLength(value>400 ? 400 : +value)}
												/>
											</div>

											<h5 className='text-md font-semibold'>
												{t("posts.postEmojisTitle")}:
											</h5>
											<div className='flex items-center gap-4 mb-5'>
												<Slider
														min={0}
														max={3}
														step={1}
														value={postEmojis}
														onChange={(value) => setPostEmojis(value>3 ? 3 : +value)}
														className='w-full'
												/>
												<InputNumber
														min={0}
														max={3}
														step={1}
														value={postEmojis}
														onChange={(value) => setPostEmojis(value>3 ? 3 : +value)}
												/>
											</div>

											<h5 className='text-md font-semibold'>
												{t("posts.formats")}:
											</h5>
											<Collapse accordion defaultActiveKey={['1']}>
												{contentFormats?.map((item) => (
														<CollapsePanel extra={
															<Checkbox
																	id={item.id}
																	value={item.name[i18n.language]}
																	onClick={e => e.stopPropagation()}
																	checked={selectedContentFormatId === item.id}
																	onChange={onChangeFormatsCheckboxes}
															/>
														} header={item.name[i18n.language]} key={item.id}>
															{Object?.keys(item?.structure).map((subItem, index) => (
																	<div key={index}>
																		<b>+ {subItem}:</b>{' '}
																		{item.structure[subItem][i18n.language]}
																	</div>
															))}
															<p className='border-l-4 border-violet-700 rounded-r-lg bg-gray-100 p-4 mt-2.5'>
																<b>{t("posts.formatExampleText")}:</b> {item.example[i18n.language]}
															</p>
														</CollapsePanel>
												))}
											</Collapse>
										</Drawer>
									</>
							)}
						</div>
					</form>
				</div>
			</>
	);
};

export default HTWSPostCreate;