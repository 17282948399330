import React, { useState } from "react";
import googleIcon from "./icons/google-icon.svg"; // Replace with path to your Google icon
import appleIcon from "./icons/apple-icon.svg"; // Replace with path to your Apple icon
import GoogleLogin from "react-google-login";
import axios from "axios";
import facebookIcon from "./icons/facebook-icon.svg"; // Replace with path to your Google icon
import xIcon from "./icons/linkedin-icon.svg"; // Replace with path to your Apple icon
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../store";
import { useTranslation, Trans } from "react-i18next";
import Public_Offer_IdeaSocial_ru from "../files/Public_Offer_IdeaSocial_ru.pdf";
import Public_Offer_IdeaSocial_en from "../files/Public_Offer_IdeaSocial_en.pdf";
import payment_ru from "../files/payment_ru.pdf";
import payment_en from "../files/payment_en.pdf";
import privacy_ru from "../files/Privacy_Policy_IdeaSocial_ru.pdf";
import privacy_en from "../files/Privacy_Policy_IdeaSocial_en.pdf";
import visaMaster from "../files/visa-master.png";
import { LanguageHeader } from "../components/LanguageHeader";
import { getProvidersUrls } from "../utils/auth";
import { PROVIDER } from "../constants/auth";

const SignupPage = () => {
  const { t, i18n } = useTranslation();
  // State for form inputs
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [lang, setLang] = useState(localStorage.getItem('ln') || i18n.language || "en");
  const dispatch = useDispatch();

  const terms = {
    ru: Public_Offer_IdeaSocial_ru,
    en: Public_Offer_IdeaSocial_en,
  };

  const payment = {
    ru: payment_ru,
    en: payment_en,
  };

  const privacy = {
    ru: privacy_ru,
    en: privacy_en,
  };

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      authActions.register({ username: email, password, name: fullName, lang })
    );
    // Implement your sign-up logic here
  };

  const onSuccess = async (res) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login/google`,
        {
          token: res?.tokenId,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const onSocialLogin = (key) => {
    window.location = getProvidersUrls()[key];
  };

  return (
    <>
      <div className='absolute w-full max-w-[375px] px-2 left-1/2 -translate-x-1/2 flex justify-end z-10'>
        <LanguageHeader/>
      </div>
      <div className="signup-wrapper">
        <header className="app-header"></header>
        <main className="signup-container">
          <h1>{t("signin.welcomeBack")}</h1>
          <p className="intro-text">{t("signin.introText")}</p>
          <form className="signup-form" onSubmit={handleSubmit}>
            <label className="input-label" htmlFor="fullName">
              {t("signup.fullName")}
            </label>
            <input
                required
              type="text"
              name="fullName"
              placeholder="John Doe"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />

            <label className="input-label" htmlFor="email">
              {t("signin.emailLabel")}
            </label>
            <input
                required
              type="email"
              name="email"
              placeholder="example@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <label className="input-label" htmlFor="password">
              {t("signin.passwordLabel")}
            </label>
            <input
                required
              type="password"
              name="password"
              placeholder={t("signin.inputPassword")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className="password-requirements">
              {t("signup.passwordRequirements")}
            </div>
            <div className="privacy-checkbox-container">
              <input type="checkbox" id="privacy" required />
              <label htmlFor="privacy">
                  <Trans
                      i18nKey="signin.privacy"
                      components={{
                        offerLink: (
                            <Link
                                className="privacy-link"
                                to={`/document/terms`}
                                // to={terms[i18n.language]}
                            ></Link>
                        ),
                        privacyLink: (
                            <Link
                                className="privacy-link"
                                to={`/document/privacy`}
                                // to={privacy[i18n.language]}
                            ></Link>
                        ),
                      }}
                  ></Trans>
              </label>
            </div>
            <button type="submit" className="continue-button">
              {t("signin.continue")}
            </button>
          </form>
          <div className="signin-link">
            {t("signup.haveAccount")}{" "}
            <Link to="/sign-in">
              <span className="link">{t("signin.signIn")}</span>
            </Link>
          </div>
          {/*<div className="divider">*/}
          {/*  <span className="divider-line"></span>*/}
          {/*  <span className="divider-text">{t("signin.or")}</span>*/}
          {/*  <span className="divider-line"></span>*/}
          {/*</div>*/}
          <div className="social-login-buttons">
            {/* <GoogleLogin
      clientId={`364042631502-vln87v20384vhut1pdm0glb063g1vb00.apps.googleusercontent.com`}
      onSuccess={onSuccess}
    /> */}
            {/*<button*/}
            {/*  className="social-button google"*/}
            {/*  onClick={() => onSocialLogin(PROVIDER.GOOGLE)}*/}
            {/*>*/}
            {/*  <span className="icon">*/}
            {/*    <img src={googleIcon} alt="" />*/}
            {/*  </span>*/}
            {/*  {t("signup.signUpGoogle")}*/}
            {/*</button>*/}
            {/* <button className="social-button apple">
      <span className="icon"><img src={appleIcon} alt="" /></span>{t("signup.signUpApple")}
    </button> */}
            {/* <button className="social-button facebook">
      <span className="icon"><img src={facebookIcon} alt="" /></span>{t("signup.signUpFacebook")}
    </button> */}

            {/*<button*/}
            {/*  className="social-button x"*/}
            {/*  onClick={() => onSocialLogin(PROVIDER.LINKEDIN)}*/}
            {/*>*/}
            {/*  <span className="icon">*/}
            {/*    <img width={30} src={xIcon} alt="" />*/}
            {/*  </span>*/}
            {/*  {t("signup.signUpLinkedIn")}*/}
            {/*</button>*/}
          </div>

          <div className="divider">
            <span className="divider-line"></span>
          </div>
          <div
            className="main-content"
            style={{
              marginTop: "40px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            <p style={{ whiteSpace: "pre-line" }}>{t("signin.contacts")}</p>
          </div>
        </main>
      </div>
    </>
  );
};

export default SignupPage;
