import PageHeader from "../../components/Header/PageHeader";
import React, {useLayoutEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SupportForm from "../../components/Forms/SupportForm";
import {message} from "antd";
import {clearAndRefreshCache} from "../../helpers/clear-and-refresh-cache";


const Support = () => {
	const navigate = useNavigate();
	const {t} = useTranslation();

	function clearAndRefreshCache() {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.ready.then(registration => {
				registration.active.postMessage({ type: 'CLEAR_CACHE' });
				sessionStorage.setItem('cacheHasRefreshed', new Date().toISOString());
				window.location.reload();
			}).catch((error) => {
				message.error(`${t("functions.error:")} ${error.message ? error.message : error}`);
			});
		}
	}

	useLayoutEffect(() => {
		const differenceTime = Number(((new Date() - new Date(sessionStorage.getItem('cacheHasRefreshed'))) / 1000 / 60).toFixed(1));
		if (differenceTime < 0.5) {
			message.success(t("support.clearCacheSuccess"));
			// sessionStorage.removeItem('cacheHasRefreshed');
		}
	}, []);

	return (
			<div>
				<PageHeader
						title={t("support.header")}
						onBackClick={() => {
							navigate("/home");
						}}
				/>
				<div className='survey-page pb-5'>
				<SupportForm/>
				</div>
				<div className='text-sm text-center mx-auto max-w-lg mt-5 pt-2.5 flex flex-col justify-center items-center border-t'>
					<p>
						{t("support.clearCacheDescription")}
					</p>
					<button className='logout-button max-w-max max-w-fit' onClick={clearAndRefreshCache}>
						{t("support.clearCacheBtnTitle")}
					</button>
				</div>
			</div>
	);
};

export default Support;