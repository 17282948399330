import React, {useState} from 'react';
import {Button, Drawer, message, Space, Tabs} from "antd";
import {useTranslation} from "react-i18next";


const OrganizationHelpers = ({organization}) => {
	const {t} = useTranslation();
	const [drawerVisible, setDrawerVisible] = useState(false);

	const tabsItems = [
		{
			label: t("organization.contacts"),
			key: 1,
			children: <div className='flex flex-col gap-1 justify-start'>
				<div
						className='relative ml-auto max-w-max max-w-fit py-2 px-5 flex justify-end -mb-4 z-10 opacity-50 hover:opacity-100'>
					<button className='border rounded-xl py-1 px-2 text-xs hover:bg-purple-400 hover:text-white'
									onClick={() => {
										navigator.clipboard.writeText(
`${t("organization.name")}: ${organization?.name}
${t("organization.establishmentYear")}: ${organization?.establishmentYear}
${t("organization.email")}: ${organization?.email}
${t("organization.phone")}: ${organization?.phones.toString()}
${t("organization.website")}: ${organization?.website}
${t("organization.address")}: ${organization?.primaryAddress}`
										)
										message.success(t("functions.copied"))
									}
									}
					>
						📋 {t("functions.copy")}
					</button>
				</div>

				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.name")}:
					</p>
					<p>
						{organization?.name}
					</p>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.establishmentYear")}:
					</p>
					<p>
						{organization?.establishmentYear}
					</p>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.email")}:
					</p>
					<a target='_blank' href={`mailto:${organization?.email}`}>
						{organization?.email}
					</a>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.phone")}:
					</p>
					{organization?.phones?.map((phone) => (
						<a key={phone} target='_blank' href={`tel:${phone}`}>
							{phone}
						</a>
					))}
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.website")}:
					</p>
					<a target='_blank' href={`${organization?.website}`}>
						{organization?.website.replace('https://', '').replace('http://', '')}
					</a>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.address")}:
					</p>
					<address>
						{organization?.primaryAddress}
					</address>
				</div>
			</div>,
		},
		{
			label: t("organization.credentials"),
			key: 2,
			children: <div className='flex flex-col gap-1 justify-start'>
				<div
						className='relative ml-auto max-w-max max-w-fit py-2 px-5 flex justify-end -mb-4 z-10 opacity-50 hover:opacity-100'>
					<button className='border rounded-xl py-1 px-2 text-xs hover:bg-purple-400 hover:text-white'
									onClick={() => {
										navigator.clipboard.writeText(
`${t("organization.BANK")}: ${organization?.BANK}
${t("organization.BIC")}: ${organization?.BIC}
${t("organization.BIN")}: ${organization?.BIN}
${t("organization.IIK")}: ${organization?.IIK}
${t("organization.KBE")}: ${organization?.KBE}
${t("organization.generalDirector")}: ${organization?.generalDirector}
${t("organization.address")}: ${organization?.primaryAddress}`
										)
										message.success(t("functions.copied"))
									}
					}
					>
						📋 {t("functions.copy")}
					</button>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.BANK")}:
					</p>
					<p>
						{organization?.BANK}
					</p>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.BIC")}:
					</p>
					<p>
						{organization?.BIC}
					</p>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.BIN")}:
					</p>
					<p>
						{organization?.BIN}
					</p>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.IIK")}:
					</p>
					<p>
						{organization?.IIK}
					</p>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.KBE")}:
					</p>
					<p>
						{organization?.KBE}
					</p>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.generalDirector")}:
					</p>
					<p>
						{organization?.generalDirector}
					</p>
				</div>
				<div className='flex items-center gap-1'>
					<p className='font-semibold'>
						{t("organization.address")}:
					</p>
					<address>
						{organization?.primaryAddress}
					</address>
				</div>
			</div>,
		},
	];

	return (
			<div className='mt-2'>
				<button
						type="button"
						className="w-full text-sm text-center p-2 py-2.5 border border-transparent rounded-xl text-gray-500 hover:text-gray-600 hover:border-gray-600 font-semibold"
						onClick={() => setDrawerVisible(true)}
				>
					{t('organization.aboutOrganization')}
				</button>
				<Drawer
						title={t('organization.aboutOrganization')}
						placement={'bottom'}
						closable={false}
						onClose={() => setDrawerVisible(false)}
						open={drawerVisible}
						classNames={{
							mask: 'backdrop-blur-sm',
							content: 'h-full max-w-[648px] mx-auto overflow-hidden rounded-t-2xl',
							wrapper: 'h-full max-w-[648px] mx-auto overflow-hidden rounded-t-2xl'
						}}
						styles={{
							wrapper: {maxWidth: '648px', margin: '0 auto', borderRadius: '2rem 2rem 0 0', maxHeight: '500px'},
						}}
						extra={
							<Space>
								<Button type="default" onClick={() => setDrawerVisible(false)}>
									{t("profile.close")}
								</Button>
							</Space>
						}
				>
					<div>
						<Tabs
								type="card"
								items={tabsItems}
						/>
					</div>
				</Drawer>
			</div>
	);
};

export default OrganizationHelpers;