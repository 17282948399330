import React, { useState, useEffect } from "react";
import "../Survey/SurveyPage.css";
import activeMic from "../icons/mic-on.svg";
import copyIcon from "../icons/copy-icon.svg";
import arrowDownIcon from "../icons/arrow-down.svg";
import arrowUpIcon from "../icons/arrow-up.svg";
import inactiveMic from "../icons/mic-off.svg";
import QuestionCarousel from "../../components/Carousel/QuestionCarousel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { contentPlanActions, postActions, questionsActions } from "../../store";
import {Button, Checkbox, Collapse, Drawer, InputNumber, message, Popover, Select, Slider, Space} from "antd";
import PageHeader from "../../components/Header/PageHeader";
import { useTranslation } from "react-i18next";
import AudioRecorder from "audio-recorder-polyfill";
import i18n from "i18next";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import {fetchWrapper} from "../../helpers";
import {QuestionCircleOutlined} from "@ant-design/icons";


const baseUrl = `${process.env.REACT_APP_API_URL}`;
export const CreatePost = ({ openNotification }) => {
  const memoizedPostFormats = JSON.parse(localStorage.getItem('postFormats')) || undefined;
  window.MediaRecorder = AudioRecorder;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contentPlan = useSelector((obj) => obj.contentPlan.contentPlan);
  const leadingQuestionsLoading = useSelector(
    (obj) => obj.contentPlan.leadingQuestionsLoading
  );
  const postLoading = useSelector((obj) => obj.post.loading);
  const [isMicActive, setIsMicActive] = useState(false);
  const [openedLeading, setOpenedLeading] = useState(true);
  const [answer, setAnswer] = useState("");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [postModel, setPostModel] = useState(memoizedPostFormats?.postModel || 'advance');
  const [postLength, setPostLength] = useState(memoizedPostFormats?.postLength || 250);
  const [postEmojis, setPostEmojis] = useState(memoizedPostFormats?.postEmojis || 1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [contentFormats, setContentFormats] = useState([]);
  const [selectedContentFormatId, setSelectedContentFormatId] = useState(null);
  const [selectedContentLangs, setSelectedContentLangs] = useState(memoizedPostFormats?.contentLangs || []);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setIsMicActive(true);
        const recorder = new MediaRecorder(stream);
        recorder.start();
        setMediaRecorder(recorder);
        recorder.addEventListener("dataavailable", (e) => {
          message.loading(t("functions.loading"));
          const audioBlob = new Blob([e.data], { type: "audio/wav" });
          const audioFile = new File([audioBlob], "recording.wav", {
            type: "audio/wav",
          });
          const formData = new FormData();
          formData.append("audio", audioFile);

          axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/upload-audio`,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          })
            .then(
              (data) =>
                data.data &&
                setAnswer((answer) => `${answer || ""}${data.data}`)
            )
            .catch((error) => console.error(error));
        });
      })
      .catch((error) => {
        message.error(t("functions.microphone"));
      });
  };

  const stopRecording = () => {
    mediaRecorder?.stop();
    // You might want to do something with the audio chunks here, like creating a blob and saving or playing it
    const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
    // Resetting audio chunks after recording is stopped
    setAudioChunks([]);
    setIsMicActive(false);
    // Here, you could save the blob or do something else with it
  };

  const handleChangeGeneratedLangs = (value) => {
    setSelectedContentLangs(value)
  }

  useEffect(() => {
    return () => {
      mediaRecorder?.state === "recording" && mediaRecorder.stop();
    };
  }, [mediaRecorder]);

  const toggleMic = () => {
    if (isMicActive) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const submitAnswer = async () => {
    message.loading(t("functions.generatingPost"));
    const requestBody = {
      id: contentPlan.id,
      details: answer,
      formatId: selectedContentFormatId,
      selectedContentLangs: selectedContentLangs,
      postLength: postLength,
      postEmojis: postEmojis,
      postModel: postModel,
    };
    // dispatch()
    if (answer) {
      await dispatch(
        contentPlanActions.createContentPlanDetails(requestBody)
      );
    }

    await dispatch(postActions.generatePost({contentPlanId:contentPlan.id, reqBody: requestBody})).then((res) => {
      if (res.error) {
        openNotification();
      }
    });

    // try {
    //   await dispatch(postActions.generatePost(contentPlan.id));
    // } catch {
    //   // openNotification();
    //   console.log('aaaa');
    // }
  };

  const generateLeadingQuestions = () => {
    message.loading(t("functions.loadingLeading"));
    dispatch(contentPlanActions.generateLeadingQuestions(contentPlan.id));
  };

  const clipboardCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success(t("functions.copied"));
  };

  const handleRememberOptionsPublicationFormats = () => {
    const memoValues = {
      postModel: postModel,
      postLength: postLength,
      postEmojis: postEmojis,
      contentLangs: selectedContentLangs,
    };
    localStorage.setItem('postFormats', JSON.stringify(memoValues));
    message.success(t("functions.successfullySaved"));
  };

  const onChangeTextarea = (e) => {
    setAnswer(e.target.value);
    // if (
    //   i18n.language === "en" &&
    //   (new RegExp(/^[a-zA-Z0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
    //     e.target.value
    //   ) ||
    //     e.target.value == "")
    // ) {
    //   setAnswer(e.target.value);
    // } else if (
    //   i18n.language === "ru" &&
    //   (new RegExp(/^[а-яА-Я0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
    //     e.target.value
    //   ) ||
    //     e.target.value == "")
    // ) {
    //   setAnswer(e.target.value);
    // } else {
    //   messageApi.open({
    //     type: "error",
    //     content: t("functions.wrongLayout"),
    //   });
    // }
  };

  const onChangeFormatsCheckboxes = (e) => {
    e.stopPropagation();
    const { id } = e.target;

    if (selectedContentFormatId === id) {
      setSelectedContentFormatId(null);
    } else {
      setSelectedContentFormatId(id);
    }

  }

  useEffect(() => {
    fetchWrapper.get(`${baseUrl}/postformats`)
        .then((res) => {
          setContentFormats(res);
        });
  }, []);

  return (
    <>
      {contextHolder}
      <div className="survey-page">
        {/* <PageHeader title={"Create Post"} />
      <div>
        <p className="self-assessment-title" style={{ fontSize: 13 }}>{contentPlan.description}</p>
      </div> */}
        <div
          className={`text-justify flex ${
            contentPlan?.leadingQuestions ? "justify-between" : "justify-center"
          } px-6`}
          style={{ textAlign: "center" }}
        >
          {contentPlan?.leadingQuestions ? (
            <img
              className="cursor-pointer"
              onClick={() => setOpenedLeading(!openedLeading)}
              src={openedLeading ? arrowUpIcon : arrowDownIcon}
            />
          ) : null}
          <div>{t("post.leadingQuestions")}</div>
          {contentPlan?.leadingQuestions ? (
            <img
              className="cursor-pointer"
              onClick={() => {
                clipboardCopy(contentPlan?.leadingQuestions);
              }}
              src={copyIcon}
            />
          ) : null}
        </div>
        <div className="justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl border border-black border-solid bg-purple-200 bg-opacity-50 px-4">
          {contentPlan?.leadingQuestions ? (
            <div className="whitespace-pre-line">
              {openedLeading
                ? contentPlan?.leadingQuestions
                : `${contentPlan?.leadingQuestions.substring(0, 190)}...`}
            </div>
          ) : (
            <div className="text-center">
              <small className='hidden'>{t("post.noLeading1")}</small>
              {/*<br />*/}
              {t("post.noLeading2")}
            </div>
          )}
        </div>
        {contentPlan?.leadingQuestions ? null : (
          <button
            disabled={leadingQuestionsLoading}
            onClick={generateLeadingQuestions}
            className="submit-button p-2 m-auto"
            style={{
              fontSize: 15,
              padding: 5,
              borderRadius: 20,
              marginBottom: 5,
            }}
          >
            {leadingQuestionsLoading
              ? t("functions.loading")
              : t("functions.generate")}
          </button>
        )}
        <div className="mic-icon" onClick={toggleMic}>
          <img src={isMicActive ? activeMic : inactiveMic} alt="" />
        </div>
        {isMicActive && (
          <div className="text-center mt-4">{t("survey.recording")}</div>
        )}
        <div className="text-area-container">
          <textarea
            placeholder={t("survey.type")}
            value={answer}
            onChange={(e) => onChangeTextarea(e)}
          />
          {!!selectedContentFormatId && (
              <div className='text-center my-2'>
                <p className='font-semibold text-sm'>{t("home.formatPost")}</p>
                <p className='text-xs'>{contentFormats.find((item) => item.id === selectedContentFormatId).name[i18n.language]}</p>
              </div>
          )}
          {!!selectedContentLangs.length && (
              <div className='text-center my-2'>
                <p className='font-semibold text-sm'>{t("posts.selectedLanguages")}</p>
                <span className='text-xs'>
                  {selectedContentLangs?.toString()}
                </span>
              </div>
          )}
        </div>
        <div className="button-container">
          <button
            className="clear-button"
            onClick={() => {
              setSelectedContentFormatId(null)
              setAnswer('')
            }}
            disabled={postLoading}
          >
            {t("survey.clear")}
          </button>
          <button
            className="submit-button disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={postLoading}
            onClick={submitAnswer}
          >
            {postLoading ? t("functions.leadingQuestions") : t("survey.submit")}
          </button>
        </div>
        {!!contentFormats.length && (
            <>
              <button
                  type="button"
                  className="post-button mono mb-5"
                  disabled={postLoading}
                  onClick={() => setDrawerVisible(true)}
              >
                {t("home.formatPost")}
              </button>

              <Drawer
                  title={t("posts.chooseFormatTitle")}
                  placement={'bottom'}
                  closable={false}
                  onClose={() => setDrawerVisible(false)}
                  open={drawerVisible}
                  classNames={{
                    mask: 'backdrop-blur-sm',
                    content: 'h-full',
                    wrapper: 'h-full'
                  }}
                  extra={
                    <Space>
                      <Button type="primary" onClick={() => setDrawerVisible(false)}>
                        {t("posts.acceptFormat")}
                      </Button>
                    </Space>
                  }
              >
                <div className='w-full mb-4 flex flex-col lg:flex-row items-center lg:items-start gap-4'>
                  <div className='w-full'>
                    <h5 className='text-md font-semibold mb-1'>
                      <Popover
                          overlayStyle={{maxWidth: 500}}
                          placement='bottomLeft'
                          content={() => (
                              <pre style={{fontSize: '12px'}}
                                   className='text-[10px] block text-wrap text-pretty max-w-full font-inherit text-inherit'>
                                    {t("posts.multiLanguagePostsGenerateDescription")}
                                  </pre>
                          )}
                          title={t("posts.multiLanguagePostsGenerateTitle")}
                      >
                        <QuestionCircleOutlined
                            className='bg-primary text-white p-1 rounded-full text-md cursor-help transition-opacity opacity-50 hover:opacity-100'/>
                      </Popover>
                      <span className='ml-2'>
                            {t("posts.multiLanguagePostsGenerateTitle")}:
                          </span>
                    </h5>
                    <Select
                        size='large'
                        mode="multiple"
                        style={{
                          width: '100%'
                        }}
                        showSearch={false}
                        placeholder={t("posts.multiLanguagePostsGeneratePlaceholder")}
                        onChange={handleChangeGeneratedLangs}
                        tokenSeparators={[',']}
                        value={selectedContentLangs}
                        options={[{value: 'Русский', label: 'Русский'}, {
                          value: 'Қазақша',
                          label: 'Қазақша'
                        }, {value: 'English', label: 'English'}]}
                    />
                    {selectedContentLangs.toString().includes('Қазақша') && (
                        <p className='ml-2 mt-1 text-red-600 opacity-85'>
                          {t('posts.kazGenerateWarningMessage')}
                        </p>
                    )}
                  </div>

                  <div className='w-full'>
                    <h5 className='text-md font-semibold mb-1'>
                      {t("posts.postLanguageModelTitle")}:
                    </h5>
                    <Select
                        onChange={(value)=>{
                          localStorage.setItem('ai-model', value);
                          setPostModel(value)
                        }}
                        value={postModel}
                        className="w-full"
                        size='large'
                    >
                      <Select.Option value="default">{t("posts.postModels.default")}</Select.Option>
                      <Select.Option value="advance">{t("posts.postModels.advance")}</Select.Option>
                    </Select>
                  </div>
                </div>

                <div className='w-full mb-4 flex flex-col lg:flex-row items-center gap-4'>
                  <div className='w-full'>
                    <h5 className='text-md font-semibold'>
                      {t("posts.postLengthTitle")}:
                    </h5>
                    <div className='flex items-center gap-4'>
                      <Slider
                          min={10}
                          max={400}
                          step={10}
                          value={postLength}
                          onChange={(value) => setPostLength(value>400 ? 400 : +value)}
                          className='w-full'
                      />
                      <InputNumber
                          min={10}
                          max={400}
                          step={10}
                          value={postLength}
                          onChange={(value) => setPostLength(value>400 ? 400 : +value)}
                      />
                    </div>
                  </div>

                  <div className='w-full'>
                    <h5 className='text-md font-semibold'>
                      {t("posts.postEmojisTitle")}:
                    </h5>
                    <div className='flex items-center gap-4'>
                      <Slider
                          min={0}
                          max={3}
                          step={1}
                          value={postEmojis}
                          onChange={(value) => setPostEmojis(value>3 ? 3 : +value)}
                          className='w-full'
                      />
                      <InputNumber
                          min={0}
                          max={3}
                          step={1}
                          value={postEmojis}
                          onChange={(value) => setPostEmojis(value>3 ? 3 : +value)}
                      />
                    </div>
                  </div>
                </div>

                <div className='pb-7 border-b mb-2'>
                  <button className='logout-button' onClick={handleRememberOptionsPublicationFormats}>
                    {t("posts.memoizeChoice")}
                  </button>
                </div>

                <h5 className='text-md font-semibold'>
                  {t("posts.formats")}:
                </h5>
                <Collapse accordion defaultActiveKey={['1']}>
                  {contentFormats?.map((item) => (
                      <CollapsePanel extra={
                        <Checkbox
                            id={item.id}
                            value={item.name[i18n.language]}
                            onClick={e => e.stopPropagation()}
                            checked={selectedContentFormatId === item.id}
                            onChange={onChangeFormatsCheckboxes}
                        />
                      } header={item.name[i18n.language]} key={item.id}>
                        {Object?.keys(item?.structure).map((subItem, index) => (
                            <div key={index}>
                              <b>+ {subItem}:</b>{' '}
                              {item.structure[subItem][i18n.language]}
                            </div>
                        ))}
                        <p className='border-l-4 border-violet-700 rounded-r-lg bg-gray-100 p-4 mt-2.5'>
                          <b>{t("posts.formatExampleText")}:</b> {item.example[i18n.language]}
                        </p>
                      </CollapsePanel>
                  ))}
                </Collapse>
              </Drawer>
            </>
        )}
      </div>
    </>
  );
};
