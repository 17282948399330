import React, {createContext, useContext, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {jwtDecode} from "jwt-decode";
import {mergeStateObject} from "../utils/merger";
import {fetchWrapper} from "../helpers";
import {message} from "antd";
import {useTranslation} from "react-i18next";

export const OrganizationContextProvider = createContext(null);

export const useOrganization = () => useContext(OrganizationContextProvider);

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const OrganizationContext = ({children}) => {
	const {t} = useTranslation();
	const user = useSelector((obj) => obj.auth.user);
	const [organization, setOrganization] = useState(null);

	const handleSetOrganization = (organization) => {
		mergeStateObject(setOrganization, organization);
	};

	const getOrganizationDetails = async (id) => {
		if (!id) return {};
		const response = await fetchWrapper.get(`${baseUrl}/organizations/${id}`);
		return response;
	}

	const setOrganizationFile = async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		const response = await fetchWrapper.post(`${baseUrl}/organizations/${organization?.id}/files`, formData);
		if (response.fileUrl){
			handleSetOrganization({fileUrl: response.fileUrl});
			message.success(t("functions.successfullyUploaded"));
		} else {
			message.error(t("functions.wrong"));
		}
	}

	const setOrganizationLogo = async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		const response = await fetchWrapper.post(`${baseUrl}/organizations/${organization?.id}/logo`, formData);
		if (response?.logoUrl){
			handleSetOrganization({logoUrl: response.logoUrl});
			message.success(t("functions.successfullyUploaded"));
		} else {
			message.error(t("functions.wrong"));
		}
	}

	useEffect(() => {
		let orgFromToken = null;
		(async () => {
			if (user){
				if (jwtDecode(user?.accessToken)?.organizationId){
					orgFromToken = {
						id: jwtDecode(user?.accessToken)?.organizationId,
						curator: jwtDecode(user?.accessToken)?.curator
					}
				} else {
					orgFromToken = {
						id: user?.organizationId,
						curator: user?.curator
					}
				}
				const orgDetails = await getOrganizationDetails(orgFromToken?.id);
				handleSetOrganization({
					...orgFromToken,
					...orgDetails
				});
			}
		})();
	}, [user]);
	return (
			<OrganizationContextProvider.Provider value={{organization, setOrganizationFile, setOrganizationLogo}}>
				{children}
			</OrganizationContextProvider.Provider>
	);
};

export default OrganizationContext;