import React, {useState, useEffect, useRef, Fragment} from "react";
import "../Survey/SurveyPage.css";
import activeMic from "../icons/mic-on.svg";
import facebookIcon from "../icons/facebook-content.svg";
import instagramIcon from "../icons/instagram-content.svg";
import linkedInIcon from "../icons/linkedin-content.svg";
import copyIcon from "../icons/copy-icon.svg";
import penIcon from "../icons/pen-icon.svg";
import checkIcon from "../icons/check-icon-black.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { contentPlanActions, postActions, questionsActions } from "../../store";
import {Button, DatePicker, message, notification, Popover, Space} from "antd";
import PageHeader from "../../components/Header/PageHeader";
import moment from "moment";
import { fetchWrapper } from "../../helpers";
import { useTranslation } from "react-i18next";
import {QuestionCircleOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const icons = {
  Facebook: facebookIcon,
  Instagram: instagramIcon,
  LinkedIn: linkedInIcon,
};

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const PostPage = () => {
  const [notificationApi, notificationContextHolder] =
      notification.useNotification();
  const videoPlayerRef = useRef(null);
  const post = useSelector((obj) => obj.post.post);
  const { t } = useTranslation();
  const params = useParams();
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [generating, setGenerating] = useState(null);
  const dispatch = useDispatch();
  const [dragActive, setDragActive] = useState(false);
  const hiddenVideoInput = useRef(null);
  const hiddenFileInput = useRef(null);
  const [editMainText, setEditMainText] = useState(false);
  const [editHashtag, setEditHashtag] = useState(false);
  const [editOffer, setEditOffer] = useState(false);
  const [mainText, setMainText] = useState(post.post);
  const [hashtags, setHashtags] = useState(post.hashtags);
  const [offer, setOffer] = useState(post.media);
  const contentPlan = useSelector((obj) => obj.contentPlan.contentPlan);
  // const [publishTime, setPublishTime] = useState(moment(
  //     contentPlan.date
  // ).format("HH:mm"));
  const [publishTime, setPublishTime] = useState(contentPlan.date);
  const navigate = useNavigate();

  const changeMainText = () => {
    if (editMainText) {
      fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
        post: mainText,
        hashtags,
        media: offer,
      });
    }
    setEditMainText(!editMainText);
  };

  const changePublishTime = (value) => {
    setPublishTime(value)

    // const newDate = new Date(contentPlan.date).setHours(e.target.value.split(':')[0], e.target.value.split(':')[1], 0);
    // setPublishTime(moment(
    //     new Date(newDate)
    // ).format("HH:mm"));

      fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
        date: value,
      });

  };

  const changeHashtags = () => {
    if (editHashtag) {
      fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
        post: mainText,
        hashtags,
        media: offer,
      });
    }
    setEditHashtag(!editHashtag);
  };

  const changeOffer = () => {
    if (editOffer) {
      fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
        post: mainText,
        hashtags,
        media: offer,
      });
    }
    setEditOffer(!editOffer);
  };

  const publishNow = () => {
    const d = new Date();
    fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
      postInfo: {
        ...post,
        date: new Date(d.getTime() - d.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, -5),
      },
    });
  };

  const clipboardCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success(t("functions.copied"));
  };

  const handleClick = (event) => {
    if (images.length > 5) return message.error(t("functions.maxImages"));
    // if (post?.images?.length || images.length) return;

    // if (images.length) {
    //   setImages([]);
    // } else {
      hiddenFileInput.current.click();
    // }
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImages((prevImages) => {
        const newImages = Array.from(event.target.files);
        const totalImages = [...prevImages, ...newImages];
        return totalImages.slice(0, 6);
      });
    }
  };

  const handleDeleteImage = (indexToDelete) => {
    setImages((prevImages) => prevImages.filter((_, index) => index !== indexToDelete));
  };

  const handleResetImages = () => {
    setImages([]);
  }

  const handleRemoveGeneratedImage = async (index = null) => {
    if (index === null) {

      await dispatch(
          postActions.removeImage({
            removeIndex: 0,
            postId: post.id,
            contentPlanId: contentPlan.id,
          })
      );
    } else {

      fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
        images: null,
        hashtags,
        media: offer,
      });
      await dispatch(
          postActions.removeImage({
            removeIndex: 0,
            postId: post.id,
            contentPlanId: contentPlan.id,
          })
      );
    }
  }

  const handleAddVideo = (e) => {
    return message.info(t('functions.error'));
    if ((e.target.files[0].size / 1000000).toFixed() > 200) return message.error(t("post.maxVideoSize"));
    if (e.target.files && e.target.files[0]) {
      setVideo(e.target.files[0]);
    }
  }

  const handleManageMetaData = () => {
    if (videoPlayerRef.current){
      if (videoPlayerRef?.current?.duration > 595) {
        message.error(t("post.maxVideoDuration"));
        setVideo(null)
      }
    }
  }

  const handleRemoveVideo = async () => {
    setVideo(null);
    if (post.video){
      try {
        await dispatch(
            postActions.removeVideo({
              postId: post.id,
              contentPlanId: contentPlan.id,
              video: video,
            })
        );
            window.location.reload();
      } catch (err) {
        message.error(t("functions.wrong"));
      }
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };
  console.log(images)
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    console.log(e.dataTransfer.files)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let newFiles = Array.from(e.dataTransfer.files);
      let newImages = [];
      newFiles.forEach((file) => {
        if (file.type.includes('image')){
          newImages.push(file);
        }
        if (file.type.includes('video')) {
          message.info(t('functions.error'));
          if ((file.size / 1000000).toFixed() < 200){
            // setVideo(file);
          }
        }
      });

      setImages((prevImages) => {
        const totalFiles = [...prevImages, ...newImages];
        return totalFiles.slice(0, 6);
      });
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImages(e.target.files[0]);
    }
  };

  const onSaveFile = async () => {
    if (images) {
      try {
        await dispatch(
          postActions.uploadImage({
            postId: post.id,
            contentPlanId: contentPlan.id,
            image: images,
          })
        );
        message.success(t("functions.successfullyUploaded"));
      } catch (err) {
        message.error(t("functions.wrong"));
      }
    } else {
      message.error(t("functions.noImage"));
    }
  };

  const onOkayButton = async () => {
    if (video && !post?.images?.length) {
      try {
      await dispatch(
          postActions.uploadVideo({
            postId: post.id,
            contentPlanId: contentPlan.id,
            video: video,
          })
      );
      } catch (err) {
        message.error(t("functions.wrong"));
      }
    }
    if (images.length) {
      try {
        await dispatch(
          postActions.uploadImage({
            postId: post.id,
            contentPlanId: contentPlan.id,
            images: images,
          })
        );

        if (contentPlan.forIndependentPosts) {
          navigate("/posts");
          window.location.reload();
        } else {
          navigate("/content-plan");
          window.location.reload();
        }
      } catch (err) {
        message.error(t("functions.wrong"));
      }
    } else {
      if (contentPlan.forIndependentPosts) {
        navigate("/posts");
        window.location.reload();
      } else {
        navigate("/content-plan");
        window.location.reload();
      }
    }
  };

  const onGenerateImage = () => {
    if (post.countGenerateImage >=3) return message.error('Превышен лимит генераций изображений на пост 3 попытки.');
    if (window.confirm(`
${t('post.postGenerateImageLimit')}
${t('post.postGenerateImageLeft')} ${3 - +post.countGenerateImage}
${t('functions.continue')}?
    `)){

    setGenerating(true);
    message.loading(t("post.generatingImage"));
    dispatch(
      postActions.generateImage({
        postId: post.id,
        contentPlanId: contentPlan.id,
      })
    )
      .then(() => {
        message.success(t("functions.successfullyGenerated"));
      })
      .catch((err) => {
        message.error(t("functions.wrong"));
      })
      .finally(() => {
        setGenerating(false);
        setImages([]);
      });
    }
  };

  const handleSeePublishedPost = (url)=>{
    window.open(url, '_blank').focus();
  };

  const handleGenerateAnotherPost = () => {
    const newPostDescription = `${t("post.textToGenerateAnotherPost")}: ${post.post}`;
    const date = new Date();
    fetchWrapper
        .post(`${baseUrl}/post/save`, {
          description: newPostDescription,
          socialNetwork: "LinkedIn",
          date: date.toISOString(),
          formatId: null
        })
        .then((res) => {
          if (res){
            navigate(`/create-post/${res.contentPlanId}`);
            window?.location?.reload();
          }
        })
        .catch((err) => {
          console.error(err);
          message.error(t("functions.wrong"));
        });
  }

  const openNotificationFbPages = () => {
    const key = `open${Date.now()}`;
    const btn = (
        <Space>
          <Button
              type="primary"
              size="small"
              onClick={() => navigate("/profile")}
          >
            {t("posts.goToProfile")}
          </Button>
        </Space>
    );
    notificationApi.open({
      message: t("functions.warning"),
      description: t("posts.notSelectedFacebookPage"),
      btn,
      key,
    });
  };

  useEffect(() => {
    if (contentPlan.socialNetwork.toLowerCase().includes("facebook")){
      fetchWrapper.get(`${baseUrl}/profile/`).then((res) => {
        if (!res.facebookPageId){
          openNotificationFbPages()
        }
      });
    }
    if (contentPlan.socialNetwork.toLowerCase().includes("instagram") && (!post?.images?.length && !post?.video)) {
      message.info(t('functions.minMediaForInstagramPostMessage'));
      window.scrollTo({
        top: 9999,
        behavior: "smooth",
      })
    }

  }, []);

  useEffect(() => {
    if ([...images, video]?.reduce((size, media) => size + (media?.size ? +media?.size : 0), 0) > 10000000){
      message.error(t("post.bigMediaListSize", {size: 10}));
    }
  }, [images, video]);

  return (
      <div
          style={{display: "flex", flexDirection: "column", alignItems: "center"}}
      >
        {notificationContextHolder}
        <div
            className="flex gap-4 justify-center py-4 border-b border-solid border-b-[color:var(--Primary-200,#E9D7FE)]">
          {contentPlan.socialNetwork.includes("LinkedIn") && (
              <div className="flex flex-col flex-1">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d25408c5dff59b86cb5e75674b2a798a87cd455bde0adf0ceb8c9a670d1405e8?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                    className="self-center w-6 aspect-square"
                />
                <div className="mt-1">LinkedIn</div>
              </div>
          )}
          {contentPlan.socialNetwork.includes("Facebook") && (
              <div className="flex flex-col flex-1 font-medium">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8432315b7e3752ab28ffd9571f771342eef6b676c1f20e4b8a5e6ee48c70349?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                    className="self-center w-6 aspect-square fill-indigo-800"
                />
                <div className="mt-1">Facebook</div>
              </div>
          )}
          {contentPlan.socialNetwork.includes("Instagram") && (
              <div
                  className="flex flex-col flex-1"
              >
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/497038a4df4615b7ef35db19c49364c0fdcd7e3749f9681fe807b55de2426e88?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                    className="self-center w-6 aspect-square"
                />
                <div className="mt-1">Instagram</div>
              </div>
          )}
        </div>
        {/* <PageHeader title={"Post"} />
            <div>
                <p className="self-assessment-title" style={{ fontSize: '1rem' }}>{contentPlan.description}</p>
            </div> */}
        <div className="max-w-lg m-auto">
          <div
              className="text-justify justify-center"
              style={{textAlign: "center", fontSize: 20}}
          >
            {t("post.suggestion")}
          </div>
          <div className="flex justify-between px-8 mt-8 items-center">
            <img src={icons[contentPlan.social_network]} width={40}/>
            <div className='flex items-center gap-1' style={{color: "#667085"}}>
              {post.published
                  ? t("post.published")
                  // : `${t("post.planned")} ${!!contentPlan.weekDay ? t(`weekDays.${contentPlan.weekDay}`) : ''} ${t("post.at")}`}
                  : `${t("post.planned")} ${!!contentPlan.weekDay ? t(`weekDays.${contentPlan.weekDay}`) : ''} `}

                {!post.published && (
                  <div>
                    {contentPlan.weekDay ? (
                        <input readOnly={post.published}
                               className='ml-1 cursor-pointer border-b-2 border-violet-700 rounded-md'
                               type='time' value={moment(publishTime).format("HH:mm")}
                               onChange={(e) => {
                                 const value = e.target.value;
                                 if (value) {
                                   const newDate = new Date(publishTime).setHours(value.split(':')[0], value.split(':')[1], 0);
                                   changePublishTime(new Date(newDate).toISOString())
                                 }
                               }}
                        />
                    ) : (
                        <DatePicker
                            locale={{
                              dateTimeFormat: localStorage.getItem('ln') || 'en',
                            }}
                            disabledDate={(current) => {
                              return current && current < moment().startOf('day');
                            }}
                            defaultValue={dayjs(new Date(publishTime))}
                            format="DD.MM.YYYY HH:mm"
                            showTime
                            onChange={(value) => {
                              if (value) {
                                changePublishTime(value.toISOString());
                              }
                            }}
                        />
                    )}
                  </div>
                )}

            </div>

            <div style={{display: "flex"}}>
              <img
                  className="cursor-pointer"
                  onClick={() => {
                    clipboardCopy(post.post);
                  }}
                  src={copyIcon}
              />
              {!post.published && (
                  <img
                      className="cursor-pointer"
                      onClick={() => changeMainText()}
                      src={editMainText ? checkIcon : penIcon}
                  />
              )}
            </div>
          </div>
          {editMainText ? (
              <div style={{display: "flex", justifyContent: "center"}}>
            <textarea
                style={{boxShadow: "0px 0px 0px 9999px rgba(0, 0, 0, 0.3)"}}
                value={mainText}
                onChange={(e) => setMainText(e.target.value)}
                className=" h-80 resize-none justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4"
            ></textarea>
              </div>
          ) : (
              <div
                  className="justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4">
                {mainText}
              </div>
          )}

          <div className="flex justify-between px-8 mt-8 items-center">
            <img width={40}/>
            <div>{t("post.hashtagSuggestion")}</div>
            <div style={{display: "flex"}}>
              <img
                  className="cursor-pointer"
                  onClick={() => {
                    clipboardCopy(post.hashtags);
                  }}
                  src={copyIcon}
              />
              {!post.published && (
                  <img
                      className="cursor-pointer"
                      onClick={() => changeHashtags()}
                      src={editHashtag ? checkIcon : penIcon}
                  />
              )}
            </div>
          </div>
          {editHashtag ? (
              <div style={{display: "flex", justifyContent: "center"}}>
            <textarea
                style={{boxShadow: "0px 0px 0px 9999px rgba(0, 0, 0, 0.3)"}}
                value={hashtags}
                onChange={(e) => setHashtags(e.target.value)}
                className=" h-24 resize-none justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4"
            ></textarea>
              </div>
          ) : (
              <div
                  className="justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4">
                {hashtags}
              </div>
          )}

          <div className="flex justify-between px-8 mt-8 items-center">
            <img width={40}/>
            <div>{t("post.mediaSuggestion")}</div>
            <div style={{display: "flex"}}>
              <img
                  className="cursor-pointer"
                  onClick={() => {
                    clipboardCopy(post.media);
                  }}
                  src={copyIcon}
              />
              {!post.published && (
                  <img
                      className="cursor-pointer"
                      onClick={() => changeOffer()}
                      src={editOffer ? checkIcon : penIcon}
                  />
              )}
            </div>
          </div>
          {editOffer ? (
              <div style={{display: "flex", justifyContent: "center"}}>
            <textarea
                style={{boxShadow: "0px 0px 0px 9999px rgba(0, 0, 0, 0.3)"}}
                value={offer}
                onChange={(e) => setOffer(e.target.value)}
                className=" h-36 resize-none justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4"
            ></textarea>
              </div>
          ) : (
              <div
                  className="justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4">
                {offer}
              </div>
          )}

          {!post.published && (

              <Popover
                  overlayStyle={{maxWidth: 500}}
                  placement='bottom'
                  content={() => (
                      <pre style={{fontSize: '12px'}}
                           className='text-[10px] block text-wrap text-pretty max-w-full font-inherit text-inherit'>
                                  {t("post.notificationFilesForPlatformsDescription")}
                                </pre>
                  )}
                  title={t("post.notificationFilesForPlatformsTitle")}
              >
                <div className="flex justify-center gap-1 mt-8 items-center">
                  <QuestionCircleOutlined
                      className='bg-primary text-white p-1 rounded-full text-md cursor-help transition-opacity opacity-50 hover:opacity-100'
                  />
                  <p className='cursor-help'>{t("post.image")}</p>
                </div>
              </Popover>


            )}

          {!post.published && (
              <div
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onClick={post?.images?.length || images?.length ? null : handleClick}
                  className={`${(!!post?.images?.length || !!images.length) ? 'cursor-pointer' : 'cursor-pointer border-2 border-black/50 border-dashed'} ${dragActive ? 'border-violet-800 bg-violet-400' : ''} justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4 min-h-24`}>
                {images?.length ? (
                    images.map((image, index) => (
                        <Fragment key={image.name}>
                          <img
                              className="m-auto rounded-2xl"
                              alt="preview image"
                              src={URL.createObjectURL(image)}
                          />
                          <div className='mt-1 mb-4 flex items-center justify-evenly gap-2.5'>
                            <p className={`text-center text-sm text-gray-500 ${(image.size / 1024).toFixed() > 10000 ? 'text-red-500' : ''}`}>
                              {(image.size / 1024).toFixed(2)} KB
                              {(image.size / 1024).toFixed() > 10000 ?
                                  <span className='block text-xs'>{t("post.bigFileSize")}</span> : ''}
                            </p>
                            <button
                                onClick={() => handleDeleteImage(index)}
                                className="p-0 bg-red-500 text-xs text-white rounded-lg px-2 py-0.5 hover:bg-red-600"
                            >
                              {t("post.remove")}
                            </button>
                          </div>
                        </Fragment>
                    ))
                ) : (post?.images?.length) ? (
                    post.images.map((image, index) => (
                        <Fragment key={index}>
                          <img className="m-auto rounded-2xl" alt="preview image" src={image}/>
                          <div className='mt-1 mb-4 flex items-center justify-evenly gap-2.5'>
                            {/*<button*/}
                            {/*    onClick={() => handleRemoveGeneratedImage(index)}*/}
                            {/*    className="p-0 bg-red-500 text-xs text-white rounded-lg px-2 py-0.5 hover:bg-red-600"*/}
                            {/*>*/}
                            {/*  {t("post.remove")}*/}
                            {/*</button>*/}
                          </div>
                        </Fragment>
                    ))
                ) : (
                    <div className={`text-center mt-4 pointer-events-none`}>
                      {(!video && !post.video) && (
                          <p className='text-lg'>
                            {t("post.noImage")}
                          </p>
                      )}
                      {dragActive ? t('post.onDrag') : t('post.dragActive')}
                      <br/>
                      <small className='text-xs opacity-50 leading-none'>
                        {t("post.dragInfo")}
                      </small>
                    </div>
                )}
                {(video || post.video) &&
                    <>
                      <video className='mx-auto rounded-xl' onLoadedMetadata={handleManageMetaData} ref={videoPlayerRef}
                             width="320" height="240" controls>
                        <source onLoadedMetadata={handleManageMetaData}
                                src={post.video ? post.video : URL.createObjectURL(video)} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                      {video?.size && (
                          <div>
                            <p className={`text-center text-sm text-gray-500 ${(video?.size / 1000000)?.toFixed() > 200 ? 'text-red-500' : ''}`}>
                              {(video?.size / 1000000)?.toFixed(2)} MB
                              {(video?.size / 1000000)?.toFixed() > 200 ?
                                  <span className='block text-xs'>{t("post.bigFileSize")}</span> : ''}
                            </p>
                          </div>
                      )}
                    </>
                }
                {[...images, video]?.reduce((size, media) => size + (media?.size ? +media?.size : 0), 0) > 10000000 && (
                    <p className={`text-center text-sm text-red-500`}>
                      <span className='block text-xs'>{t("post.bigMediaListSize", {size: 10})}</span>
                    </p>
                )}

              </div>
          )}

          {!post.published && (
              <div className="flex justify-center w-full h-full align-center mt-6">
              {generating ? (
                    <div>{t("post.processingImage")}</div>
                ) : (
                    <>
                      <div className='px-2 grid grid-cols-2 items-center justify-evenly gap-1.5'>

                        {(!!post?.images?.length) ?
                            <div className='flex w-full justify-center'>
                              <button
                                  onClick={() => handleRemoveGeneratedImage()}
                                  className="post-content-button danger h-full"
                              >
                                {t("post.removeImages")}
                              </button>
                            </div> : !!images.length &&
                            <button
                                onClick={handleResetImages}
                                className="post-content-button danger h-full"
                            >
                              {t("post.removeImages")}
                            </button>
                        }


                        {(!post?.images?.length) && (
                            <button
                                onClick={handleClick}
                                className="post-content-button h-full"
                            >
                              {t("post.uploadImage")}
                            </button>
                        )}


                        {
                          (video || post?.video) ? (
                              <button
                                  className='post-content-button h-full danger'
                                  onClick={handleRemoveVideo}
                              >
                                {t("post.removeVideo")}
                              </button>
                          ) : !post?.images?.length && (
                              <button
                                  disabled
                                  className='post-content-button h-full'
                                  onClick={() => hiddenVideoInput.current.click()}
                                  // style={{display: "none"}}
                              >
                                {t("post.addVideo")}
                              </button>
                          )
                        }

                        {(!post?.images?.length && !images?.length && !video && !post?.video) &&
                            <button
                                onClick={onGenerateImage}
                                className="post-content-button mono h-full"
                            >
                              {t("post.generateImage")}
                            </button>
                        }

                        {/*<button*/}
                        {/*    onClick={() => dispatch(postActions.generatePost({*/}
                        {/*      contentPlanId: contentPlan.id,*/}
                        {/*      reqBody: {*/}
                        {/*        description: "Напиши пост про динозавров которые очень любили пить воду и кушать траву",*/}
                        {/*        socialNetwork: "LinkedIn, Facebook",*/}
                        {/*        date: new Date().toISOString(),*/}
                        {/*        formatId: null,*/}
                        {/*        selectedContentLangs: ["English", "Русский"],*/}
                        {/*        postLength: 150,*/}
                        {/*        postEmojis: 3*/}
                        {/*      }*/}
                        {/*    }))}*/}
                        {/*    className="post-content-button mono h-full"*/}
                        {/*>*/}
                        {/*  Сгенерировать заново*/}
                        {/*</button>*/}

                        <button
                            onClick={() => dispatch(postActions.generatePost({
                              contentPlanId: contentPlan.id,
                              reqBody: null
                            }))}
                            className="post-content-button mono h-full"
                        >
                          {t("posts.regenerate")}
                        </button>

                      </div>

                    </>
              )}
              </div>
          )}
        </div>

        {(post.published && post?.images?.length) && (
            <div className='my-5'>
              <p className=' text-center my-2'>{t("post.image")}</p>
              <div className='max-w-lg flex flex-wrap justify-center'>
                {post?.images?.map((img) => (
                    <img key={img} className='block w-1/2 rounded-xl p-1 object-contain' src={img}/>
                ))}
              </div>
            </div>
        )}


        <div className='mt-7'>
        {/* <button
          onClick={publishNow}
          className="submit-button p-0"
          style={{ fontSize: 13, padding: 10, marginTop: 20, marginBottom: 20 }}
        >
          {t("posts.publish")}
        </button> */}

          {post.published ? (
              <div className={`flex flex-wrap gap-2 max-w-xs`}>
                {post?.published_statuses?.map((network, index) => (
                    !!network.url && (
                        <button
                            key={index}
                            type='button'
                            onClick={() => {
                              handleSeePublishedPost(network.url)
                            }}
                            className="post-content-button mono h-full"
                        >
                          <span className='mr-1.5'>
                            {network?.name?.toLowerCase() === 'linkedin' && (
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d25408c5dff59b86cb5e75674b2a798a87cd455bde0adf0ceb8c9a670d1405e8?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                                    className="self-center w-6 aspect-square"
                                />
                            )}
                            {network?.name?.toLowerCase() === 'facebook' && (
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8432315b7e3752ab28ffd9571f771342eef6b676c1f20e4b8a5e6ee48c70349?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                                    className="self-center w-6 aspect-square fill-indigo-800"
                                />
                            )}
                            {network?.name?.toLowerCase() === 'instagram' && (
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/497038a4df4615b7ef35db19c49364c0fdcd7e3749f9681fe807b55de2426e88?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                                    className="self-center w-6 aspect-square"
                                />
                            )}
                          </span>
                          {t("posts.seePost")}
                        </button>
                    )
                ))}
                <button
                    type='button'
                    onClick={handleGenerateAnotherPost}
                    className="post-content-button h-full"
                >
                  {t("posts.createAnotherPost")}
                </button>
              </div>

          ) : (
              <button
                  type='button'
                  onClick={onOkayButton}
                  className="post-content-button"
              >
              {t("post.save")}
              </button>
          )}

        </div>

        <div className='mb-10 flex flex-col justify-center'>
          <input
              type="file"
              accept="image/*"
              onChange={handleChange}
              ref={hiddenFileInput}
              multiple
              style={{display: "none"}}
          />
          <input onChange={handleAddVideo} ref={hiddenVideoInput} type="file"
                 accept='video/mp4, video/mov, video/wmv, video/avi, video/flv, video/mpeg, video/mpg, video/quicktime, video/3gpp, video/webm'
                 className='hidden'/>
        </div>
      </div>
  );
};
