import {Button, Modal, notification, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {fetchWrapper} from "../../helpers";


const baseUrl = `${process.env.REACT_APP_API_URL}`;

const CreatePostFromMessageModalWithButton = ({createAction, snAction, socialNetworks}) => {
	const [fbPageId, setFbPageId] = useState('');
	const [tokens, setTokens] = useState({
		instagram: false,
		facebook: false,
		linkedin: false
	});
	const navigate = useNavigate();
	const [notificationApi, notificationContextHolder] =
			notification.useNotification();
	const {t} = useTranslation();
	const [isSNModalOpen, setIsSNModalOpen] = useState(false);

	const openNotificationSocialNetwork = ({message, description}) => {
		const key = `open${Date.now()}`;
		const btn = (
				<Space>
					<Button
							type="primary"
							size="small"
							onClick={() => navigate("/profile")}
					>
						{t("posts.goToProfile")}
					</Button>
				</Space>
		);
		notificationApi.open({
			message: message ||t("functions.error"),
			description: description || t("posts.unknownError"),
			btn,
			key,
		});
	};
	const handleChangeSocialNetworks = (value) => {
		if (!tokens[value.toLowerCase()]){
			return openNotificationSocialNetwork({
				message: t("functions.error"),
				description: t(`posts.notLinked-${value.toLowerCase()}`),
			});
		}
		snAction((prevState) => {
			if (prevState.includes(value)) {
				return prevState.split(',').filter(item => item !== value).join(',')
			} else {
				if (prevState === '') {
					return value
				} else {
					return `${prevState},${value}`
				}
			}
		});
	}

	useEffect(() => {
		fetchWrapper
				.get(`${baseUrl}/auth/getSocialMediaTokens/`)
				.then((res) => {
					let filteredSn = [];
					const filteredToken = {
						facebook: false,
						instagram: false,
						linkedin: false,
					};
					res.forEach((token) => {
						if (token.socialMediaType === "FACEBOOK") {
							filteredToken.facebook = true;
								filteredSn.push('Facebook')
						}
						if (token.socialMediaType === "INSTAGRAM") {
							filteredToken.instagram = true;
								filteredSn.push('Instagram')
						}
						if (token.socialMediaType === "LINKEDIN") {
							filteredToken.linkedin = true;
								filteredSn.push('LinkedIn')
						}
					});
					setTokens(prevState => filteredToken);
					snAction(filteredSn.join(','));
				})
				.catch(() => {});
	}, []);

	useEffect(() => {
		fetchWrapper.get(`${baseUrl}/profile/`).then((res) => {
			setFbPageId(res.facebookPageId);
		});
	}, []);
	return (
			<>
				{notificationContextHolder}
				<button
						className='w-full sm:w-auto text-xs hover:bg-purple-500 hover:text-white py-1 px-1.5 rounded-xl text-center'
						onClick={()=>setIsSNModalOpen(true)}
						// onClick={() => createPostFromMessage(message?.content)}
				>
					&#10004; {t("assistant.createPostFromThisMessageBtn")}
				</button>
				<Modal
						title={t("assistant.createPostFromThisMessageBtn")}
						open={isSNModalOpen}
						onOk={createAction}
						onCancel={()=>setIsSNModalOpen(false)}
						okText={t('posts.create')}
						cancelText={t('posts.cancel')}
				>
					<div
							className="text-center flex gap-4 justify-center py-4 border-b border-solid border-b-[color:var(--Primary-200,#E9D7FE)]"
					>
							<div style={{opacity: socialNetworks?.includes("LinkedIn") ? 1 : 0.4}}
									 onClick={() => handleChangeSocialNetworks("LinkedIn")}
									 className="flex flex-col flex-1 cursor-pointer">
								<img
										loading="lazy"
										src="https://cdn.builder.io/api/v1/image/assets/TEMP/d25408c5dff59b86cb5e75674b2a798a87cd455bde0adf0ceb8c9a670d1405e8?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
										className="self-center w-6 aspect-square"
								/>
								<div className="mt-1">LinkedIn</div>
							</div>

							{process.env.REACT_APP_IS_HIDE_FACEBOOK !== 'true' &&
									<div style={{opacity: socialNetworks?.includes("Facebook") ? 1 : 0.4}}
											 onClick={() => handleChangeSocialNetworks("Facebook")}
											 className={`flex flex-col flex-1 cursor-pointer ${process.env.REACT_APP_IS_HIDE_FACEBOOK === 'true' && '!hidden'}`}>
										<img
												loading="lazy"
												src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8432315b7e3752ab28ffd9571f771342eef6b676c1f20e4b8a5e6ee48c70349?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
												className="self-center w-6 aspect-square"
										/>
										<div className="mt-1">Facebook</div>
									</div>
							}
							<div style={{opacity: socialNetworks?.includes("Instagram") ? 1 : 0.4}}
									 onClick={() => handleChangeSocialNetworks("Instagram")}
									 className="flex flex-col flex-1 cursor-pointer">
								<img
										loading="lazy"
										src="https://cdn.builder.io/api/v1/image/assets/TEMP/497038a4df4615b7ef35db19c49364c0fdcd7e3749f9681fe807b55de2426e88?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
										className="self-center w-6 aspect-square"
								/>
								<div className="mt-1">Instagram</div>
							</div>

					</div>
				</Modal>
			</>
	);
};

export default CreatePostFromMessageModalWithButton;