import checkIcon from "../../icons/check-icon.svg"; // Replace with path to your Google icon
import uncheckIcon from "../../icons/uncheck-icon.svg"; // Replace with path to your Google icon
import questionIcon from "../../icons/question-icon.svg"; // Replace with path to your Google icon
import arrowRightIcon from "../../icons/arrow-right-content.svg"; // Replace with path to your Google icon
import refreshIcon from "../../icons/refresh.svg"; // Replace with path to your Google icon
import moment from "moment";
import {Link, useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { fetchWrapper } from "../../../helpers";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import penIcon from "../../icons/pen-icon.svg";
import {Button, message, notification, Popover, Space} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const PostCard = ({ post }) => {
	const [notificationApi, notificationContextHolder] =
			notification.useNotification();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [isMinified, setIsMinified] = useState(true);
	const [postThemeEditText, setPostThemeEditText] = useState(false);

	const changePostThemeText = () => {
		onlyAfterAuth();
	};

	const approve = () => {
		onlyAfterAuth();
	};

	const setPostTitleText = (e)=>{
		onlyAfterAuth();
	};

	const onlyAfterAuth = () => {
		message.info(t("functions.accessAfterAuth"));
		const key = `open${Date.now()}`;
		const btn = (
				<Space>
					<Button
							type="primary"
							size="small"
							onClick={() => navigate("/sign-in")}
					>
						{t("signin.signIn")}
					</Button>
				</Space>
		);
		notificationApi.open({
			message: t("functions.warning"),
			description: t("functions.accessAfterAuth"),
			btn,
			key,
		});
	};

	return (
			<div className="schedule-item">
				{notificationContextHolder}
				<div className="schedule-details">

					<span className="schedule-time font-bold flex items-center gap-2">
						{post?.socialNetwork?.split(',')?.map((network, index) => (
								<span key={index}>{network}</span>
						))}
					</span>

					<span className="schedule-time">
          	{moment(post.date).format("DD/MM/YYYY")}
        	</span>

					<span className="schedule-time">
						{t("plancard.generated")}
        	</span>

					<span className={`schedule-type --schedule-red-badge`}>
              {t("post.notpublished")}
            </span>

				</div>


				<div className="schedule-info">
					<div className="schedule-description flex items-center">
						<div style={{maxWidth: "3rem"}}>
							<img
									style={{width: "25px", height: "25px", maxWidth: "unset" }}
									className="cursor-pointer"
									onClick={() => post?.published ? null : changePostThemeText()}
									src={postThemeEditText || post?.published ? checkIcon : penIcon}
							/>
						</div>

						{postThemeEditText ? (
								<div className="pl-3 w-full">
              <textarea
									value={post.postTitle}
									onChange={(e) => setPostTitleText(e.target.value)}
									className="resize-none w-full h-32 sm:h-full justify-start"
							/>
								</div>
						) : (
								<div className="pl-3 text-ellipsis overflow-hidden h-10 line-clamp-2">
									{post.postTitle}
								</div>
						)}
					</div>

					<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
					>
						<div className="refresh-icon-placeholder">
							<p
									onClick={() => approve()}
									className="plan-card-button --schedule-green-badge"
							>
								{t("plancard.publish")}
							</p>
						</div>

						<Link to={`/how-to-work-post/${post.id}`}>
							<div className="arrow-icon-placeholder">
								<img src={arrowRightIcon}/>
							</div>
						</Link>

					</div>
				</div>

				{post && (
						<Link to={`/how-to-work-post/${post.id}`}>
							<div
									className={"justify-center px-3 py-2 mt-2.5 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50" +
											` ${isMinified? 'text-ellipsis text-nowrap overflow-hidden' : ''}`}
							>
								{post.post}
							</div>
						</Link>
				)}
				{post && (
						<div
								className="cursor-pointer max-w-max max-w-fit mx-auto justify-center py-1 mt-1 mb-2 m-auto text-xs text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4"
								onClick={()=> setIsMinified(ps => !ps)}
						>
							{isMinified ? t("functions.expand") : t("functions.collapse")}
						</div>
				)}
			</div>
	);
};
