import PageHeader from "../../components/Header/PageHeader";
import {Link, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import "./style.css";
import React, { useEffect, useState } from "react";
import { fetchWrapper } from "../../helpers";
import {message, Modal, Select} from "antd";
import { useDebounce } from "use-debounce";
import i18n from "i18next";
import { getProvidersUrls } from "../../utils/auth";
import { PROVIDER } from "../../constants/auth";
import settingsIcon from "../icons/settings-icon.svg";
import xIcon from "../icons/linkedin-icon.svg";
import fbIcon from "../icons/facebook-icon.svg";
import instIcon from "../icons/instagram-logo.png";
import { authActions } from "../../store";
import { useDispatch } from "react-redux";
import moment from "moment";
import crownIcon from "../icons/crown.png";
import InviteFriend from "../../components/Forms/InviteFriend";
import ReferralsModal from "../../components/modals/Referrals-modal";
import axios from "axios";
import {getUserTimeInfo} from "../../helpers/get-user-time-zone";
import TimeZoneModal from "../../components/modals/TimeZone-modal";
import RemoveAccountModal from "../../components/modals/Remove-account-modal";
import {useOrganization} from "../../context/organizationContext";
import OrganizationSettings from "../../components/blocks/OrganizationSettings";
import OrganizationLogo from "../../components/blocks/OrganizationLogo";
import {getUserCoordinates} from "../../utils/getUserCoordinates";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const ProfilePage = () => {
  const { t } = useTranslation();
  const [handleUpdateProfile, sethandleUpdateProfile] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((obj) => obj.auth.user);
  const org = useOrganization();
  const [profile, setProfile] = useState(null);
  const [currentTimeZone, setCurrentTimeZone] = useState(getUserTimeInfo());
  const [linkedInModalVisible, setLinkedInModalVisible] = useState(false);
  const [fbModalVisible, setFBModalVisible] = useState(false);
  const [fbModalInfoVisible, setFBModalInfoVisible] = useState(false);
  const [instModalInfoVisible, setInstModalInfoVisible] = useState(false);
  const [timeZones, setTimeZones] = useState([]);
  const [language, setLanguage] = useState();
  const [gender, setGender] = useState();
  const [name, setName] = useState(user.name);
  const [subDateEnd, setSubDateEnd] = useState();
  const [subLevel, setSubLevel] = useState();
  const [updateName] = useDebounce(name, 1000);
  const [handleUpdateReferrals, sethandleUpdateReferrals] = useState(false);
  const [fbPages, setFbPages] = useState([]);
  const [linkedInPages, setLinkedInPages] = useState([]);
  const [socialMediaUsers, setSocialMediaUsers] = useState({
    facebook: {},
    instagram: {},
    linkedIn: {},
  });
  const [tokensIds, setTokensIds] = useState({
    facebook: "",
    instagram: "",
    linkedIn: "",
  });
  const [tokens, setTokens] = useState({
    facebook: false,
    instagram: false,
    linkedIn: false,
  });
  const [sid, setSid] = useState(
      jwtDecode(JSON.parse(localStorage.getItem("user")).accessToken)
          .subscriptionLevel.id
  );
  const dispatch = useDispatch();
  const socialLogin = {
    linkedIn: () => {
      fetchWrapper.get(`${baseUrl}/auth/special-url?sn=linkedin`)
          .then((res) => {
            if (res?.redirect_url){
              window.location = res.redirect_url;
            } else {
              window.location = getProvidersUrls()[PROVIDER.LINKEDIN];
            }
          })
          .catch((err) => {
            console.error(err);
            window.location = getProvidersUrls()[PROVIDER.LINKEDIN];
          });
      //   console.log(getProvidersUrls()[PROVIDER.LINKEDIN]);
      // window.location = getProvidersUrls()[PROVIDER.LINKEDIN];
    },
    facebook: () => {
      fetchWrapper.get(`${baseUrl}/auth/special-url?sn=facebook`)
          .then((res) => {
            if (res?.redirect_url){
              window.location = res.redirect_url;
            } else {
              window.location = getProvidersUrls()[PROVIDER.FACEBOOK];
            }
          })
          .catch((err) => {
            console.error(err);
            window.location = getProvidersUrls()[PROVIDER.FACEBOOK];
          });
      //   console.log(getProvidersUrls()[PROVIDER.LINKEDIN]);
      // window.location = getProvidersUrls()[PROVIDER.FACEBOOK];
    },
    instagram: () => {
      fetchWrapper.get(`${baseUrl}/auth/special-url?sn=instagram`)
          .then((res) => {
            if (res?.redirect_url){
              window.location = res.redirect_url;
            } else {
              window.location = getProvidersUrls()[PROVIDER.INSTAGRAM];
            }
          })
          .catch((err) => {
            console.error(err);
            window.location = getProvidersUrls()[PROVIDER.INSTAGRAM];
          });
      //   console.log(getProvidersUrls()[PROVIDER.LINKEDIN]);
    },
  };

  const socialUnhook = {
    linkedIn: () => {
      if (window.confirm(t('profile.unlinkLinkedinConfrim'))) {
        fetchWrapper.get(`${baseUrl}/unhook/linkedin/${tokensIds.linkedIn}`).then((res) => {
          if (res) {
            setTokens(prevState => ({...prevState, linkedIn: false}));
            refreshToken();
          }
        });
      }
    },
    facebook: () => {
      if (window.confirm(t('profile.unlinkFacebookConfrim'))) {
        fetchWrapper.get(`${baseUrl}/unhook/facebook/${tokensIds.facebook}`).then((res) => {
          if (res) {
            setTokens(prevState => ({...prevState, facebook: false}));
            refreshToken();
          }
        });
      }
    },
    instagram: () => {
      if (window.confirm(t('profile.unlinkInstagramConfrim'))) {
        fetchWrapper.get(`${baseUrl}/unhook/instagram/${tokensIds.instagram}`).then((res) => {
          if (res) {
            setTokens(prevState => ({...prevState, instagram: false}));
            refreshToken();
          }
        });
      }
    },
  };

  useEffect(() => {
    fetchWrapper
      .get(`${baseUrl}/auth/getSocialMediaTokens/`)
      .then((res) => {
        const socialUsers = {
          facebook: null,
          instagram: null,
          linkedIn: null,
        };
        const newTokensId = {
          facebook: "",
          instagram: "",
          linkedIn: "",
        };
        const filteredToken = {
          facebook: false,
          instagram: false,
          linkedIn: false,
        };
        res.forEach((token) => {
          if (token.socialMediaType === "FACEBOOK") {
            filteredToken.facebook = true;
            newTokensId.facebook = token.id;
            socialUsers.facebook = {username: token?.username, email: token?.email};
          }
          if (token.socialMediaType === "INSTAGRAM") {
            filteredToken.instagram = true;
            newTokensId.instagram = token.id;
            socialUsers.instagram = {username: token?.username, email: token?.email};
          }
          if (token.socialMediaType === "LINKEDIN") {
            // console.log(token)
            filteredToken.linkedIn = true;
            newTokensId.linkedIn = token.id;
            socialUsers.linkedIn = {username: token?.username, email: token?.email};
          }
        });
        setSocialMediaUsers(socialUsers);
        setTokens(filteredToken);
        setTokensIds(newTokensId);
      })
      .catch(() => {});
  }, []);
  const refreshToken = () => {
    fetchWrapper.get(`${baseUrl}/auth/refresh-token`).then((res) => {
      const oldUser = JSON.parse(localStorage.getItem("user"));
      oldUser.languageGeneration = language;
      oldUser.name = name;
      oldUser.accessToken = res.accessToken;
      localStorage.setItem("user", JSON.stringify(oldUser));
      setSubDateEnd(jwtDecode(res?.accessToken)?.userSubscription?.endDate);
      setSubLevel(jwtDecode(res?.accessToken)?.subscriptionLevel);
      dispatch(authActions.updateUser());
    });
    localStorage.setItem('ln', language);
  };

  const onChangeLanguage = (value) => {
      setLanguage(value);
      localStorage.setItem('ln', value);
  };

  const handleChangeGender = (gender) => {
    fetchWrapper.post(`${baseUrl}/profile/update-info`,{
      gender: gender
    }).then((res) => {
      if (res){
        setGender(gender)
      }
    });
  };

  const onChangeTimeZone = (TZ) => {
    setCurrentTimeZone(TZ);
    fetchWrapper.post(`${baseUrl}/profile/update-info`,{
      timeZone: TZ
    });
  };

  const showLinkedInModal = () => {
    setLinkedInModalVisible(true);
  };

  const showFBModal = () => {
    setFBModalVisible(true);
  };

  const showFBModalInfo = () => {
    setFBModalInfoVisible(true);
  };

  const showInstModalInfo = () => {
    setInstModalInfoVisible(true);
  };

  const handleChangeFBPage = (page) => {
    setFBModalVisible(false)
    fetchWrapper.post(`${baseUrl}/profile/update-info`,{
      facebookPageId: page.id
    }).then((res) => {
      if (res){
        sethandleUpdateProfile(p => !p);
      }
    });
  }

  const handleChangeLinkedInPage = (page) => {
    setLinkedInModalVisible(false)
    fetchWrapper.post(`${baseUrl}/profile/update-info`,{
      linkedinPageId: page.page_id
    }).then((res) => {
      if (res){
        sethandleUpdateProfile(p => !p);
      }
    });
  }

  const shareGeoData = async () => {
    let coordinates = null;
    try {
        coordinates = await getUserCoordinates();
    } catch (e) {
      console.error(e)
      message.error(t("profile.sharedGeoDataError"));
    }
    fetchWrapper.post(`${baseUrl}/profile/update-info`, {
      coordinates
    }).then((res) => {
      if (res){
        message.success(t("profile.sharedGeoDataSuccess"));
      } else {
        message.error(t("profile.sharedGeoDataError"));
      }
    }).catch(()=>{
      message.error(t("profile.sharedGeoDataError"));
    });
  }

  useEffect(() => {
    refreshToken();

    axios.get('/data/utc-zones.json')
        .then((res)=>{
            setTimeZones(res.data)
        })
        .catch(err => console.log(err));


  }, []);

  useEffect(() => {
    fetchWrapper.get(`${baseUrl}/profile/`).then((res) => {
      setLanguage(res.languageGeneration);
      localStorage.setItem('ln', res.languageGeneration);
      setProfile(res);
      if (res.timeZone){
        setCurrentTimeZone(res.timeZone);
      }
      if (res.gender){
        setGender(res.gender || 'other');
      } else {
        setGender('other');
      }
    });

    try{
      fetchWrapper.get(`${baseUrl}/profile/facebook-pages`)
          .then((res)=>{
            if (res){
              setFbPages(res)
            }
          })
          .catch(err => console.log(err));
    } catch (err) {
      console.error('facebook pages error:', err)
    }

    try {
      fetchWrapper.get(`${baseUrl}/profile/linkedin-pages`)
          .then((res)=>{
            if (res){
              setLinkedInPages(res)
            }
          })
          .catch((err) => {
            console.error(err)
          });
    } catch(err) {
      console.error('linkedin pages error:', err)
    }

  }, [handleUpdateProfile]);

  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('ln', language);
    fetchWrapper.post(`${baseUrl}/profile/update-info`, {
      languageGeneration: language,
    });
    refreshToken();
  }, [language]);

  useEffect(() => {
    fetchWrapper
      .post(`${baseUrl}/profile/update-info`, { name: updateName })
      .then(() => {
        refreshToken();
      });
  }, [updateName]);

  useEffect(() => {
    if (!profile?.linkedinPageId && linkedInPages.length) {
      fetchWrapper.post(`${baseUrl}/profile/update-info`,{
        linkedinPageId: linkedInPages[0]?.page_id
      }).then((res) => {
        if (res){
          sethandleUpdateProfile(p => !p);
        }
      });
    }
  }, [profile, linkedInPages]);

  return (
    <>
      <PageHeader
        title={t("profile.header")}
        onBackClick={() => {
          navigate("/home");
        }}
      />
      <div className="profile">
        <div className="profile_info_container">
          <OrganizationLogo/>
          <div
              style={{display: "flex", alignItems: "center", columnGap: "5px"}}
          >
            <p className="profile_info__text">
              <span className="profile_info_title">{t("profile.name")}:</span>
            </p>
            <input
                type="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            {!!sid ? <img className='premium-icon' src={`${crownIcon}`} alt="premium"/> : null}
          </div>
          <p className="profile_info__text text-nowrap overflow-hidden text-ellipsis">
            <span className="profile_info_title">{t("profile.login")}:</span>{" "}
            <span>
              {user.email}
            </span>
          </p>
          {subDateEnd && (
              <p className="profile_info__text">
              <span className="profile_info_title">
                {t("profile.subscription")}:
              </span>{" "}
                {moment(subDateEnd).format("DD/MM/YYYY")}
              </p>
          )}
          <div
              style={{display: "flex", alignItems: "center", columnGap: "5px"}}
          >
            <p className="profile_info__text">
              <span className="profile_info_title">{t("profile.gender")}:</span>
            </p>
            <Select
                onChange={handleChangeGender}
                value={gender}
                className="w-26 mr-2"
            >
              <Select.Option value="male">{t("profile.male")}</Select.Option>
              <Select.Option value="female">{t("profile.female")}</Select.Option>
              <Select.Option value="other">{t("profile.other")}</Select.Option>
            </Select>
          </div>
          <div
              style={{display: "flex", alignItems: "center", columnGap: "5px"}}
          >
            <p className="profile_info__text">
              <span className="profile_info_title">{t("profile.lang")}:</span>
            </p>
            <Select
                onChange={onChangeLanguage}
                value={language}
                className="w-26 mr-2"
            >
              <Select.Option value="kk">Қазақша</Select.Option>
              <Select.Option value="ru">Русский</Select.Option>
              <Select.Option value="en">English</Select.Option>
            </Select>
          </div>
          {!!timeZones.length && (
              <div
                  style={{display: "flex", alignItems: "center", columnGap: "5px"}}
              >
                <p className="profile_info__text">
                  <span className="profile_info_title">{t("profile.timeZone")}:</span>
                </p>
                <Select
                    onChange={onChangeTimeZone}
                    value={currentTimeZone}
                    className="w-26 mr-2"
                    options={timeZones}
                >
                  {/*{timeZones?.map(({id, value}) => {*/}
                  {/*  <Select.Option key={id} value={value}>{value}</Select.Option>*/}
                  {/*})}*/}
                </Select>
                <TimeZoneModal/>
              </div>
          )}

          <div className='-mt-4'>
            <button onClick={shareGeoData} className='logout-button opacity-95 text-sm'>
              {profile?.coordinates?.length
                  ? t("profile.reShareGeoDataButtonTitle")
                  : t("profile.shareGeoDataButtonTitle")
              }
            </button>
          </div>

          <div className='border-t my-2.5'/>
          <p className="profile_info__text">
            <span className="profile_info_title">
              {t("profile.linkAccount")}:
            </span>
          </p>

          <div className={`w-full flex gap-2 items-center`}>
            <button
                className="social-button x"
                onClick={() => tokens.linkedIn ? socialUnhook.linkedIn() : socialLogin.linkedIn()}
            >
              <span className="icon">
                <img width={45} src={xIcon} alt=""/>
              </span>
              {tokens.linkedIn
                  ? <span className='text-xs w-full text-left flex flex-col items-start justify-start gap-0.5'>
                    <b className='text-xs'>
                      {t("signin.unlinkedLinkedIn")}
                    </b>
                    <span className='m-0 p-0'>
                    {socialMediaUsers?.linkedIn?.username.toLowerCase().includes('couldnt fetch') ? t("profile.linkedInError") : socialMediaUsers?.linkedIn?.username}
                    </span>
                    <span className='text-[10px] m-0 p-0'>
                    {socialMediaUsers?.linkedIn?.email}
                    </span>
                  </span>
                  : t("signin.linkLinkedIn")}
            </button>

            {tokens.linkedIn &&
                <button className="social-button x" onClick={showLinkedInModal} style={{minWidth: 40, padding: 10}}>
                  <img width={30} height={30} src={settingsIcon} alt="settings"/>
                </button>
            }
            {
              tokens.linkedIn &&
                  <Modal
                      open={linkedInModalVisible}
                      title={t('profile.selectLinkedInPage')}
                      onCancel={() => setLinkedInModalVisible(false)}
                      footer={null}
                  >
                    <div className='flex flex-col gap-2 pt-5'>
                      {linkedInPages?.length ? linkedInPages?.map((page, index) => (
                          <button
                              onClick={() => page?.page_id === profile?.linkedinPageId ? setLinkedInModalVisible(false) : handleChangeLinkedInPage(page)}
                              style={{marginTop: 0}}
                              className={` ${page?.page_id === profile?.linkedinPageId ? "logout-button" : "border-2 rounded-xl p-2 hover:bg-gradient-to-r from-cyan-600/15 to-purple-700/15"}`}
                              key={index}
                          >{page?.name}</button>
                      )) : (
                          <div className='text-center my-10 flex flex-col gap-5'>
                            {t("profile.noPagesLinkedIn")}
                            <Link to={'/guideline'} className='logout-button hover:text-black'>
                              {t("profile.openFBDocsTitle")}
                            </Link>
                          </div>
                      )}
                    </div>
                  </Modal>
            }
          </div>

          <div className={`w-full flex gap-2 items-center ${process.env.REACT_APP_IS_HIDE_FACEBOOK === 'true' && '!hidden'}`}>
            <button
                className="social-button x"
                onClick={() => tokens.facebook ? socialUnhook.facebook() : socialLogin.facebook()}
                style={{width: '100%'}}
            >
              <span className="icon">
                <img style={{minWidth: 30}} width={30} src={fbIcon} alt=""/>
              </span>
              {tokens.facebook
                  ? <span className='text-xs text-left flex flex-col items-start justify-start gap-0.5'>
                    <b className='text-xs'>
                      {t("signin.unlinkedFacebook")}
                    </b>
                    <span className='m-0 p-0 text-ellipsis text-nowrap overflow-hidden w-28'>
                    {socialMediaUsers?.facebook?.username?.toLowerCase().includes('couldnt fetch') ? t("profile.linkedInError") : socialMediaUsers?.facebook?.username}
                    </span>
                    <span className='text-[10px] m-0 p-0 text-ellipsis text-nowrap overflow-hidden w-28'>
                    {socialMediaUsers?.facebook?.email}
                    </span>
                  </span>
                  : t("signin.linkFacebook")}
            </button>
            {tokens.facebook ?
                <button className="social-button x" onClick={showFBModal} style={{minWidth: 40, padding: 10}}>
                  <img width={30} height={30} src={settingsIcon} alt="settings"/>
                </button>
                :
                <button className="social-button x" onClick={showFBModalInfo} style={{minWidth: 40, padding: 10}}>
                  <span className='text-2xl text-black/50'>
                    ?
                  </span>
                </button>
            }
          </div>
          {
              tokens.facebook ?
              <Modal
                  open={fbModalVisible}
                  title={t('profile.selectFacebookPage')}
                  onCancel={() => setFBModalVisible(false)}
                  footer={null}
              >
                <div className='flex flex-col gap-2 pt-5'>
                  {fbPages?.length ? fbPages?.map((page, index) => (
                      <button
                          onClick={() => page?.id === profile?.facebookPageId ? setFBModalVisible(false) : handleChangeFBPage(page)}
                          style={{marginTop: 0}}
                          className={` ${page?.id === profile?.facebookPageId ? "logout-button" : "border-2 rounded-xl p-2 hover:bg-gradient-to-r from-cyan-600/15 to-purple-700/15"}`}
                          key={index}
                      >{page?.name}</button>
                  )) : (
                      <div className='text-center my-10 flex flex-col gap-5'>
                        {t("profile.noPagesFB")}
                        <Link to={'/guideline'} className='logout-button hover:text-black'>
                          {t("profile.openFBDocsTitle")}
                        </Link>
                      </div>
                  )}
                </div>
              </Modal>
                  :
                  <Modal
                      open={fbModalInfoVisible}
                      title={t("profile.helpForFacebook")}
                      onCancel={() => setFBModalInfoVisible(false)}
                      footer={null}
                  >
                    <div className='flex flex-col gap-2 pt-5'>
                      <p dangerouslySetInnerHTML={{__html: t("profile.fbPagesInfoLinking")}} />
                      <Link to={'/guideline'} className='logout-button hover:text-black text-center'>
                        {t("home.guideline")}
                      </Link>
                    </div>
                  </Modal>
          }
          <div className='w-full flex gap-2 items-center'>
            <button
                className="social-button x"
                onClick={() => tokens.instagram ? socialUnhook.instagram() : socialLogin.instagram()}
            >
              <span className="icon">
                <img width={35} src={instIcon} alt=""/>
              </span>
              {tokens.instagram
                  ? <span className='text-xs w-full text-left flex flex-col items-start justify-start gap-0.5'>
                    <b className='text-sm'>
                      {t("signin.unlinkedInstagram")}
                    </b>
                    <span className='m-0 p-0'>
                    {socialMediaUsers?.instagram?.username?.toLowerCase()?.includes('couldnt fetch') ? t("profile.linkedInError") : socialMediaUsers?.instagram?.username}
                    </span>
                  </span>
                  : t("signin.linkInstagram")}
            </button>
            {!tokens.instagram &&
                <>
                <button className="social-button x" onClick={showInstModalInfo} style={{minWidth: 40, padding: 10}}>
                    <span className='text-2xl text-black/50'>
                      ?
                    </span>
                </button>
                  <Modal
                      open={instModalInfoVisible}
                      title={t("profile.helpForInstagram")}
                      onCancel={() => setInstModalInfoVisible(false)}
                      footer={null}
                  >
                    <div className='flex flex-col gap-2 pt-5'>
                      <p dangerouslySetInnerHTML={{__html: t("profile.instInfoLinking")}} />
                      <Link to={'/guideline'} className='logout-button hover:text-black text-center'>
                        {t("home.guideline")}
                      </Link>
                    </div>
                  </Modal>
                </>
            }
          </div>

          {org?.organization?.id
              ? <OrganizationSettings/>
              : <div className='border-t'>
                {/*<p className="profile_info__text">*/}
                {/*  <span className="profile_info_title">*/}
                {/*    {subLevel?.id*/}
                {/*        ? t("profile.subscriptionManagement")*/}
                {/*        : t("profile.subscriptionManagement")}*/}
                {/*    :*/}
                {/*  </span>*/}
                {/*</p>*/}
                <button
                    className="logout-button"
                    // className="profile-button"
                    onClick={() => navigate("/subscription-plans")}
                >
                  {subLevel?.id
                      ? t("profile.subscriptionManagement")
                      : t("profile.subscriptionManagement")}
                </button>
                <InviteFriend handleUpdate={sethandleUpdateReferrals}/>
                <ReferralsModal handleUpdate={handleUpdateReferrals}/>
                <RemoveAccountModal handleUpdate={sethandleUpdateProfile} profile={profile}/>
              </div>
          }

        </div>
      </div>
    </>
  );
};
