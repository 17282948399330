import React, {useEffect, useState} from "react";
import googleIcon from "./icons/google-icon.svg"; // Replace with path to your Google icon
import emailIcon from "./icons/email-icon.png";
import appleIcon from "./icons/apple-icon.svg"; // Replace with path to your Apple icon
import axios from "axios";
import facebookIcon from "./icons/facebook-icon.svg"; // Replace with path to your Google icon
import xIcon from "./icons/linkedin-icon.svg"; // Replace with path to your Apple icon
import fbIcon from "./icons/facebook-icon.svg";
import instIcon from "./icons/instagram-logo.png";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { authActions } from "../store";
import GoogleLogin from "react-google-login";
import { getProvidersUrls } from "../utils/auth";
import { PROVIDER } from "../constants/auth";
import { useTranslation, Trans } from "react-i18next";
import Public_Offer_IdeaSocial_ru from "../files/Public_Offer_IdeaSocial_ru.pdf";
import Public_Offer_IdeaSocial_en from "../files/Public_Offer_IdeaSocial_en.pdf";
import payment_ru from "../files/payment_ru.pdf";
import payment_en from "../files/payment_en.pdf";
import privacy_ru from "../files/Privacy_Policy_IdeaSocial_ru.pdf";
import privacy_en from "../files/Privacy_Policy_IdeaSocial_en.pdf";
import visaMaster from "../files/visa-master.png";
import { LanguageHeader } from "../components/LanguageHeader";
import {message, Modal} from "antd";
import {getUserCoordinates} from "../utils/getUserCoordinates";
import {fetchWrapper} from "../helpers";

const SignInPage = () => {
  const navigate = useNavigate();
  const [isWelcomeTutorialModalOpen, setIsWelcomeTutorialModalOpen] = useState(false);
  const [isEmailSignInModalOpen, setIsEmailSignInModalOpen] = useState(false);
  const user = useSelector((obj) => obj.auth.user);
  const { t, i18n } = useTranslation();
  // State for form inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const terms = {
    ru: Public_Offer_IdeaSocial_ru,
    en: Public_Offer_IdeaSocial_en,
  };
  const payment = {
    ru: payment_ru,
    en: payment_en,
  };
  const privacy = {
    ru: privacy_ru,
    en: privacy_en,
  };
  const dispatch = useDispatch();

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(authActions.login({ username: email, password }));
    // Implement your sign-up logic here
  };

  const showWelcomeTutorialModal = () => {
    setIsWelcomeTutorialModalOpen(true);
  };

  const handleOkWelcomeTutorialModal = () => {
    setIsWelcomeTutorialModalOpen(false);
    navigate('/how-to-work')
    localStorage.setItem('isHideWelcomeTutorialModal', 'true');
  };
  const handleCancelWelcomeTutorialModal = () => {
    setIsWelcomeTutorialModalOpen(false);
    localStorage.setItem('isHideWelcomeTutorialModal', 'true');
  };

  const onSuccess = async (res) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login/google`,
        {
          token: res?.tokenId,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const onSocialLogin = (key) => {
    localStorage.setItem('provider', key?.toLowerCase());
      fetchWrapper.get(`${process.env.REACT_APP_API_URL}/auth/special-url?sn=${key?.toLowerCase()}`)
        .then((res) => {
              if (res.redirect_url){
                console.log(res)
                window.location = res.redirect_url;
              } else {
                window.location = getProvidersUrls()[key];
              }
          })
          .catch((err) => {
              console.error(err);
              window.location = getProvidersUrls()[key];
          });
  };
  useEffect(() => {
    if (user){
      window.location = "/";
    }
  }, [user]);
  useEffect(() => {
      const timeOut = setTimeout(() => {
        setIsWelcomeTutorialModalOpen(localStorage.getItem('isHideWelcomeTutorialModal') ? false : true);
        clearTimeout(timeOut);
      }, 1000);
  }, []);
  return (
      <>
        {/*<LanguageHeader />*/}
        <div className='absolute w-full max-w-[375px] px-2 left-1/2 -translate-x-1/2 flex justify-end z-10'>
          {/*<p className='text-xs text-center'>*/}
          {/*  {t("signin.chooseLang")}*/}
          {/*</p>*/}
          <LanguageHeader/>
        </div>
        <div className="signup-wrapper">
          <header className="app-header"></header>
          <main className="signup-container">
            <h1 className='font-bold'>{t("signin.welcomeBack")}</h1>
            <p className="intro-text">
              {t("signin.introText")}
              {" "}
              <button
                  className='text-sm hover:text-violet-600 font-semibold hover:underline'
                  onClick={showWelcomeTutorialModal}
              >
                {t('signin.howThatWorkingBtn')}
              </button>
            </p>

            <Modal
              title={t('signin.welcomeModalTitle')}
              open={isWelcomeTutorialModalOpen}
              onOk={handleOkWelcomeTutorialModal}
              onCancel={handleCancelWelcomeTutorialModal}
              okText={t('signin.welcomeModalOkBtn')}
              cancelText={t('signin.welcomeModalCancelBtn')}
            >
              {t('signin.welcomeModalText')}
            </Modal>




            <Modal
                title={t('signin.signInEmail')}
                open={isEmailSignInModalOpen}
                onOk={handleOkWelcomeTutorialModal}
                onCancel={() => setIsEmailSignInModalOpen(false)}
                okText={t('signin.welcomeModalOkBtn')}
                cancelText={t('signin.welcomeModalCancelBtn')}
                footer={[null]}
            >
              <form className="signup-form" onSubmit={handleSubmit}>
                <label className="input-label" htmlFor="email">
                  {t("signin.emailLabel")}
                </label>
                <input
                    required
                    type="email"
                    name="email"
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <label className="input-label" htmlFor="password">
                  {t("signin.passwordLabel")}
                </label>
                <input
                    required
                    type="password"
                    name="password"
                    placeholder={t("signin.inputPassword")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="forgot-password">
                  <Link to="/forgot-password">{t("signin.forgotPassword")}</Link>
                </div>
                <button type="submit" className="continue-button">
                  {t("signin.continue")}
                </button>
              </form>
              <div className="signin-link">
                {t("signin.dontHaveAccount")}{" "}
                <Link to="/sign-up">
                  <span className="link">{t("signup.signUp")}</span>
                </Link>
              </div>
            </Modal>


            <div onClick={(e) => {
              e.stopPropagation();
              if (!agree) {
                message.info(t("signin.agreePrivacy"))
              }
            }} className="social-login-buttons">
              {/* <GoogleLogin
        style={{marginBottom: 10}}
        clientId={`364042631502-vln87v20384vhut1pdm0glb063g1vb00.apps.googleusercontent.com`}
        onSuccess={onSuccess}
      /> */}

              <button
                  disabled={!agree}
                  className="social-button google disabled:pointer-events-none"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEmailSignInModalOpen(true)
                  }}
              >
              <span className="icon">
                <img width={28} src={emailIcon} alt=""/>
              </span>
                {t("signin.signInEmail")}
              </button>

              <button
                  disabled={!agree}
                  className="social-button google disabled:pointer-events-none"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSocialLogin(PROVIDER.GOOGLE)
                  }}
              >
              <span className="icon">
                <img src={googleIcon} alt=""/>
              </span>
                {t("signin.signInGoogle")}
              </button>
              {/* <button className="social-button apple">
      <span className="icon"><img src={appleIcon} alt="" /></span>{t("signin.signInApple")}
    </button> */}
              {/* <button className="social-button facebook" onClick={() => onSocialLogin(PROVIDER.FACEBOOK)}>
      <span className="icon"><img src={facebookIcon} alt="" /></span>{t("signin.signInFacebook")}
    </button> */}

              <button
                  disabled={!agree}
                  className="social-button x disabled:pointer-events-none !hidden"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSocialLogin(PROVIDER.LINKEDIN)
                  }}
              >
              <span className="icon">
                <img width={30} src={xIcon} alt=""/>
              </span>
                {t("signin.signInLinkedIn")}
              </button>


              <button
                  disabled={!agree}
                  className={`!hidden social-button x disabled:pointer-events-none ${process.env.REACT_APP_IS_HIDE_FACEBOOK === 'true' && '!hidden'}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSocialLogin(PROVIDER.FACEBOOK)
                  }}
              >
              <span className="icon">
                <img width={30} src={fbIcon} alt=""/>
              </span>
                {t("signin.signInFacebook")}
              </button>


              <button
                  disabled={!agree}
                  className="social-button x disabled:pointer-events-none !hidden"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSocialLogin(PROVIDER.INSTAGRAM)
                  }}
              >
              <span className="icon">
                <img width={30} src={instIcon} alt=""/>
              </span>
                {t("signin.signInInstagram")}
              </button>

            </div>

            <div className="mt-5 privacy-checkbox-container">
              <input checked={agree} onChange={e => setAgree(e.target.checked)} type="checkbox" id="privacy" required/>
              <label htmlFor="privacy">
                <Trans
                    i18nKey="signin.privacy"
                    components={{
                      offerLink: (
                          <Link
                              className="privacy-link"
                              to={`/document/terms`}
                              // to={terms[i18n.language]}
                          ></Link>
                      ),
                      privacyLink: (
                          <Link
                              className="privacy-link"
                              to={`/document/privacy`}
                              // to={privacy[i18n.language]}
                          ></Link>
                      ),
                    }}
                ></Trans>
              </label>
            </div>

            {/*<div className="divider">*/}
            {/*  <span className="divider-line"></span>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*    className="main-content"*/}
            {/*    style={{*/}
            {/*      marginTop: "40px",*/}
            {/*      marginBottom: "20px",*/}
            {/*      textAlign: "center",*/}
            {/*    }}*/}
            {/*>*/}
            {/*  <Link className="signin-link" to={`/document/payment`}>*/}
            {/*    <img src={visaMaster} style={{cursor: "pointer"}} alt=""/>*/}
            {/*    <span className="link">{t("signin.payment")}</span>*/}
            {/*  </Link>*/}
            {/*</div>*/}
            <div className="divider">
              <span className="divider-line"></span>
            </div>
            <div
                className="main-content"
                style={{
                  marginTop: "40px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
            >
              <p style={{whiteSpace: "pre-line"}}>{t("signin.contacts")}</p>
            </div>
          </main>
        </div>
      </>
  );
};

export default SignInPage;
