import React, { useState } from 'react';
import PageHeader from '../../components/Header/PageHeader';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store';
import {useLocation} from 'react-router-dom';
import { LanguageHeader } from '../../components/LanguageHeader';

export const InputCode = ({ onCodeSubmitted }) => {
    const {t} = useTranslation()
    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!token) return;

        const res = await dispatch(authActions.recoveryPassword({token: token, username: location.state.username}))
        console.log('input code result', res)
        if (res?.error?.message === 'Rejected'){
            return;
        } else {
            navigate('/new-password',{state: {username: location.state.username}})
        }
        // onCodeSubmitted(); // This function should handle the transition to the next step
    };

    return (
        <>
            {/*<LanguageHeader/>*/}
            <PageHeader title={t('inputCode.header')} />
            <div className="forgot-password-container">
                <div className="content">
                    <p>{t('inputCode.title')}</p>
                    <input
                        required
                        type="text"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        placeholder={t('inputCode.placeholder')}
                    />
                    <button onClick={handleSubmit}>{t('inputCode.proceed')}</button>
                </div>
            </div>
        </>

    );
};
