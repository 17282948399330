

export const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth'
export const GOOGLE_SCOPE =
  'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'

export const FACEBOOK_AUTH_URL = 'https://www.facebook.com/v21.0/dialog/oauth'
export const FACEBOOK_SCOPE = 'read_insights,email,pages_show_list,business_management,pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_engagement,public_profile,instagram_basic,instagram_content_publish'

export const LINKEDIN_AUTH_URL =
  'https://www.linkedin.com/oauth/v2/authorization'
export const LINKEDIN_SCOPE = 'email,openid,profile,w_member_social'

export const INSTAGRAM_AUTH_URL =
  'https://www.instagram.com/oauth/authorize'
export const INSTAGRAM_SCOPE = 'public_profile,instagram_basic,instagram_content_publish'

export const PROVIDER = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  LINKEDIN: 'LINKEDIN',
  INSTAGRAM: 'INSTAGRAM'
}

export const MODAL_PURPOSE = {
  SIGN_UP: 'SIGN_UP',
  SIGN_IN: 'SIGN_IN'
}

export const PROVIDER_NAME = {
  [PROVIDER.GOOGLE]: 'Google',
  [PROVIDER.LINKEDIN]: 'LinkedIn',
  [PROVIDER.FACEBOOK]: 'Facebook',
  [PROVIDER.FACEBOOK]: 'Instagram'
}