import PageHeader from "../../components/Header/PageHeader";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {PostCard} from "./HowToWorkService/PostCard";
import {Button, message, notification, Space} from "antd";



const mockPosts = [
	{
		"post": "💡 **Как тестирование контента помогает в генерации идей?**\n\nПостоянное тестирование контента — это ключ к открытию новых идей и стратегий, которые могут радикально изменить ваш подход к маркетингу. Проводя A/B тестирования и анализируя результаты, можно выявить, какие форматы и сообщения лучше всего резонируют с вашей аудиторией. Это не только усиливает вовлеченность, но и создает более персонализированный опыт для ваших подписчиков. 🔍\n\nТестируйте, анализируйте и адаптируйтесь, чтобы оставаться на шаг впереди. Поделитесь своими мыслями в комментариях и расскажите, какие методы тестирования работают для вас! 📈\n\n-------------\n\n💡 **How does content testing fuel idea generation?**\n\nConsistent content testing is the key to unlocking new ideas and strategies that can dramatically shift your marketing approach. By conducting A/B tests and analyzing the results, you can identify which formats and messages resonate most with your audience. This not only boosts engagement but also creates a more personalized experience for your followers. 🔍\n\nTest, analyze, and adapt to stay ahead of the curve. Share your thoughts in the comments and let us know what testing methods work for you! 📈",
		"hashtags": "#ContentTesting #IdeaGeneration #MarketingStrategy #ABTesting #AudienceEngagement #Personalization #StayAhead",
		"media": "An eye-catching visual of a digital dashboard displaying vibrant, dynamic graphs and charts illustrating content performance metrics, symbolizing the analysis and adaptation process in content marketing.",
		"postTitle": "Тестирование контента: ключ к новым идеям!",

		id: 12345,
		socialNetwork: "LinkedIn,Facebook",
		date: new Date().toISOString(),
	},
	{
		"post": "💡 **Как тестирование контента помогает в генерации идей?**\n\nПостоянное тестирование контента — это ключ к открытию новых идей и стратегий, которые могут радикально изменить ваш подход к маркетингу. Проводя A/B тестирования и анализируя результаты, можно выявить, какие форматы и сообщения лучше всего резонируют с вашей аудиторией. Это не только усиливает вовлеченность, но и создает более персонализированный опыт для ваших подписчиков. 🔍\n\nТестируйте, анализируйте и адаптируйтесь, чтобы оставаться на шаг впереди. Поделитесь своими мыслями в комментариях и расскажите, какие методы тестирования работают для вас! 📈\n\n-------------\n\n💡 **How does content testing fuel idea generation?**\n\nConsistent content testing is the key to unlocking new ideas and strategies that can dramatically shift your marketing approach. By conducting A/B tests and analyzing the results, you can identify which formats and messages resonate most with your audience. This not only boosts engagement but also creates a more personalized experience for your followers. 🔍\n\nTest, analyze, and adapt to stay ahead of the curve. Share your thoughts in the comments and let us know what testing methods work for you! 📈",
		"hashtags": "#ContentTesting #IdeaGeneration #MarketingStrategy #ABTesting #AudienceEngagement #Personalization #StayAhead",
		"media": "An eye-catching visual of a digital dashboard displaying vibrant, dynamic graphs and charts illustrating content performance metrics, symbolizing the analysis and adaptation process in content marketing.",
		"postTitle": "Тестирование контента: ключ к новым идеям!",

		id: 123456789,
		socialNetwork: "LinkedIn,Facebook",
		date: new Date().toISOString(),
	}
];




const HowToWorkService = () => {
	const [notificationApi, notificationContextHolder] =
			notification.useNotification();
	const [posts, setPosts] = useState(JSON.parse(sessionStorage.getItem('posts')) || []);
	const {t} = useTranslation();
	const navigate = useNavigate();

	const onlyAfterAuth = () => {
		message.info(t("functions.accessAfterAuth"));
		const key = `open${Date.now()}`;
		const btn = (
				<Space>
					<Button
							type="primary"
							size="small"
							onClick={() => navigate("/sign-in")}
					>
						{t("signin.signIn")}
					</Button>
				</Space>
		);
		notificationApi.open({
			message: t("functions.warning"),
			description: t("functions.accessAfterAuth"),
			btn,
			key,
		});
	};

	return (
			<div>
				{notificationContextHolder}
				<PageHeader
						title={t("posts.header")}
						onBackClick={() => {
							navigate("/sign-in");
						}}
				/>
				<div
						// className='bg-red-500 max-w-screen-sm my-10 mx-auto'
				>
					<div className='flex justify-center items-center mt-5'>
						<button
								className="logout-button max-w-fit max-w-max mx-auto mb-2.5"
								onClick={() => posts.length > 3 ? onlyAfterAuth() : navigate("/how-to-work-post/create")}
						>
							{t("home.createPost")}
						</button>
					</div>
					<div className={`${posts.length&& 'ml-20'} flex flex-col gap-2`}>
						{posts.length? posts?.map((post, index) => (
								<PostCard
										key={index}
										post={post}
								/>
						))
								:
						<h2 className='max-w-sm mx-auto text-center mt-20 text-lg text-black/50 font-semibold'>
							{t('posts.postsListEmptyText')}
						</h2>
						}
					</div>
				</div>
			</div>
	);
};

export default HowToWorkService;