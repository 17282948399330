export default {
	"signin": {
		"signIn": "Кіру",
		"welcomeBack": "Қайта оралғаныңызға қуаныштымыз!",
		"introText": "iMean: әлеуметтік желілердегі сіздің ақылды көмекшіңіз.",
		"emailLabel": "Электрондық пошта мекенжайы",
		"inputPassword": "Құпиясөзді енгізіңіз",
		"passwordLabel": "Құпиясөз",
		"forgotPassword": "Құпиясөзді ұмыттыңыз ба?",
		"dontHaveAccount": "Тіркелгіңіз жоқ па?",
		"or": "немесе",
		"continue": "Жалғастыру",
		"signInEmail": "Пошта арқылы кіру",
		"signInGoogle": "Google арқылы кіру",
		"signInApple": "Apple арқылы кіру",
		"signInFacebook": "Facebook арқылы кіру",
		"signInInstagram": "Instagram арқылы кіру",
		"signInLinkedIn": "LinkedIn арқылы кіру",
		"linkFacebook": "Facebook аккаунтын байланыстырыңыз",
		"linkLinkedIn": "LinkedIn аккаунтын байланыстырыңыз",
		"linkInstagram": "Instagram аккаунтын байланыстырыңыз",
		"linkedFacebook": "Facebook аккаунты байланысты",
		"linkedInstagram": "Instagram аккаунты байланысты",
		"unlinkedLinkedIn": "LinkedIn аккаунтын ажырату",
		"unlinkedFacebook": "Facebook аккаунтын ажырату",
		"unlinkedInstagram": "Instagram аккаунтын ажырату",
		"privacy": "<offerLink>Жария оферта</offerLink> мен <privacyLink>Құпиялылық саясатының</privacyLink> шарттарын оқып шықтым, барлық талаптарды түсініп, келісемін.",
		"payment": "Төлем тәртібі",
		"contacts": "WhatsApp: +7 700 600 0001 \r\n Email: info@imean.io",
		"agreePrivacy": "“☑” белгісін басып, Жария Оферта мен Құпиялылық саясатының шарттарымен келісетіндігіңізді растаңыз.",
		"privacyTitle": "Құпиялылық саясаты",
		"offerTitle": "Жария оферта",
		"chooseLang": "Сізге ыңғайлы тілді таңдаңыз",
		"howThatWorkingBtn": "Бұл қалай жұмыс істейді?",
		"welcomeModalTitle": "Қош келдіңіз IMEAN.IO",
		"welcomeModalText": "Егер сіз мұнда бірінші рет болсаңыз және біздің қызметіміздің қалай жұмыс істейтінін білмесеңіз, біздің қосымшаның демо нұсқасын авторизациясыз сынап көру үшін \"көріңіз\" батырмасын басуға болады немесе тіркелгіңізге кіру үшін \"кіру\" батырмасын басыңыз",
		"welcomeModalOkBtn": "Көріңіз",
		"welcomeModalCancelBtn": "Кіру",
	},
	"signup": {
		"signUp": "Тіркелу",
		"fullName": "Толық аты-жөніңіз",
		"passwordRequirements": "Құпиясөз кем дегенде 8 символдан, 1 бас әріптен, 1 кіші әріптен, 1 цифрдан және 1 арнайы таңбадан тұруы керек.",
		"haveAccount": "Тіркелгіңіз бар ма?",
		"signUpGoogle": "Google арқылы тіркелу",
		"signUpApple": "Apple арқылы тіркелу",
		"signUpFacebook": "Facebook арқылы тіркелу",
		"signUpLinkedIn": "LinkedIn арқылы тіркелу",
		"lang": "Тіл"
	},
	"welcome": {
		"welcome": "iMean-ге қош келдіңіз, {{username}}!",
		"howWeWork": "Біз қалай жұмыс істейміз",
		"completeSurvey": "Жеке брендті сәтті қалыптастыру үшін стратегияны әзірлеу маңызды.",
		"completeSurvey2": "Осы стратегия негізінде сіздің мақсаттарыңыз бен қалауларыңызды ескеретін контент жоспары жасалып, жарияланымдар дайындалады.",
		"proceed": "Сауалнамаға өту",
		"warning": "Стратегияның сіздің күткеніңізге барынша сәйкес болуы үшін сұрақтарға мүмкіндігінше толық жауап беру өте маңызды.",
		"warning2": "Сізбен жұмыс жасау барысында біз стратегияны біздің көзқарасымыздан сізге нақтырақ бейімдейміз."
	},
	"assistant": {
		"header": "Көмекші",
		"title": "Сіздің жеке көмекшіңіз",
		"emptyChat": "Әзірге хабарламалар жоқ",
		"assistantInfo": "Көмекші қателік жіберуі мүмкін. Маңызды ақпаратты тексеруді ұсынамыз.",
		"helloMessage": "Сәлем!\nМен сіздің көмекшіңізбін және әртүрлі жағдайларда көмектесуге дайынмын: электрондық хат дайындау немесе оған жауап беру,\nсоңғы жаңалықтармен бөлісу, әзіл айту немесе аударма жасау. Мен сізге жұмыста, бизнесте және басқа да көптеген мәселелерде қолдау көрсетуге дайынмын. Сізге қалай көмектесе аламын?",
		"textToGeneratePostFromAssistantMessage": "Минималды өзгерістермен осы пост негізінде жарияланым жасаңыз",
		"createPostFromThisMessageBtn": "Осы хабарлама негізінде хабарлама жасаңыз",
		"chatSettingsTitle": "Чат параметрлері",
		"useYourFile": "Файлыңызды пайдаланыңыз",
		"assistantWarningInfo": "Бұл функцияға кіру үшін файлды жүктеу керек",
		"assistantQuickFilePopoverTitleEmpty": "Жылдам файл",
		"assistantQuickFilePopoverTitle": "Тіркелген файл",
		"assistantQuickFilePopoverInfo": "Неғұрлым сәйкес жауап немесе файл мазмұнын талдау үшін хабарламаңызға бір жылдам файлды тіркей аласыз.",
		"assistantSettingsInstructionTitle": "Көмекшіні үйретудің сынақ режимі.",
		"assistantSettingsInstructionContent": "Көмекшімен өзара әрекеттесу үшін оған ақпарат беріңіз. (Бұл режимге қосымша, жақын арада көмекшінің қосымша өте керемет мүмкіндіктері пайда болады).",
		"uploadFile": "Файлды жүктеу",
		"uploadNewFile": "Файлды жаңарту",
		"yourHaveFile": "Сізде жүктелген файл бар.",
		"confirm": "Растау",
		"fileUploadSuccess": "Сәтті жүктелді!"
	},
	"profile": {
		"unlinkLinkedinConfrim": "Сіз шынымен LinkedIn есептік жазбаңызды шешкіңіз келе ме?",
		"unlinkFacebookConfrim": "Сіз шынымен Facebook есептік жазбаңызды шешкіңіз келе ме?",
		"unlinkInstagramConfrim": "Сіз шынымен Instagram есептік жазбаңызды шешкіңіз келе ме?",
		"openFBDocsTitle": "Қолдану нұсқаулығын ашу",
		"noPagesFB": "Facebook беттері жоқ",
		"noPagesLinkedIn": "LinkedIn-де беттер жоқ",
		"cancelDelete": "Жоюды болдырмау",
		"accountWillBeDeleted": "Сіздің есептік жазбаңыз жойылады",
		"recoverAccount": "Жоюды болдырмау",
		"removeAccountBtn": "Есептік жазбаны жою",
		"yesRemoveAccount": "Иә, жою.",
		"notRemoveAccount": "Жоқ, жоймау",
		"removeAccountTitle": "Сіз есептік жазбаны шынымен жойғыңыз келе ме?",
		"removingApproved": "Есептік жазбаны жою туралы сұрау сәтті жіберілді!",
		"recoverApproved": "Есептік жазбаны жоюды болдырмау туралы сұрау сәтті жіберілді!",
		"accountActionWait": "Күтіңіз...",
		"removeAccountDescription": "Осы түймені басу арқылы сіз 14 күннен кейін деректеріңіздің мәңгілікке жойылатынын растайсыз. Осы мерзім өткен соң есептік жазбаны қалпына келтіру мүмкін болмайды.",
		"emptyReferrals": "Сіз әлі ешкімді шақырған жоқсыз!",
		"status-accepted": "Шақырылды",
		"status-pending": "Күтілуде...",
		"status-gifted": "Сыйақы алынды",
		"status-taken": "Шақырудан бас тартылды",
		"close": "Жабу",
		"yourReferrals": "Сіздің жолдауларыңыз",
		"reInvite": "Қайта жіберу",
		"inviteSent": "{{email}} электрондық поштасына шақыру жіберілді",
		"inviteError": "Шақыру жіберу кезінде қате орын алды. Бұл пайдаланушының бұрыннан тіркелгеніне байланысты болуы мүмкін.",
		"header": "Профиль",
		"assistDescription": "Жетілдірілген көмекшіден соңғы жаңалықтар мен кеңестерді алыңыз",
		"endSubscriptionDate": "Жазылым мерзімі аяқталады:",
		"endFreeSubscriptionDate": "Сынақ мерзімі аяқталады:",
		"buySubscription": "Жазылымды сатып алу",
		"subscriptionManagement": "Жазылымды басқару",
		"upgradeSubscription": "Жоспарды жаңарту",
		"extendSubscription": "Жазылымды ұзарту",
		"cancelSubscription": "Жазылымнан бас тарту",
		"comingSoon": "Көп ұзамай...",
		"soon": "Жақында...",
		"name": "Аты",
		"login": "Кіру",
		"subscription": "Жазылым мерзімі",
		"no": "Жоқ",
		"yes": "Иә",
		"lang": "Тіл",
		"gender": "Гендер",
		"male": "Ер адам",
		"female": "Әйел",
		"other": "Басқа",
		"timeZone": "Уақыт белдеуі",
		"linkAccount": "Есептік жазбаны байланыстыру",
		"linkedInError": "Есептік жазбаны қайта тағайындау",
		"inviteFriend": "Досыңызды шақырыңыз:",
		"invite": "Шақыру",
		"timeZoneModalTitle": "Уақыт белдеулері",
		"inviteModalTitle": "Досыңызды шақырыңыз және жарты ай тегін алыңыз!",
		"timeZoneModalText": `
        Біз сіздің ыңғайлылығыңыз үшін уақыт белдеуін (мысалы, UTC+5) таңдауды енгіздік, бұл жазбаларыңыздың кестесін нақтылауға мүмкіндік береді. Бұл қалаған аймақтағы аудиторияңызды жақсырақ нысанаға алуға және барынша қамтуға көмектеседі.
        <br>
        <b>LinkedIn-ге жазба жариялау үшін ұсынылатын уақыттар:</b>
        \t•\tДүйсенбі: таңғы 8:00-ден 10:00-ге дейін
        \t•\tСейсенбі: таңғы 9:00-ден 12:00-ге дейін
        \t•\tСәрсенбі: таңғы 10:00-ден 12:00-ге дейін
        \t•\tБейсенбі: таңғы 9:00-ден 11:00-ге дейін
        \t•\tЖұма: таңғы 9:00-ден 12:00-ге дейін
        <br>
        <b>Қолдану мысалы:</b>
        Егер сіз Қазақстанда (UTC+5) болсаңыз және АҚШ-тағы (мысалы, Нью-Йорк, UTC-5) аудиторияға мазмұн жариялағыңыз келсе, сәйкес уақыт белдеуін таңдаңыз. Бұл жазбаларыңыз Нью-Йорк уақытында таңғы 9:00-де көрінетініне кепілдік береді.
        <br>
        <b>Негізгі елдер мен олардың UTC айырмашылықтары:</b>
        <b>Азия</b>
        <br>
        \t•\tҚазақстан (Алматы): UTC+5
        \t•\tҚытай (Бейжің): UTC+8
        \t•\tҮндістан (Нью-Дели): UTC+5:30
        \t•\tЖапония (Токио): UTC+9
        \t•\tОңтүстік Корея (Сеул): UTC+9
        \t•\tИндонезия (Джакарта): UTC+7
        <b>Африка</b>
        \t•\tОңтүстік Африка (Йоханнесбург): UTC+2
        \t•\tЕгипет (Каир): UTC+2
        \t•\tНигерия (Лагос): UTC+1
        \t•\tКения (Найроби): UTC+3
        \t•\tМарокко (Рабат): UTC+0
        <b>Латын Америкасы</b>
        \t•\tБразилия (Сан-Паулу): UTC-3
        \t•\tАргентина (Буэнос-Айрес): UTC-3
        \t•\tМексика (Мехико): UTC-6
        \t•\tКолумбия (Богота): UTC-5
        \t•\tЧили (Сантьяго): UTC-3
        <b>Еуропа</b>
        \t•\tҰлыбритания (Лондон): UTC+0
        \t•\tГермания (Берлин): UTC+1
        \t•\tФранция (Париж): UTC+1
        \t•\tИталия (Рим): UTC+1
        \t•\tИспания (Мадрид): UTC+1
        <b>Солтүстік Америка</b>
        \t•\tАҚШ (Нью-Йорк, Шығыс уақыты): UTC-5
        \t•\tАҚШ (Лос-Анджелес, Тынық мұхиты уақыты): UTC-8
        \t•\tКанада (Торонто): UTC-5
        \t•\tКанада (Ванкувер): UTC-8
        <b>Қосымша ескертулер:</b>
        Кейбір елдерде бірнеше уақыт белдеуі бар екенін ескеріңіз. Егер сіздің мақсатты аудиторияңыз басқа елде болса, ең жақсы жариялау уақыттарын анықтау үшін тиісті UTC айырмашылығын тексеруді ұсынамыз.
        Жергілікті уақыттарды timeanddate.com сияқты құралдарды пайдаланып тексеруге болады.
        <br>
        Уақыт белдеуіңізді орнатыңыз және аудиторияңыздың назарын барынша арттырыңыз! 😊
    `,
		"inviteModalText": `
        Біздің қызметімізді тегін қосымша күндермен қолданғыңыз келе ме?<br>
        Жай ғана досыңызды шақырыңыз! Мұнда қалай жұмыс істейді:
        <br>
        <ol>
        <li><b>1. Байланыс деректерін бөлісіңіз:</b> Қызметіміз сіздің досыңызға қызықты болуы мүмкін деп ойласаңыз, оның электрондық поштасын беріңіз.</li>
        <li><b>2. Біз электрондық пошта жібереміз:</b> Біз досыңызға электрондық пошта жібереміз (сізді көшірмеде қалдырамыз), қызметімізді таныстырамыз және сіздің шақырғаныңызды хабарлаймыз. Бұл досыңыздың оның спам немесе алаяқтық емес екенін түсінуін қамтамасыз етеді.</li>
        <li><b>3. Жарты ай тегін алыңыз:</b> Егер досыңыз тіркеліп, ақылы жоспарға жазылса, сіз автоматты түрде досыңыз таңдаған жоспарға 15 күн тегін аласыз.</li>
        <li><b>4. Сіздің досыңыз да пайда көреді:</b> Досыңыз да қызметімізді бірінші ай бойы пайдалану үшін <b>30% жеңілдік</b> алады. Бұл барлығына пайдалы!</li>
        </ol>
        <br>
        Егер сізде сұрақтар болса, бізбен <a class='text-purple-800' href="mailto:info@imean.io">info@imean.io</a> электрондық поштасы арқылы байланысыңыз — біз көмектесуге дайынбыз!
    `,
		"shareGeoDataButtonTitle": "Орынды бөлісу",
		"reShareGeoDataButtonTitle": "Орынды жаңарту",
		"sharedGeoDataSuccess": "Сіз i серверде орналасқан жеріңізді сәтті жаңарттыңыз IMEAN.IO",
		"sharedGeoDataError": "I сервердегі орынды жаңарту кезінде қате пайда болды IMEAN.IO",
		"selectFacebookPage": "Facebook бетін таңдаңыз",
		"selectLinkedInPage": "LinkedIn бетін таңдаңыз",
		"helpForFacebook": "Facebook-ке көмек",
		"fbPagesInfoLinking": "Facebook-тің тіркелгіңізді біздің қызметімізбен байланыстыру үшін, сізде жеке парақша ғана  емес, Facebook парақшасы бар екеніне көз жеткізіңіз." +
				"<br/>" +
				"<br/>" +
				"Парақшаны қалай жасау керектігі туралы ақпаратты басты терезедегі «Пайдалы кеңестер» бөлімінен таба аласыз.",
		"helpForInstagram": "Instagram-ға көмек",
		"instInfoLinking": "Instagram тіркелгіңізді біздің қызметімізбен байланыстыру үшін, сізде Instagram бизнес-аккаунты бар екеніне көз жеткізіңіз." +
				"<br/>\n" +
				"<br/>\n" +
				"Бизнес-аккаунтты қалай жасау керектігі туралы ақпаратты басты терезедегі «Пайдалы кеңестер» бөлімінен таба аласыз.",
	},
	"subscriptionPlans": {
		"header": "Тарифтер",
		"Strategy": "Стратегия",
		"ContentPlan": "Контент жоспарлары",
		"LeadingQuestions": "Негізгі сұрақтар",
		"Post": "Посттар",
		"Theme": "Тақырыптар",
		"PostImages": "Пост суреттері",
		"free": "Тегін",
		"paid": "Премиум",
		"assistant": "Кеңейтілген Ассистент",
		"infoButton": "Функционал сипаттамасы",
		"promoCodeTitle": "Промо-код",
		"havePromoCode": "Промо-код бар ма?",
		"promoCodeError": "Қате промо-код немесе акция мерзімі аяқталды",
		"modalTitle": "IMEAN.IO қызметінің функционалы:",
		"modalText": `
            iMean LinkedIn, Facebook, Instagram желісінде жасанды интеллект арқылы посттар құруды жеңілдетіп және тиімді етеді. Қызметіміз келесілерді ұсынады:
            
             <b>• Жасанды интеллект арқылы пост жасау:</b>
            Қызметіміз кәсіби мақсаттарыңызға және жеке стиліңізге сай контент жасайтын алгоритмдерді пайдаланады. Бұл контент аудиторияңыз бен сіздің салаларыңыздың негізгі тақырыптарына сәйкес келеді, сізді бәсекелестерден ерекшелендіреді және жеке брендіңізді қалыптастырады.
            
             <b>• Бір сәтте немесе жоспар бойынша жариялау:</b>
            Посттарды бірден жариялауға немесе ыңғайлы уақытта жоспарлауға мүмкіндік береді. Жоспарлы жариялау платформаның белсенділігін сақтауға және аудиторияға ең тиімді уақытта жетуге көмектеседі.
            
             <b>• Ассистент:</b>
            Интеллектуалды ассистент сұрақтарыңызға жауап беруге, пост идеяларын жасауға, мәтіндерді аударуға, іскерлік хаттар құрастыруға және аудиториямен өзара әрекеттесу стратегияларын ұсынуға дайын. Сіз ассистентке пост жазып, оны бірден LinkedIn, Facebook, Instagram жариялауды сұрай аласыз, бұл уақытыңызды үнемдейді.
            
             <b>• Дауыстық енгізу:</b>
            Терудің қажеті жоқ — тек сөйлеңіз, қызметіміз сөздеріңізді мәтінге аударады. Бұл мүмкіндікті уақыт тапшылығы кезінде немесе идеяңызды жылдам жазып алу үшін пайдалануға болады.
        `,
		"submit": "ОК",
		"unlimitedRate": "Шексіз функционал",
		"assistantRate": "Шексіз функционал + жеке жасанды интеллект ассистенті",
		"freeRate": "Тегін сынақ кезеңі 14 күнге",
		"active": "Белсенді",
		"offerExpire": "Ұсыныс аяқталады",
		"offerOver": "Ұсыныс аяқталды",
		"subscribeExpired": "Жазылу мерзімі аяқталды",
		"subscribeExtended": "Жазылым сәтті ұзартылды!",
		"rememberMyCard": "Картаны есте сақтаңыз",
		"rememberMyCardPopoverTitle": "Төлем жасау  жөнiнде пайдаланушыларға арналған кеңес",
		"rememberMyCardPopoverText": "Сіздің қолайлылығыңыз үшін чекбокс таңдауын белгілеп, картаны сақтай аласыз және келесі төлемдерде оны қайта енгізудің қажеті болмайды. Біз сіздің картаңыздың деректерін сақтамаймыз және өңдемейміз — бұл процесті төлемдерді қауіпсіз өңдеуді қамтамасыз ететін FreedomPay халықаралық төлем платформасы жүзеге асырады.\n" +
				"\n" +
				"Егер қолыңызда карта болмаса, Apple Pay, Google Pay немесе Samsung Pay арқылы төлем жасай аласыз. Бұл жағдайда картаны сақтау үшін  белгі  таңдаудың қажеті жоқ.",
		"removeMyCardBtn": "Картаны жою",
		"cardDeleted": "Карта сәтті жойылды!",
		"cardDeleteError": "Картаны жою кезінде сервер қатесі!",
		"confirmDeleteCard": "Сіз шынымен төлем картаңызды жойғыңыз келе ме?",
		"confirmExtendSubscribe": "Жазылымды жаңартуды растаңыз",
	},
	"survey": {
		"survey": "Сауалнама",
		"recording": "Дауыс жазылып жатыр... Тоқтату үшін басыңыз",
		"type": "немесе жауабыңызды осында теріңіз...",
		"wait": "Жауаптарыңыз сақталып, ақпарат жасалуда. Біраз күте тұрыңыз",
		"clear": "Тазалау",
		"submit": "Жіберу",
		"tapToStart": "Жауабыңызды айту үшін басыңыз",

		"title": "Контент құру үшін ақпаратты толтырыңыз",

		"inputLabelName": "Сіздің атыңыз?",
		"inputPlaceholderName": "Атыңызды енгізіңіз",
		"inputInfoName": "Бұл ат контентте қолданылады",

		"inputLabelIndustry": "Сала",
		"inputPlaceholderIndustry": "Сіздің салаңыз",
		"inputInfoIndustry": "Егер сіздің салаңыз тізімде болмаса, оны қолмен енгізіңіз",

		"inputLabelPosition": "Лауазым",
		"inputPlaceholderPosition": "Сіздің лауазымыңыз",
		"inputInfoPosition": "Егер сіздің лауазымыңыз тізімде болмаса, оны қолмен енгізіңіз",

		"textLabelWorkExperienceAndSkills": "Жұмыс тәжірибесі мен негізгі дағдылар",
		"textPlaceholderWorkExperienceAndSkills": "Жұмыс тәжірибеңіз, негізгі дағдыларыңыз бен біліктілігіңіз туралы қысқаша сипаттама және сіз мақтанатын жобалар.",

		"textLabelPersonalityHobby": "Жеке қызығушылықтар мен хоббилер",
		"textPlaceholderPersonalityHobby": "Посттарды шынайырақ етіп көрсету үшін қызығушылықтарыңыз бен хоббилеріңіз туралы ақпарат.",

		"textLabelSocialActivity": "Әлеуметтік белсенділік және қоғамдағы қатысу",
		"textPlaceholderSocialActivity": "Сіз қатысатын топтар немесе қауымдастықтар (мысалы, кәсіподақтар, волонтерлік, кәсіби топтар).",

		"textLabelShortGoals": "Қысқа мерзімді мансаптық мақсаттар (1-2 жыл)",
		"textPlaceholderShortGoals": "Қысқа мерзімде жетуді қалайтын кәсіби мақсаттарыңызды анықтаңыз. Бұл лауазымдық көтерілу, жаңа дағдыларды үйрену немесе маңызды жобаларды сәтті аяқтау болуы мүмкін.",

		"textLabelLongGoals": "Ұзақ мерзімді мансаптық мақсаттар (3-5 жыл)",
		"textPlaceholderLongGoals": "Ұзақ мерзімде қандай бағытта дамығыңыз келеді. Болашақта өзіңізді қандай рөлдерде немесе лауазымдарда көресіз? Бұл мақсаттар мансаптық армандарыңызға жету үшін стратегиялық қадамдарға назар аударуға көмектеседі.",

		"textLabelUniqueTradeRelations": "Сіздің ерекше ұсынысыңыз (USP)",
		"textPlaceholderUniqueTradeRelations": "Сіздің салаңыздағы басқа мамандардан ерекшелендіретін не нәрсе? Бұл ерекше дағдылар, тәсілдер немесе жетістіктер болуы мүмкін.",

		"textLabelAchievements": "Марапаттар мен жетістіктер",
		"textPlaceholderAchievements": "Кәсіби марапаттар, сертификаттар немесе әріптестерден алған мақтаулар, бұл сіздің тәжірибеңіз бен саланың дамуына қосқан үлестеріңізді көрсетеді.",

		"textLabelProjects": "Аяқталмаған жобалар мен бастамалар",
		"textPlaceholderProjects": "Қазіргі уақытта жұмыс істеп жатқан жобалар немесе бастамалар туралы ақпарат, бұл аудиторияның қызығушылығын оятады.",

		"textLabelStrongSides": "Сіздің күшті жақтарыңыз",
		"textPlaceholderStrongSides": "Кәсіби іс-әрекетіңізді табысты орындауға және ерекшеленуге көмектесетін негізгі дағдылар мен қасиеттер.",

		"textLabelWeakSides": "Әлсіз жақтары",
		"textPlaceholderWeakSides": "Сіз жақсартуды қажет деп санайтын немесе дамытуға тырысатын аспектілер.",

		"selectLabelExperience": "Тәжірибе деңгейі",
		"selectPlaceholderExperience": "Тәжірибе деңгейіңізді таңдаңыз",

		"checkboxLabelThemes": "Талқылау тақырыптары",

		"checkboxLabelGoalsPublicLinkedIn": "Әлеуметтік желілерде жариялау мақсаттары"
	},
	"home": {
		"welcomeBack": "Қош келдіңіз, ",
		"logout": "Шығу",
		"support": "Техникалық қолдау",
		"guideline": "Пайдалы кеңестер",
		"contentPlan": "Контент жоспарын құру",
		"personalAssistant": "Жеке ассистент",
		"strategy": "Сандық стратегия",
		"createPost": "Пост жасау",
		"formatPost": "Пост форматы",
		"linkSocials": "Әлеуметтік желілерді байланыстыру",
		"profile": "Профиль",
		"personalBrandStrategy": "Жеке бренд — бұл мақсатты аудиторияның санасында қалыптасатын кәсіби қызметпен айналысатын адамның қоғамдық бейнесі.",
		"profileTitle": "Профиль, жазылым және шақырылған достар туралы ақпарат",
		"contentPlanTitle": "Бұл бөлімде болашақ посттар туралы ақпарат сақталады",
		"createPostTitle": "Мұнда сіз тез арада пост жасап, оны бірден жариялай аласыз.",
		"assistantTitle": "Диалог режимінде жеке ассистент мәтінді аудара алады, хаттар жаза алады және тағы басқалар.",
		"strategyTitle": "Мұнда сізге арналған стратегия жасалады.",
		"subscriptionDaysLeft": "Жазылым аяқталуына қалған күндер саны: {{days}}"
	},
	"personalBrand": {
		"needSurveyText": "Цифрлық контенты дәлірек жасау үшін сауалнамадан өту қажет",
		"coffee": "Бұл шамамен 5-7 минутты алады.\r\nСтратегия қалыптасып жатқанда, шабыттандыру үшін бір шыныаяқ кофе қалай болады?",
		"header": "Цифрлық стратегия",
		"newStrategy": "Жаңа бренд стратегиясын жасау",
		"Name": "Аты",
		"Occupation/Title": "Қызметі/Атауы",
		"Short Bio": "Қысқаша өмірбаяны",
		"Accolades & Awards": "Марапаттар мен жетістіктер",
		"Strengths": "Мықты жақтар",
		"Weaknesses": "Әлсіз жақтар",
		"Passions": "Қызығушылықтар",
		"Values": "Құндылықтар",
		"What Sets You Apart": "Сізді әріптестерден ерекшелендіретін нәрселер",
		"Unique Benefits You Can Provide": "Сіз ұсына алатын бірегей артықшылықтар",
		"Determine Who You are Trying to Reach and Communicate With": "Кімді қамтуды және байланысуды жоспарлайтыныңызды анықтаңыз",
		"Understand Their Needs, Wants, and Challenges": "Олардың қажеттіліктерін, қалауларын және қиындықтарын түсініңіз",
		"How Should Daniel Address These Needs and Challenges": "Бұл қажеттіліктер мен қиындықтарды {{username}} қалай шешуі керек?",
		"Colors": "Түстер",
		"Imagery": "Көрнекіліктер",
		"Personal Logo or Signature": "Жеке логотип немесе қолтаңба",
		"Areas of Expertise": "Маманданған салалар",
		"Content Formats": "Контент форматтары",
		"Content Themes": "Контент тақырыптары",
		"Engagement Strategy": "Қатысу стратегиясы",
		"Short-term Goals (1-2 years)": "Қысқа мерзімді мақсаттар (1-2 жыл)",
		"Long-term Goals (3-5 years)": "Ұзақ мерзімді мақсаттар (3-5 жыл)",
		"Feedback Channel": "Кері байланыс арнасы",
		"Adaptation Strategy": "Бейімделу стратегиясы",
		"Conclusion": "Қорытынды",
		"pdf": "PDF жүктеп алу"
	},
	"sendPassword": {
		"header": "Құпия сөзді ұмыттыңыз ба",
		"title": "Электрондық поштаңызды енгізіңіз, код жіберіледі",
		"placeholder": "Электрондық пошта мекенжайы",
		"sendCode": "Код жіберу"
	},
	"inputCode": {
		"header": "Кодты енгізіңіз",
		"title": "Электрондық поштаңызға жіберілген кодты енгізіңіз.",
		"placeholder": "Кодты енгізіңіз",
		"proceed": "Жалғастыру"
	},
	"newPassword": {
		"noMatch": "Құпия сөздер сәйкес келмейді.",
		"header": "Жаңа құпия сөз жасау",
		"passwordRequirements": "Құпия сөзіңіз кемінде 8 таңбадан тұруы керек - 1 бас әріп, 1 кіші әріп, 1 сан және 1 арнайы таңба.",
		"placeholderCreate": "Жаңа құпия сөз жасау",
		"placeholderConfirm": "Құпия сөзді растау",
		"continue": "Жалғастыру"
	},
	"post": {
		"textToGenerateAnotherPost": "Осыған ұқсас немесе осы жазбаның жалғасын жазу",
		"at": "уақытында",
		"needSurveyText": "Цифрлық контенты дәлірек жасау үшін сауалнамадан өту қажет",
		"needSurveyRepeatText": "Келесі жазбаларды генерациялау үшін сізге сауалнаманы аяқтау қажет",
		"leadingQuestions": "Негізгі сұрақтар",
		"noLeading1": "Жеке жазба жазу үшін \"Генерациялау\" түймесін басыңыз",
		"noLeading2": "Егер қазір не туралы жазатыныңызды білмесеңіз, “Құру” батырмасын басыңыз – ұсынылған сұрақтар мен оларға барыншы мүмкін жауаптар Сізге бағыт беруі мүмкін. Әйтпесе, бұл ескертуді елемей-ақ қойыңыз.",
		"loading": "Жүктелуде...",
		"generate": "Генерациялау",
		"headerPost": "Жазба",
		"headerCreate": "Жазба жасау",
		"suggestion": "Жазба ұсынысы",
		"published": "Жарияланды",
		"notpublished": "Жарияланбаған",
		"broken": "Қате",
		"planned": "Жоспарланған",
		"hashtagSuggestion": "Хэштег ұсыныстары",
		"mediaSuggestion": "Визуал бойынша ұсыныстар",
		"image": "Медиа файлдар",
		"noImage": "Медиа файлдар жоқ!",
		"noImageInfoNotDrag": "Файлдарды осында апарыңыз немесе таңдау үшін басыңыз",
		"noImageInfoWithDrag": "Жүктеу үшін босатыңыз",
		"bigFileSize": "Файл тым үлкен. Басқа суретті таңдаңыз, ең көбі 10мб",
		"bigMediaListSize": "Медиа файлдардың жалпы салмағы {{size}}МБ-тан асады. Жалпы өлшемі {{size}}мб дейінгі басқа медиа файлдарды таңдаңыз",
		"maxVideoSize": "Файл тым үлкен. Басқа бейнені таңдаңыз, ең көбі 300мб",
		"uploadImage": "Сурет жүктеу",
		"processingImage": "Күтіңіз, біз суретті өңдеп жатырмыз",
		"save": "Сақтау",
		"generateImage": "Сурет жасау",
		"generatingImage": "Сурет жасалуда",
		"generating": "Біз сіздің жазбаңызды генерациялап жатырмыз",
		"remove": "Жою",
		"addVideo": "Бейне жүктеу",
		"removeVideo": "Бейнені жою",
		"removeImages": "Суреттерді жою",
		"maxVideoDuration": "Бейненің ұзақтығы тым үлкен. Ең көбі 10 минут болатын бейнені таңдаңыз",
		"dragActive": "Файлдарды осында апарыңыз немесе таңдау үшін басыңыз",
		"onDrag": "Жүктеу үшін босатыңыз",
		"dragInfo": "Сіз медиа файлдарды жүктей аласыз: ең көбі 200мб бейнелер және ұзақтығы 10 минутқа дейінгі бейнелер мен 10мб суреттер.",
		"notificationFilesForPlatformsTitle": "Әлеуметтік желілердегі суреттерге қатысты ұсыныстар",
		"notificationFilesForPlatformsDescription": "Әлеуметтік желілер әртүрлі суреттерге өз талаптарын қояды. Төменде әр платформаға арналған суреттерге қатысты ұсыныстар берілген:  \n" +
			" \n" +
			"LinkedIn\n" +
			"- Ұсынылатын өлшемі: 1200 x 627 пиксель (1.91:1 қатынасы).  \n" +
			"- Минималды өлшемі: 552 x 368 пиксель.  \n" +
			"- Максималды өлшемі: 7680 x 4320 пиксель.  \n" +
			" \n" +
			"Facebook\n" +
			"- Ұсынылатын өлшемі: 1200 x 630 пиксель.  \n" +
			"- Ұсынылатын ені: кемінде 630 пиксель.  \n" +
			" \n" +
			"Instagram\n" +
			"- Ұсынылатын өлшемі:1080 x 1080 пиксель.  \n" +
			"- Шаршы сурет: 1080 x 1080 пиксель(1:1).  \n" +
			"- Портрет: 1080 x 1350 пиксель (4:5).  \n" +
			"- Ландшафт: 1080 x 566 пиксель (1.91:1).  \n" +
			" \n" +
			"Маңызды:  \n" +
			"Әлеуметтік желілердегі жазбаларға, соның ішінде суреттерге қойылатын талаптар өзгеруі мүмкін. Сондықтан посттарды жариялау алдында олардың сәйкес келетінін тексеру ұсынылады.",
		"postGenerateImageLimit": "Бір постқа кескін генерациясының максималды саны-3 әрекет.",
		"postGenerateImageLeft": "Сізде әлі де әрекет бар:",
	},
	"contentPlan": {
		"header": "Контент жоспары",
		"week": "Апта",
		"add": "Әлеуметтік желілерді таңдау үшін + басыңыз",
		"frequency": "Жиілік",
		"elementsTitle": "Осы аптаның контенті үшін элементтерді таңдаңыз немесе қосыңыз",
		"custom": "Өзіңіздің сұрауларыңызды осында жазыңыз",
		"create": "Контент жоспарын жасау",
		"tokenError": "Генерациялау үшін кем дегенде бір әлеуметтік желі қосылған болуы керек",
		"strategyError": "Сіз жеке стратегия жасамадыңыз",
		"needSurveyText": "Контент жоспарын жасау үшін сауалнаманы аяқтау қажет",
		"goToSurveyBtnText": "Сауалнамаға өту",
		"selectWeekDaysTitle": "Аптаның қай күндеріне мазмұн жасау керектігін таңдаңыз",
	},
	"organization": {
		"managedByOrganization": "Бұл профиль ұйыммен басқарылады",
		"viewFile": "Файлды қарау",
		"uploadFile": "Файлды жүктеу",
		"replaceFile": "Файлды ауыстыру",
		"replaceLogo": "Логотипті ауыстыру",
		"aboutOrganization": "Ұйым туралы",
		"contacts": "Байланыс",
		"name": "Аты",
		"establishmentYear": "Құрылған жылы",
		"email": "E-Mail",
		"phone": "Телефон",
		"website": "Веб-сайт",
		"address": "Мекенжай",
		"credentials": "Реквизиттер",
		"BANK": "БАНК",
		"BIC": "БИК",
		"BIN": "БИН",
		"IIK": "ИИК",
		"KBE": "КБЕ",
		"generalDirector": "Бас директор"
	},
	"functions": {
		"apply": "Қолдану",
		"generate": "Құру",
		"generating": "Генерациялануда...",
		"successfullyGenerated": "Сәтті генерацияланды!",
		"successfullyFinished": "Сәтті аяқталды!",
		"successfullyUploaded": "Сәтті жүктелді!",
		"successfullySaved": "Сәтті қалды!",
		"successfullySendToGenerate": "Генерациялау процесі сәтті басталды!",
		"wrong": "Қате кетті",
		"loading": "Жүктелуде...",
		"sending": "Жіберілуде...",
		"microphone": "Микрофонға қол жеткізу қатесі, құрылғыны тексеріңіз немесе рұқсат беріңіз",
		"generatingPost": "Күтіңіз, пост генерациялануда",
		"generatingContentPlan": "Күтіңіз, контент жоспары генерациялануда",
		"generatingBrand": "Күтіңіз, стратегия генерациялануда",
		"loadingLeading": "Сұрақтар жүктелуде",
		"copied": "Көшірілді!",
		"pastedFromClipboard": "Аралық сақтағыштан салынған!",
		"savingAnswersForStrategy": "Жауаптар сақталып, стратегия жасалуда",
		"close": "Жабу",
		"loggedIn": "Сәтті кірдіңіз!",
		"registered": "Сәтті тіркелдіңіз!",
		"incorrectCreds": "Қате мәліметтер",
		"limitReached": "Әрекет лимиті таусылды, {{count}} / {{max}} пайдаланылды",
		"recoveryRequested": "Қалпына келтіру сұралды",
		"newPassword": "Құпия сөз жаңартылды",
		"error": "Қате",
		"errorPleaseCallSupport": "Қате, техникалық қолдауға хабарласыңыз.",
		"warning": "Ескерту",
		"wrongLayout": "Қате пернетақта орналасуы, тілді өзгерту қажет",
		"maxImages": "Максималды сурет саны - 6",
		"beta": "Бета",
		"minMediaForInstagramPostMessage": "Instagram-да жариялау үшін кем дегенде бір медиа файлды жүктеу немесе жасау керек!",
		"collapse": "Орау",
		"expand": "Орналастыру",
		"next": "Келесі",
		"previous": "Артқа",
		"onThePage": "бетте",
		"copy": "Көшіру",
		"continue": "Жалғастыру",
		"accessAfterAuth": "Авторизациядан кейін ғана қол жетімді!"
	},
	"socials": {
		"header": "Әлеуметтік желілер",
		"linkAccounts": "Авто-пост жасау үшін әлеуметтік желі есептік жазбаларын байланыстырыңыз"
	},
	"plancard": {
		"generated": "Пост генерацияланды",
		"notGenerated": "Пост генерацияланбады",
		"approved": "Бекітілген",
		"notApproved": "Бекітілмеген",
		"publish": "Постты жариялау",
		"approve": "Постты бекіту",
		"approveAgain": "Қайтадан жариялау",
		"disapprove": "Постты бекітпеу",
		"hide": "Жарияланымды жасыру",
		"hiddeConfrim": "Сіз бұл жазбаны шынымен жасырғыңыз келе ме?",
		"successfullyHidden": "Пост сәтті жасырылды!",
	},
	"posts": {
		"header": "Посттар",
		"generatePost": "Постты генерациялау",
		"postDescription": "Жоспарланған посттың негізгі идеясын немесе контекстін бөлісіңіз. Микрофонды пайдалануға болады.",
		"postDetails": "Мәліметтер",
		"postDate": "Жариялау күні",
		"publish": "Жариялау",
		"create": "Жасау",
		"cancel": "Бас тарту",
		"regenerate": "Постты қайта генерациялау",
		"limit": "Лимит таусылды. Қызметті жалғастыру үшін ақылы тарифке жазылуға болады.",
		"unknownError": "Қате пайда болды. Сізден сервисті техникалық қолдау бөліміне хабарласуыңызды және мәселені сипаттауыңызды сұраймыз. Мәселені тез шешу үшін бар күшімізді саламыз.",
		"notLinked-linkedin": "Посттарды жариялау үшін LinkedIn есептік жазбасын байланыстырыңыз",
		"notLinked-facebook": "Посттарды жариялау үшін Facebook есептік жазбасын байланыстырыңыз",
		"notLinked-instagram": "Посттарды жариялау үшін Instagram есептік жазбасын байланыстырыңыз",
		"notSelectedFacebookPage": "Facebook жазбаларын жариялау үшін профильдегі Facebook бетін таңдаңыз",
		"goToRate": "Тарифтерге өту",
		"goToProfile": "Профильге өту",
		"goToSupport": "Қолдауға өтіңіз",
		"chooseFormatTitle": "Жариялау форматын таңдаңыз",
		"acceptFormat": "Қабылдау",
		"formatExampleText": "Мысал",
		"createAnotherPost": "Ұқсас пост жасау",
		"seePost": "Постты көру",
		"formats": "Форматтар",
		"selectedLanguages": "Таңдалған тілдер",
		"postLengthTitle": "Пост ұзындығы (сөздер)",
		"postEmojisTitle": "Посттағы эмодзилердің көптігі",
		"kazGenerateWarningMessage": "Қазір қазақ тіліндегі ұрпақ бета-тестілеуде, бірақ біз қазақ тіліндегі ұрпақ сапасын жақсарту үшін жұмыс істеп жатырмыз",
		"multiLanguagePostsGeneratePlaceholder": "Көп тілді жазбаларды жасау үшін Тілдерді таңдаңыз (бос қалдырылған жағдайда) пост профильде таңдалған тілде жасалады)",
		"multiLanguagePostsGenerateTitle": "Көп тілді посттарды құру",
		"multiLanguagePostsGenerateDescription": "Егер сіз бірнеше тілде хабарлама жасағыңыз келсе, төмендегі өрістегі тілдерді таңдаңыз. \n" +
				"Тілді таңдағанда, тілдерді таңдау тәртібін ескеріңіз (хабарлама тілдерді таңдау ретімен жасалады). \n" +
				"Егер сіз өрісті бос қалдырсаңыз, хабарлама профильде таңдалған тілде жасалады.",
		"postStatusesInfoTitle": "Жарияланымдардың мәртебесі туралы ақпарат",
		"postsListEmptyText": "Жазбалар тізімі бос, пост жасау үшін \"пост жасау\" түймесін басыңыз",
		"postLanguageModelTitle": "Тіл моделін таңдау",
		"postModels": {
			"default": "Стандартты",
			"advance": "Жетілдірілген",
		},
		"memoizeChoice": "Таңдауды есте сақтаңыз",
	},
	"support": {
		"header": "Техникалық қолдау",
		"sectionLabel": "Бөлімді таңдаңыз",
		"messageLabel": "Мәселені немесе ұсынысты сипаттаңыз",
		"fileLabel": "Файл тіркеу",
		"send": "Жіберу",
		"uploadFile": "Жүктелген файлдар",
		"removeFile": "Жою",
		"sections": {
			"home-page": "Басты бет",
			"profile-page": "Профиль",
			"content-plan": "Контент жоспары",
			"publication": "Жариялау",
			"personal-assistant": "Жеке көмекші",
			"personal-branding": "Жеке стратегия"
		},
		"clearCacheBtnTitle": "Кэшті тазарту",
		"clearCacheDescription": "Егер сізде ескірген деректермен проблемалар туындаса, ақпаратты жаңарту үшін \"Кэшті тазарту\" түймесін басыңыз.",
		"clearCacheSuccess": "Кэш сәтті тазартылды!",
	},
	"hww": {
		"regTitle": "Қызметке тіркелу",
		"regSubtitle": "Қызметке тіркеліңіз",
		"strategyTitle": "Стратегия жасау",
		"strategySubtitle": "Сауалнамаға жауап беріңіз, және ЖИ жеке стратегия дайындайды",
		"planTitle": "Контент жоспарын жасау",
		"planSubtitle": "Стратегия негізінде ЖИ контент жоспарын ұсынады",
		"postTitle": "Посттарды жариялау",
		"postSubtitle": "Сіздің стратегияңыз бен контент жоспарыңыз негізінде ЖИ сізге пост идеяларын ұсынады"
	},
	"weekDays": {
		"Monday": "Дүйсенбі",
		"Tuesday": "Сейсенбі",
		"Wednesday": "Сәрсенбі",
		"Thursday": "Бейсенбі",
		"Friday": "Жұма",
		"Saturday": "Сенбі",
		"Sunday": "Жексенбі"
	}
}
